import React, { useContext } from 'react';
import Login from '../pages/Login';
import { AuthContext } from '../../contexts/Auth';
import { TContext } from '../../models/TContext';
import { BasicBackdrop } from './BasicBackdrop';
import { StateContext } from '../../contexts/State';

export const RequireAuth: React.FC<TContext> = (props: any) => {
  const { user, userLoading } = useContext(AuthContext);
  const { loading } = useContext(StateContext);
  const prd = process.env.REACT_APP_ENV === "prd";
  if (!user && !prd) {
    return LoginComponent(userLoading);
  }
  return MainComponent(loading, props);
}

const LoginComponent = (loading: boolean) => {
  return loading ? <Login /> : <BasicBackdrop open={true} />;
};

/// スクロール完了するまで、DOMを表示しない
const MainComponent = (loading: boolean, props: any) => {
  return (
    <>
      <BasicBackdrop open={loading} />
      {props.children}
    </>
  );
};