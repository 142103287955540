import { getAuth, GoogleAuthProvider, onAuthStateChanged, signInWithPopup, signOut } from 'firebase/auth';
import { collection, doc, getDocs, query, setDoc, where } from 'firebase/firestore';
import React, { createContext, useCallback, useEffect, useState } from 'react';
import { db, toTimestamp, User } from '../firebase';
import { TContext } from '../models/TContext';
import { TUser } from '../models/TUser';
import dayjs from 'dayjs';

interface IAuthContext {
	login: any;
	user: User | null | undefined;
	userLoading: boolean;
}

export const AuthContext = createContext<IAuthContext>({
	login: undefined,
	user: undefined,
	userLoading: false
})

export const AuthProvider: React.FC<TContext> = (props: any) => {
	const [user, setUser] = useState<User | null | undefined>(undefined);
	const [userLoading, setUserLoading] = useState<boolean>(false);

	useEffect(() => {
		const auth = getAuth();
		onAuthStateChanged(auth, async (user: User | null | undefined) => {
			if (user) {
				const idTokenResult = await user.getIdTokenResult(true);
				if (idTokenResult.claims.admin) {
					// admin権限があるユーザーのみログイン許可
					setUser(user);
				} else {
					// admin権限なしは強制ログアウト
					signOut(auth);
				}
			} else {
				// Logout
				setUser(undefined);
			}
			setUserLoading(true);
		});
		return () => { };
	}, []);

	const login = async () => {
		const auth = getAuth();
		const provider = new GoogleAuthProvider();
		try {
			/// google認証
			await signInWithPopup(auth, provider).then(async (result) => {
				const credential = GoogleAuthProvider.credentialFromResult(result);
				if (!credential) return;
				const token = credential.accessToken;
				if (token) {
					const user = result.user;
					const uid = user.uid;
					const q = query(collection(db, 'users'), where('uid', '==', uid));
					try {
						const querySnapshot = await getDocs(q);
						if (querySnapshot.empty) {
							alert('ログイン権限がありません');
							signOut(auth);
							await user.delete();
							return;
						}
						querySnapshot.forEach(async (doc) => {
							const data = {
								id: doc.id,
								uid: uid,
								token: token,
								updatedAt: toTimestamp(dayjs())
							};
							await updateAdmin(data);
						});
					} catch (e) {
						alert('ログイン権限がありません');
						// await user.delete();
						return;
					}
				}
			});
			return;
		} catch (error: any) {
			alert(error.message);
			return;
		}
	}

	const updateAdmin = useCallback(
		async (user: TUser) => {
			const { id, ...record } = user;
			if (id) {
				await setDoc(doc(db, 'users', id), {
					...record,
				});
			}
		}, []
	);

	return (
		<AuthContext.Provider
			value={{
				login,
				user,
				userLoading
			}}
		>
			{props.children}
		</AuthContext.Provider>
	)
}