import axios, { AxiosRequestConfig } from "axios";
import { TPost, TResponse } from "../models/TInstagram";

const instaBusinessId = process.env.REACT_APP_INSTAGRAM_BUSINESS_ID;
const accessToken = process.env.REACT_APP_INSTAGRAM_ACCESS_TOKEN;

export const getInstagramPosts = async (): Promise<TPost[]> => {
  if (!accessToken || !accessToken) return [];
  const response = await instagramBasicDisplayApi();
  if (response) {
    const posts = response.data.flatMap((v) => {
      return {
        id: v.id,
        commentsCount: v.comments_count,
        likeCount: v.like_count,
        children: v.children,
        mediaCount: v.media_count,
        mediaType: v.media_type,
        mediaUrl: v.media_url,
        timestamp: v.timestamp,
        username: v.username,
        paging: v.paging,
        permalink: v.permalink
      };
    });
    if (posts.length === 0) return [];
    return posts;
  }
  return [];
};

export const instagramBasicDisplayApi = async (): Promise<TResponse | null> => {
  if (!accessToken || !accessToken) return null;
  const url = `https://graph.facebook.com/v17.0/${instaBusinessId}/media?fields=id,comments_count,like_count,children{media_url},media_url,media,username,timestamp,thumbnail_url,media_type,permalink&access_token=${accessToken}`;
  const response: TResponse = await instagramApi(url, 'GET');
  return response;
};

export const instagramApi = async (
  url: string,
  method: string
) => {

  const options: AxiosRequestConfig = {
    url: url,
    method: method,
  };

  try {
    const response = await axios.get(url, options);
    return response.data;
  } catch (error) {
    console.error('Instagram APIのリクエスト中にエラーが発生しました:', error);
    return null;
  }
};