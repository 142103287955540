import { Box, Divider, Typography, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { palette, theme } from '../../theme';
import { TNews } from '../../models/TNews';
import { TStorage } from '../../models/TStorage';
import { StateContext } from '../../contexts/State';
import { Image } from '../atoms/Image';
import { toDayjs } from '../../firebase';
import { useWindowSize } from 'react-use';

interface OwnProps {
  backgroundColor: string;
  isDivider: boolean;
  isHover: number | null;
  images: TStorage[];
  news: TNews[];
  pb: number;
  onSetIsHover: React.Dispatch<React.SetStateAction<number | null>>;
  onSetDetail: React.Dispatch<React.SetStateAction<TNews>> | null;
}

const CustomContentsBox = styled(Box)({
  flexWrap: 'wrap',
  justifyContent: 'space-around',
  [theme.breakpoints.down('md')]: {
    flexWrap: 'unset',
  },
  [theme.breakpoints.down('sm')]: {
    flexWrap: 'unset',
  }
});

const CustomImageBox = styled(Box)({
  "&::before": {
    content: '""',
    background: 'rgba(0, 0, 0, 0)',
    borderRadius: 3,
    display: 'block',
    inset: 1,
    position: 'absolute',
    zIndex: 1,
  },
});

const CustomCard = styled(Box)({
  alignItems: 'flex-start',
  cursor: 'pointer',
  display: 'flex',
  padding: '16px 0',
  [theme.breakpoints.down('md')]: {
    cursor: 'default',
  },
  [theme.breakpoints.down('sm')]: {
    borderTopLeftRadius: '5px!important',
    borderTopRightRadius: '5px!important',
    borderBottomLeftRadius: '5px!important',
    borderBottomRightRadius: '5px!important',
    boxShadow: '0 0 5px rgba(0,0,0,0.3)',
    flexDirection: 'column'
  }
});

const CustomTitle = styled(Typography)({
  fontSize: 16,
  fontWeight: 'bold',
  transition: '.3s color ease-in',
  width: '100%'
});

const CustomSubTitle = styled(Typography)({
  color: palette.info.light,
  fontSize: 14,
  fontWeight: 'bold',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

const CustomDateTimeTitle = styled(Typography)({
  fontSize: 14,
  fontWeight: 'bold',
  color: palette.info.dark,
});

export const NewsInfoContent: React.FC<OwnProps> = (props) => {
  const { backgroundColor, isDivider, isHover, images, news, pb, onSetIsHover, onSetDetail } = props;
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { width } = useWindowSize();
  const { setLoading } = useContext(StateContext);
  const [itemHeight, setItemHeight] = useState<number>(0);

  useEffect(() => {
    if (ref) {
      const current = ref.current;
      if (current) {
        const rect = current.getBoundingClientRect();
        const height = rect.height;
        setItemHeight(height + 16);
      }
    }
  }, [isMediumScreen, isSmallScreen, news, ref, width]);

  return (
    <CustomContentsBox
      p={2}
      pb={pb}
      display="flex"
      width='-webkit-fill-available'
      sx={{
        [theme.breakpoints.up('md')]: {
          justifyContent: news.length === 1 ? 'flex-start' : 'space-around',
        },
        [theme.breakpoints.down('md')]: {
          flexDirection: 'column',
          justifyContent: news.length === 1 ? 'flex-start' : 'space-around',
        },
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
          justifyContent: news.length === 1 ? 'flex-start' : 'space-around',
        }
      }}
    >
      {news.flatMap((value, idx) => {
        const find = images.find((v) => v.id === value.id);
        return (
          <Box
            key={idx}
            height={news.length < 2 ? itemHeight : 'auto'}
            width={isMediumScreen ? 1 : .48}
          >
            {isDivider && [0, 1].includes(idx) && (
              <Divider
                variant="fullWidth"
                style={{
                  borderColor: '#d2d8e6'
                }}
              />
            )}
            {!isDivider && (
              <Divider
                variant="fullWidth"
                style={{
                  borderColor: '#d2d8e6'
                }}
              />
            )}
            <CustomCard
              ref={idx === 0 ? ref : null}
              bgcolor={backgroundColor}
              onClick={() => {
                setLoading(true);
                setTimeout(() => {
                  if (onSetDetail) {
                    onSetDetail(value);
                  }
                  navigate(`/news/${value.id}`);
                }, 1000);
                setTimeout(() => {
                  window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                  });
                }, 2000);
                setTimeout(() => {
                  setLoading(false);
                }, 3500);
              }}
              onMouseEnter={() => onSetIsHover(idx)}
              onMouseLeave={() => onSetIsHover(null)}
            >
              <CustomImageBox
                className="news-image-box"
                borderRadius={1}
                height={120}
                width={180}
                overflow="hidden"
                position="relative"
                sx={{
                  "&::before": {
                    background: isHover === idx && !isMediumScreen && find ? 'rgba(23,28,44,.1)' : 'transparent'
                  }
                }}
              >
                <Image
                  alt={'new image'}
                  image={find ? find.image : null}
                  style={{
                    transform: isHover === idx && !isMediumScreen ? 'translate(-50%, -50%) scale(1.1)' : 'translate(-50%, -50%)'
                  }}
                />
              </CustomImageBox>
              {isSmallScreen ? (<Box mt={2} />) : (<Box mr={3} />)}
              <Box
                display="flex"
                alignItems="flex-start"
                flexDirection="column"
                width={isSmallScreen ? 1 : .7}
              >
                <Box
                  className={isDivider ? 'news-title-home' : 'news-title'}
                  display="block"
                  overflow="hidden"
                  width={1}
                >
                  <CustomTitle
                    color={isHover === idx && !isMediumScreen ? palette.info.light : palette.primary.contrastText}
                  >
                    {value.title}
                  </CustomTitle>
                </Box>
                <Box mt={1} />
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                  width={1}
                >
                  <CustomDateTimeTitle>{toDayjs(value.updatedAt).format('YYYY.MM.DD')}</CustomDateTimeTitle>
                  <Box mr={1} />
                  <Divider
                    flexItem
                    orientation="vertical"
                    variant="fullWidth"
                    style={{
                      borderColor: palette.info.dark,
                      marginTop: 4,
                      marginBottom: 4,
                    }}
                  />
                  <Box mr={1} />
                  <CustomSubTitle>{value.category}</CustomSubTitle>
                </Box>
              </Box>
            </CustomCard>
            {isDivider && (
              <Divider
                variant="fullWidth"
                style={{
                  borderColor: '#d2d8e6'
                }}
              />
            )}
            {isSmallScreen ? (
              <Box mt={3} />
            ) : (
              <Box mt={2} />
            )}
          </Box>
        );
      })}
      {news.length < 6 && !isMediumScreen && (
        <Box
          height={itemHeight}
          width={.48}
        />
      )}
    </CustomContentsBox>
  )
}