import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Popover } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router';
import { styled } from '@mui/system';
import { theme } from '../../theme';

const CustomList = styled(List)({
	cursorPointer: {
		[theme.breakpoints.up('md')]: {
			cursor: 'pointer'
		}
	}
});

interface OwnProps {
	open: boolean;
	anchorEl: HTMLButtonElement | null;
	onClose(): void;
}

export const PopoverMenu: React.FC<OwnProps> = (props) => {
	const { open, anchorEl, onClose } = props;
	const navigate = useNavigate();

	const handleLogout = async () => {
		onClose();
		// eslint-disable-next-line no-restricted-globals
		if (confirm('ログアウトしますか？')) {
			const auth = getAuth();
			await signOut(auth);
			navigate('/');
		}
	}

	return (
		<Popover
			open={open}
			onClose={onClose}
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
		>
			<CustomList>
				<ListItem
					style={{
						cursor: 'pointer'
					}}
					onClick={handleLogout}
				>
					<ListItemIcon>
						<ExitToAppIcon />
					</ListItemIcon>
					<ListItemText>
						ログアウト
					</ListItemText>
				</ListItem>
			</CustomList>
		</Popover>
	)
}