import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { CenterColumnBox, palette, theme } from '../../theme';
import { styled } from '@mui/system';
import { CompanyContext } from '../../contexts/Company';
import { TGeolocation } from '../../models/TCompany';
import { Link } from 'react-router-dom';
import { TopView } from '../atoms/TopView';
import { CompanyOverview } from '../organisms/CompanyOverview';
import { GoogleMap } from '../atoms/GoogleMap';
import { ContentTitle } from '../atoms/ContentTitle';
import { IcoArrowRight } from '../../images/IcoArrowRight';
import { StateContext } from '../../contexts/State';
import { useWindowSize } from 'react-use';

const CustomContentTitleBox = styled(Box)({
  width: '80%',
  [theme.breakpoints.down('md')]: {
    width: '90%'
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  }
});

export const Company: React.FC = () => {
  const { company } = useContext(CompanyContext);
  const { headerRef } = useContext(StateContext);
  const { width } = useWindowSize();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [location, setLocation] = useState<TGeolocation>({ lat: 0, lng: 0 });
  const [isButtonHover, setIsButtonHover] = useState<boolean>(false);
  const [headerHeight, setHeaderHeight] = useState<number>(0);

  useEffect(() => {
    window.document.title = "GARAGE WIDE | 会社案内";
    navigator.geolocation.getCurrentPosition((position) => {
      let lat = position.coords.latitude;
      let lng = position.coords.longitude;
      setLocation({ lat, lng });
    });
  }, []);

  useEffect(() => {
    if (headerRef) {
      const current = headerRef.current;
      if (current) {
        const rect = current.getBoundingClientRect();
        const height = rect.height;
        setHeaderHeight(height);
      }
    }
  }, [headerRef, width]);

  return (
    <Box style={{ marginTop: headerHeight }}>
      <TopView title={'会社案内'} imageName={'company.jpg'} />
      <Box mt={15} />
      {isSmallScreen ? (<Box mt={3} />) : (<Box mt={8} />)}
      <Box>
        <Box p={2}>
          <Box
            display="flex"
            justifyContent="center"
          >
            <CustomContentTitleBox
              bgcolor={palette.secondary.contrastText}
              borderTop={`2px solid ${palette.primary.main}`}
              maxWidth={1152}
            >
              {isSmallScreen ? (<Box mt={3} />) : (<Box mt={5} />)}
              <ContentTitle title={'会社概要'} />
              {isSmallScreen ? (<Box mt={3} />) : (<Box mt={5} />)}
              <CompanyOverview />
            </CustomContentTitleBox>
          </Box>
          {isSmallScreen ? (<Box mt={5} />) : (<Box mt={8} />)}
          <CenterColumnBox>
            <GoogleMap width={'95%'} height={'450px'} />
            <Box pt={5} />
            <Button
              disableRipple
              component={Link}
              variant="contained"
              target="_blank"
              to={`https://www.google.com/maps/dir/?api=1&origin=${location.lat},${location.lng}&destination=${company.address}`}
              sx={{
                background: palette.primary.dark,
                border: `1px solid ${palette.primary.dark}`,
                color: palette.secondary.contrastText,
                fontWeight: 'bold',
                padding: '15px 30px',
                transition: '.3s background ease-out',
                [theme.breakpoints.up('md')]: {
                  "&:hover": {
                    background: palette.secondary.contrastText,
                    border: `1px solid ${palette.primary.dark}`,
                    color: palette.primary.contrastText,
                  }
                },

                [theme.breakpoints.down('md')]: {
                  cursor: 'default',
                  "&:hover": {
                    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)!important'
                  }
                }
              }}
              onMouseEnter={() => setIsButtonHover(true)}
              onMouseLeave={() => setIsButtonHover(false)}
            >
              <Typography
                fontSize={14}
                fontWeight="bold"
                color={!isMediumScreen && isButtonHover ? palette.primary.contrastText : palette.secondary.contrastText}
              >
                Google Mapsでナビする
              </Typography>
              <Box mr={.5} />
              <IcoArrowRight
                height={'100%'}
                width={20}
                color={!isMediumScreen && isButtonHover ? palette.primary.contrastText : palette.secondary.contrastText}
              />
            </Button>
          </CenterColumnBox>
        </Box>
        {isSmallScreen ? (<Box pt={10} />) : (<Box pt={20} />)}
      </Box>
    </Box >
  );
}