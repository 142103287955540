import { Box, useMediaQuery } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { theme } from '../../theme';
import { TopView } from '../atoms/TopView';
import { serviceItems } from '../../utils/MenuList';
import { StateContext } from '../../contexts/State';
import { ServiceCardRight } from '../molecules/ServiceCardRight';
import { ServiceCardLeft } from '../molecules/ServiceCardLeft';
import { useWindowSize } from 'react-use';
import { useNavigate } from 'react-router-dom';

export const Service: React.FC = () => {
  const hash = window.location.hash;
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const saleContent = serviceItems.find((v) => v.id === 'sale')!;
  const inspectionContent = serviceItems.find((v) => v.id === 'inspection')!;
  /// [TODO] 板金・塗装は一旦事業内容から省く
  // const maintenanceContent = serviceItems.find((v) => v.id === 'maintenance')!;
  // const paintingContent = serviceItems.find((v) => v.id === 'painting')!;
  const { headerRef, setLoading } = useContext(StateContext);
  const { width } = useWindowSize();
  const [headerHeight, setHeaderHeight] = useState<number>(0);

  useEffect(() => {
    window.document.title = "GARAGE WIDE | サービス案内";
  },[]);

  useEffect(() => {
    const ids = ["sale", "inspection"];
    if (hash) {
      window.location.hash = '';
      const slash = hash.split('#');
      if (slash.length > 0) {
        const id = slash[1];
        if (!ids.includes(id)) {
          return navigate('/notfound');
        }
        const element = document.getElementById(id);
        if (element && headerRef && headerRef.current) {
          const headerRect = headerRef.current.getBoundingClientRect();
          const rect = element.getBoundingClientRect();
          let targetTop;
          if (isMediumScreen) {
            targetTop = (rect.top + window.scrollY + 72) - headerRect.height;
          } else {
            targetTop = rect.top + window.scrollY + 72;
          }
          window.scrollTo({
            top: targetTop,
            behavior: 'smooth'
          });
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      }
    }
    // eslint-disable-next-line
  }, [isMediumScreen]);

  useEffect(() => {
    if (headerRef) {
      const current = headerRef.current;
      if (current) {
        const rect = current.getBoundingClientRect();
        const height = rect.height;
        setHeaderHeight(height);
      }
    }
  }, [headerRef, width]);

  return (
    <Box style={{ marginTop: headerHeight }}>
      <TopView title={'サービス案内'} imageName={'service.jpg'} />
      <Box mt={15} />
      {isMediumScreen && (<Box mt={8} />)}
      <ServiceCardRight id={'sale'} content={saleContent} />
      {isMediumScreen && (<Box mt={8} />)}
      <ServiceCardLeft id={'inspection'} content={inspectionContent} />
      {/* {isMediumScreen && (<Box mt={8} />)} */}
      {/* <ServiceCardRight id={'maintenance'} content={maintenanceContent} /> */}
      {/* {isMediumScreen && (<Box mt={8} />)}
      <ServiceCardLeft id={'painting'} content={paintingContent} /> */}
      {isSmallScreen ? (<Box pt={10} />) : (<Box pt={15} />)}
    </Box>
  )
}