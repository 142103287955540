import { Box } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { styled } from '@mui/system';
import { Header } from '../organisms/Header';
import { Footer } from '../organisms/Footer';
import { ProgressBar } from '../atoms/ProgressBar';
import { Background } from '../../images/Background';
import { svgImage } from '../../utils/Format';
import { StateContext } from '../../contexts/State';
import { palette } from '../../theme';

interface OwnProps {
  component: React.FC;
}

const CustomBox = styled(Box)({
  background: palette.background.paper,
  width: '100%',
  position: 'relative',
  zIndex: 1,
});

const CustomBackground = styled(Box)({
  backgroundSize: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: -1,
  width: '100%',
  overflow: 'hidden',
  transition: '3s height ease'
});

export const Page: React.FC<OwnProps> = (props) => {
  const { loading } = useContext(StateContext);
  const [height, setHeight] = useState<string>('0%');

  useEffect(() => {
    if (!loading) {
      setHeight('100%');
    } else {
      setHeight('0%');
    }
  }, [loading]);

  return (
    <>
      <CustomBox>
        <CustomBackground
          style={{
            background: `url(${svgImage(Background(3000, 6000))}) no-repeat center top`,
            height: height
          }}
        />
        <ProgressBar />
        <Header />
        <Box>
          <props.component />
        </Box>
        <Footer />
      </CustomBox>
    </>
  )
}