import dayjs from 'dayjs';
import * as firebase from 'firebase/app';
import * as auth from 'firebase/auth';
import * as firestore from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { getStorage, ref } from 'firebase/storage';
import { firebaseConfig } from './config';

export const app = firebase.initializeApp(firebaseConfig);
export const db = firestore.getFirestore();
export const func = getFunctions(app, 'asia-northeast1');

export const storage = getStorage(app, process.env.REACT_APP_STORAGE);
export const newsPathReference = (imageName: string) => ref(storage, `news/${imageName}`);

const analytics = getAnalytics();
logEvent(analytics, 'notification_received');

export type User = auth.User;
export type Timestamp = firestore.Timestamp;

export const toTimestamp = (value: dayjs.Dayjs) => {
  return firestore.Timestamp.fromDate(value.toDate());
};

export const toDayjs = (value: Timestamp) => {
  return dayjs(new Date(value.seconds * 1000));
};