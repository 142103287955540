import dayjs from 'dayjs';
import { doc, onSnapshot } from 'firebase/firestore';
import { createContext, useMemo, useState } from 'react';
import { db, toTimestamp } from '../firebase';
import { TCompany } from '../models/TCompany';

interface ICompanyContext {
  company: TCompany;
}

const defaultCompany = {
  id: '',
  address: '〒901-0203 沖縄県豊見城市長堂６６−４',
  businessDetails: [''],
  closingTime: toTimestamp(dayjs()),
  email: '',
  facebook: '',
  iframe: '',
  instagram: '',
  latLng: { lat: 0, lng: 0 },
  name: 'Garage Wide',
  openingTime: toTimestamp(dayjs()),
  regularClosingDays: 0,
  representative: '',
  tel: '',
  updatedAt: toTimestamp(dayjs()),
  x: ''
};

const CompanyContext = createContext<ICompanyContext>({
  company: defaultCompany,
});

const CompanyProvider = (props: any) => {
  const [company, setCompany] = useState<TCompany>(defaultCompany);

  // eslint-disable-next-line
  const col = useMemo(async () => {
    /// [TODO]: company collectionのdocumentIdを変更するかを考える
    const col = doc(db, 'company', 'nhiucMaOS6AUe1pkQ3lu');
    onSnapshot(col, (doc) => {
      const docData = doc.data();
      if (docData) {
        const data = docData as TCompany;
        setCompany(data);
      }
    });
    return col;
  }, []);

  return (
    <CompanyContext.Provider value={{ company }}>
      {props.children}
    </CompanyContext.Provider>
  );
};

export { CompanyContext, CompanyProvider };