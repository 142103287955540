import { Box, Pagination, useMediaQuery } from '@mui/material';
import React, { useContext } from 'react';
import { palette, theme } from '../../theme';
import { StateContext } from '../../contexts/State';

interface OwnProps {
  count: number;
  selectedPageNum: number;
  onSetSelectedPageNum: React.Dispatch<React.SetStateAction<number>>;
}

export const PaginationComponent: React.FC<OwnProps> = (props) => {
  const { count, selectedPageNum, onSetSelectedPageNum } = props;
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { headerRef, setLoading } = useContext(StateContext);
  return (
    <Box
      display="flex"
      justifyContent="center"
      sx={{
        '& > *': {
          marginTop: theme.spacing(2),
        }
      }}
    >
      <Pagination
        count={count}
        color="primary"
        size="large"
        showFirstButton
        showLastButton
        onChange={(e, page) => {
          if (selectedPageNum !== page) {
            setLoading(true);
            setTimeout(() => {
              onSetSelectedPageNum(page);
              const el = document.getElementById('news-content');
              if (el && headerRef && headerRef.current) {
                const headerRect = headerRef.current.getBoundingClientRect();
                const rect = el.getBoundingClientRect();
                const targetTop = (rect.top + window.scrollY) - (headerRect.height);
                window.scrollTo({
                  top: targetTop,
                  behavior: 'smooth',
                });
              }

            }, 1000);
            setTimeout(() => {
              setLoading(false);
            }, 2000);
          }
        }}
        sx={{
          background: palette.secondary.contrastText,
          borderRadius: 1,
          padding: 1,
          [theme.breakpoints.up('md')]: {
            "& button:hover": {
              color: isMediumScreen ? 'rgba(0, 0, 0, 0.87)' : palette.secondary.contrastText,
              backgroundColor: isMediumScreen ? palette.secondary.contrastText : palette.primary.main
            },
          },
          [theme.breakpoints.down('md')]: {
            "& button": {
              cursor: 'default!important'
            },
            "& button:hover": {
              backgroundColor: 'transparent'
            },
          },
          "&& .Mui-selected": {
            fontWeight: 'bold',
            color: palette.secondary.contrastText,
          }
        }}
      />
    </Box>
  )
}
