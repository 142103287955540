import React, { useContext } from 'react';
import { styled } from '@mui/system';
import { CustomItemButton, CustomItemTypography, CustomSubItemTypography } from '../../theme';
import { Menu } from 'react-pro-sidebar';
import { sideMenuTop } from '../../utils/MenuList';
import { Box } from '@mui/material';
import { ServiceMenu } from './ServiceMenu';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { StateContext } from '../../contexts/State';

interface OwnProps {
  isOpen: boolean;
  onSetIsToggled: React.Dispatch<React.SetStateAction<boolean>>;
  onSetSideMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CustomMenu = styled(Menu)({
  textAlign: 'end'
});

const CustomSubMenuContents = styled(Box)({
  "& .subtext-hover": {
    background: 'transparent !important',
    opacity: 0.5,
    transition: 'opacity 0.6s ease!important'
  },
  "& .subtext-hover.ps-open": {
    opacity: '1 !important',
  },
  "& .ps-menu-button": {
    padding: '0 !important',
    transition: 'opacity 0.6s ease',
  }
});

export const SideMenuTop: React.FC<OwnProps> = (props) => {
  const { isOpen, onSetIsToggled, onSetSideMenuOpen } = props;
  const navigate = useNavigate();
  const { setLoading } = useContext(StateContext);
  const { t } = useTranslation();

  return (
    <CustomMenu>
      {sideMenuTop.flatMap((item, idx) => {
        return (
          <Box key={idx}>
            {idx === 0 && (
              <CustomSubMenuContents
                position="relative"
                display="flex"
                flexDirection="column"
                alignItems="end"
              >
                <ServiceMenu
                  isOpenSideMenu={isOpen}
                  onSetIsToggled={onSetIsToggled}
                  onSetSideMenuOpen={onSetSideMenuOpen}
                />
              </CustomSubMenuContents>
            )}
            {idx !== 0 && (
              <CustomItemButton
                disableRipple
                onClick={() => {
                  setLoading(true);
                  setTimeout(() => {
                    navigate(item.link);
                  }, 1000);
                  setTimeout(() => {
                    onSetIsToggled(false);
                    onSetSideMenuOpen(false);
                    window.scrollTo({
                      top: 0,
                      behavior: 'smooth'
                    });
                  }, 2000);
                  setTimeout(() => {
                    setLoading(false);
                  }, 3500);
                }}
              >
                <Box>
                  <CustomItemTypography>
                    {item.text}
                  </CustomItemTypography>
                  <CustomSubItemTypography>
                    {t(item.text)}
                  </CustomSubItemTypography>
                </Box>
              </CustomItemButton>
            )}
          </Box>
        );
      })}
    </CustomMenu>
  )
}