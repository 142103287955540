import { Box, Divider, Typography, useMediaQuery } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { styled } from '@mui/system';
import { palette, theme } from '../../theme';
import { BackgroundNews } from '../../images/BackgroundNews';
import { svgImage } from '../../utils/Format';
import { NewsDetailContent } from '../organisms/NewsDetailContent';
import { NewNewsInfo } from '../organisms/NewNewsInfo';
import { NewsContext } from '../../contexts/News';
import { useNavigate } from 'react-router-dom';
import { TNews } from '../../models/TNews';
import { toDayjs, toTimestamp } from '../../firebase';
import dayjs from 'dayjs';
import { StateContext } from '../../contexts/State';
import { useWindowSize } from 'react-use';

const CustomTopBox = styled(Box)({
  position: 'relative',
  paddingTop: '8%',
  paddingBottom: 30,
  "&::before": {
    position: 'absolute',
    top: 0,
    left: -7,
    zIndex: -1,
    display: 'block',
    width: 'calc(100% - 32px)',
    paddingBottom: '13.6%',
    content: '""',
    background: `url(${svgImage(BackgroundNews(216, 53))}) left center/contain no-repeat`,
  },
  [theme.breakpoints.up('md')]: {
    paddingTop: 56,
    paddingBottom: 50,
    "&::before": {
      paddingBottom: 85
    }
  },
  [theme.breakpoints.down('md')]: {
    paddingTop: 30,
    "&::before": {
      paddingBottom: 51,
    }
  },
  [theme.breakpoints.down('sm')]: {
    "&::before": {
      paddingBottom: 51,
    }
  }
});

const CustomTopText = styled(Typography)({
  color: palette.info.light,
  fontSize: 40,
  fontWeight: 'bold',
  [theme.breakpoints.down('md')]: {
    fontSize: 35,
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 30,
  }
});

const CustomTopDateTime = styled(Typography)({
  color: palette.primary.contrastText,
  fontSize: 18,
  fontWeight: 'bold'
});

const CustomTopCategory = styled(Typography)({
  fontSize: 18,
  fontWeight: 'bold',
  color: palette.info.light,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

const defaultNews = {
  id: '',
  imageName: '',
  title: '',
  category: '',
  content: '',
  updatedAt: toTimestamp(dayjs()),
  url: ''
};

export const NewsDetail: React.FC = () => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const { headerRef } = useContext(StateContext);
  const { width } = useWindowSize();
  const { news } = useContext(NewsContext);
  const [detail, setDetail] = useState<TNews>(defaultNews);
  const [headerHeight, setHeaderHeight] = useState<number>(0);

  useEffect(() => {
    window.document.title = "GARAGE WIDE | お知らせ詳細";
  },[]);

  useEffect(() => {
    if (news.length === 0) return;
    const pathNames = window.location.pathname.split('/');
    if (pathNames.length === 3) {
      const pathName = pathNames[2];
      const find = news.find((v) => v.id === pathName);
      if (find) {
        setDetail(find);
        return;
      }
    }
    return navigate('/notfound');
    // eslint-disable-next-line
  }, [news]);

  useEffect(() => {
    if (headerRef) {
      const current = headerRef.current;
      if (current) {
        const rect = current.getBoundingClientRect();
        const height = rect.height;
        setHeaderHeight(height);
      }
    }
  }, [headerRef, width]);

  return (
    <Box style={{ marginTop: headerHeight }}>
      <Box
        marginRight={2}
        marginLeft={2}
        display="flex"
        justifyContent="center"
      >
        <Box
          p={3}
          maxWidth={1152}
          width={1}
        >
          <Box>
            <CustomTopBox>
              <CustomTopText>{detail.title}</CustomTopText>
            </CustomTopBox>
            <Box mt={2} />
            <Box
              display="flex"
              align-items="center"
              justifyContent="flex-end"
              width={1}
            >
              <Box
                display="flex"
                align-items="center"
                justifyContent="flex-end"
                width={300}
              >
                <Box>
                  <CustomTopDateTime>{toDayjs(detail.updatedAt).format('YYYY.MM.DD')}</CustomTopDateTime>
                </Box>
                <Divider
                  flexItem
                  orientation="vertical"
                  variant="fullWidth"
                  style={{
                    borderColor: palette.info.dark,
                    marginTop: 4,
                    marginBottom: 4,
                    marginRight: 8,
                    marginLeft: 8,
                  }}
                />
                <CustomTopCategory>{detail.category}</CustomTopCategory>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <NewsDetailContent news={detail} />
      <Box mt={isSmallScreen ? 5 : 8} />
      <NewNewsInfo onSetDetail={setDetail} />
      <Box mt={10} />
    </Box >
  )
}