import { Card, useMediaQuery } from '@mui/material';
import React from 'react';
import { theme } from '../../theme';

interface OwnProps {
  boxNum: number | undefined;
  imageUrl: string;
  index: number;
  height: number;
  cardRef: React.MutableRefObject<HTMLDivElement | null>;
}

export const ServiceCard: React.FC<OwnProps> = (props) => {
  const { boxNum, imageUrl, index, height, cardRef } = props;
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Card
      ref={index === 0 ? cardRef : null}
      style={{
        height: height,
        backgroundImage: imageUrl,
        transform: boxNum === index && !isMediumScreen ? 'scale(1.2)' : 'scale(1.0)',
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        padding: theme.spacing(1),
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '0 0',
        backgroundSize: 'cover',
        filter: 'brightness(0.5)',
        transition: '.5s',
        width: '100%',
        cursor: isMediumScreen ? 'default' : 'pointer'
      }}
    />
  )
}
