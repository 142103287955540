import React, { useEffect, useState } from 'react';
import { TNews } from '../../models/TNews';
import { newsPathReference } from '../../firebase';
import { getBytes } from 'firebase/storage';
import { TStorage } from '../../models/TStorage';
import { NewsContentComponent } from './NewsContentComponent';
import { palette } from '../../theme';

interface OwnProps {
  news: TNews[];
}

export const NewsContent: React.FC<OwnProps> = (props) => {
  const { news } = props;
  const [images, setImages] = useState<TStorage[]>([]);
  const [isHover, setIsHover] = useState<number | null>(null);

  useEffect(() => {
    if (news.length === 0) return;
    const getImageName = news.flatMap((v) => v.imageName);
    if (getImageName.length === 0) return;
    (async () => {
      for (let i = 0; i < news.length; i++) {
        if (i > 0) return;
        const getImage = await getBytes(newsPathReference(news[i].imageName));
        const binaryData = btoa(Array.from(new Uint8Array(getImage), e => String.fromCharCode(e)).join(""));
        setImages((previous) => {
          return [
            ...previous.filter((v) => v.id !== news[i].id),
            {
              id: news[i].id,
              image: binaryData
            }
          ];
        });
      }
    })()
  }, [news]);

  return (
    <NewsContentComponent
      backgroundColor={palette.secondary.contrastText}
      images={images}
      isChange={null}
      isHover={isHover}
      news={news}
      onSetIsHover={setIsHover}
      onSetDetail={null}
    />
  )
}