import { Box, IconButton } from '@mui/material';
import React, { useContext } from 'react';
import { styled } from '@mui/system';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import { FlexRowReverse, palette, theme } from '../../theme';
import { CompanyContext } from '../../contexts/Company';

interface OwnProps {
  color: string;
}

const CustomIconButton = styled(IconButton)({
  color: palette.secondary.dark,
  transition: 'opacity 0.6s ease',
  "& svg": {
    fontSize: '2.0rem'
  },
  [theme.breakpoints.up('md')]: {
    "&:hover": {
      background: 'transparent !important',
      opacity: .5
    },
  },
  [theme.breakpoints.down('md')]: {
    cursor: 'default'
  }
});

export const SocialMedia: React.FC<OwnProps> = (props) => {
  const { color } = props;
  const { company } = useContext(CompanyContext);
  const components = [
    {
      icon: <FacebookIcon />,
      url: company.facebook
    },
    {
      icon: <XIcon />,
      url: `https://twitter.com/${company.x}/`
    },
    {
      icon: <InstagramIcon />,
      url: `https://www.instagram.com/${company.instagram}/`
    },
  ];
  return (
    <FlexRowReverse>
      {components.flatMap((component, idx) => {
        return (
          <Box key={idx}>
            <CustomIconButton
              disableRipple
              onClick={() => window.open(component.url)}
              style={{
                color: color
              }}
            >
              {component.icon}
            </CustomIconButton>
          </Box>
        );
      })}
    </FlexRowReverse>
  )
}
