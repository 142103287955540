import React, { useContext, useEffect, useState } from 'react';
import { styled } from '@mui/system';
import { CustomItemButton, CustomItemTypography, CustomSubItemTypography, palette, theme } from '../../theme';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { serviceList } from '../../utils/MenuList';
import { useTranslation } from 'react-i18next';
import { Box, Collapse, List, ListItemButton, ListItemText, useMediaQuery } from '@mui/material';
import { StateContext } from '../../contexts/State';
import { useNavigate } from 'react-router-dom';

interface OwnProps {
  isOpenSideMenu: boolean;
  onSetIsToggled: React.Dispatch<React.SetStateAction<boolean>>;
  onSetSideMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CustomListItemButton = styled(ListItemButton)({
  padding: '10px 20px',
  transition: 'opacity 0.6s ease',
  "& div": {
    marginTop: 0,
    marginBottom: 0
  },
  "&:hover": {
    background: 'none !important',
  },
  [theme.breakpoints.up('md')]: {
    "&:hover": {
      background: 'none !important',
      opacity: 0.5,
    }
  },
  [theme.breakpoints.down('md')]: {
    cursor: 'default!important',
  }
});

const typographyProps = {
  primary: {
    color: palette.primary.contrastText,
    fontSize: 16,
    fontWeight: 'bold',
    letterSpacing: '0.1em',
  },
  secondary: {
    color: 'rgba(0, 0, 0, 0.5)',
    fontSize: 10,
    fontWeight: 400,
    letterSpacing: '0.15em',
  }
};

export const ServiceMenu: React.FC<OwnProps> = (props) => {
  const { isOpenSideMenu, onSetIsToggled, onSetSideMenuOpen } = props;
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { headerRef, setLoading } = useContext(StateContext);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (!isOpenSideMenu) {
      setIsOpen(false);
    }
  }, [isOpenSideMenu]);

  return (
    <Box
      width={1}
      display="flex"
      justifyContent="center"
      flexDirection="column"
      mb={isOpen ? .7 : 0}
    >
      <CustomItemButton
        disableRipple
        onClick={handleClick}
        style={{
          justifyContent: 'space-between'
        }}
      >
        {isOpen ? (
          <ExpandLessIcon style={{ color: palette.secondary.contrastText }} />
        ) : (
          <ExpandMoreIcon style={{ color: palette.secondary.contrastText }} />
        )}
        <Box>
          <CustomItemTypography>サービス案内</CustomItemTypography>
          <CustomSubItemTypography>{t("サービス案内")}</CustomSubItemTypography>
        </Box>
      </CustomItemButton>
      <Collapse
        in={isOpen}
        timeout="auto"
        unmountOnExit
        sx={{ width: '100%' }}
      >
        <List
          component="div"
          sx={{ background: palette.secondary.contrastText }}
        >
          {serviceList.flatMap((item, idx) => {
            return (
              <Box key={idx}>
                <CustomListItemButton
                  key={idx}
                  disableRipple
                  onClick={() => {
                    setLoading(true);
                    const pathName = window.location.pathname;
                    if (pathName === '/service') {
                      const id = item.link.replace("#", "");
                      const element = document.getElementById(id);
                      if (element && headerRef && headerRef.current) {
                        const headerRect = headerRef.current.getBoundingClientRect();
                        const rect = element.getBoundingClientRect();
                        let targetTop = 0;;
                        if (isMediumScreen) {
                          targetTop = (rect.top + window.scrollY + 72) - headerRect.height * 2;
                        } else {
                          targetTop = (rect.top + window.scrollY + 72) - headerRect.height;
                        }
                        setIsOpen(false);
                        onSetSideMenuOpen(false);
                        onSetIsToggled(false);
                        setTimeout(() => {
                          window.scrollTo({
                            top: targetTop,
                            behavior: 'smooth'
                          });
                        }, 1000);
                        setTimeout(() => {
                          setLoading(false);
                        }, 2000);
                      }
                    } else {
                      setTimeout(() => {
                        setIsOpen(false);
                        onSetSideMenuOpen(false);
                        onSetIsToggled(false);
                        navigate(`/service${item.link}`);
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }, 1000);
                    }
                  }}
                >
                  <ListItemText
                    primary={item.text}
                    primaryTypographyProps={typographyProps.primary}
                    secondary={t(item.text)}
                    secondaryTypographyProps={typographyProps.secondary}
                  />
                </CustomListItemButton>
              </Box>
            );
          })}
        </List>
      </Collapse>
    </Box>
  )
}