import { Typography } from '@mui/material';
import { palette } from '../theme';
import { styled } from '@mui/system';

/// サービス案内
export const serviceList = [
  {
    id: 1,
    text: "中古車販売",
    link: "#sale",
  },
  {
    id: 2,
    text: "車検・点検",
    link: "#inspection"
  },
  // {
  //   id: 3,
  //   text: "整備・修理",
  //   link: "#maintenance"
  // },
  // {
  //   id: 4,
  //   text: "塗装",
  //   link: "#painting"
  // }
];

/// サイドメニュー項目
export const sideMenuTop = [
  {
    id: 1,
    text: "サービス案内",
    link: "/service"
  },
  {
    id: 2,
    text: "会社案内",
    link: "/company"
  },
  {
    id: 3,
    text: "お知らせ",
    link: "/news"
  },
  {
    id: 4,
    text: "お問い合わせ",
    link: "/contact"
  }
];

export const sideMenuBottom = [
  {
    id: 1,
    text: "個人情報保護方針",
    link: "/privacy"
  },
  {
    id: 2,
    text: "採用情報",
    link: "/employment"
  }
];

/// ヘッダーのメニュー項目
export const headerContents = [
  {
    id: 1,
    text: "サービス案内",
    link: "/service"
  },
  {
    id: 2,
    text: "会社案内",
    link: "/company"
  },
  {
    id: 3,
    text: "お知らせ",
    link: "/news"
  },
  {
    id: 4,
    text: "お問い合わせ",
    link: "/contact"
  }
];

/// フッターのメニュー項目
export const footerContents = [
  {
    id: 1,
    text: "サービス案内",
    link: "/service"
  },
  {
    id: 2,
    text: "お知らせ",
    link: "/news"
  },
  {
    id: 3,
    text: "会社案内",
    link: "/company"
  },
  {
    id: 4,
    text: "お問い合わせ",
    link: "/contact"
  },
  {
    id: 5,
    text: "採用情報",
    link: "/employment"
  },
  {
    id: 6,
    text: "個人情報保護方針",
    link: "/privacy"
  }
];

const CustomTypography = styled(Typography)(({ theme }) => ({
  color: palette.primary.contrastText,
  fontSize: 20,
  fontWeight: 'bold',
  [theme.breakpoints.down('sm')]: {
    fontSize: 16
  }
}));

export const serviceItems = [
  {
    id: "sale",
    name: "中古車販売",
    imageUrl: "url('/images/sale.jpg')",
    link: "/service",
    content: <CustomTypography color={palette.secondary.contrastText} lineHeight={1.8}>全車種・全メーカーの幅広い中古車を取扱っており、お客様のご予算やライフスタイル、目的・条件に合わせた最適なお車をご提案させていただきます。中古車をご購入の際は、自社工場で徹底的に点検し、車内外をキレイな状態で納車いたします。<br />また、納車後の車検やアフターケアなどもお任せください。</CustomTypography>
  },
  {
    id: "inspection",
    name: "車検・点検",
    imageUrl: "url('/images/car-inspection.jpg')",
    link: "/service",
    content: <CustomTypography color={palette.secondary.contrastText} lineHeight={1.8}>お客様の大切なお車を守るために、経験豊富な整備士たちが車のあらゆる部分を丁寧に点検し、精密な診断を行います。小さな問題も見逃さず、車の安全性と性能を最適な状態に保ちます。<br />さらに、車検の過程で発見された問題点については、すべてお客様に詳しく説明し、修理やメンテナンスをご提案させていただきます。作業内容と費用については、事前に詳細な情報お伝えし、お客様の同意を得てから作業を進めさせていただきます。</CustomTypography>
  },
  // {
  //   id: "maintenance",
  //   name: "整備・修理",
  //   imageUrl: "url('/images/maintenance.jpg')",
  //   link: "/service",
  //   content: <CustomTypography>愛車のパフォーマンスは、その心臓部であるエンジンから、細部にわたる一つ一つの部品まで、全てが最適な状態であることによって成り立っています。<br />小さなキズの修理から、大きなエンジントラブルの修復まで、高い精度をもって、その安全と性能を守るために取り組みます。<br />信頼と安心を提供する私たちに、あなたの愛車のケアをお任せください。あなたのカーライフが今日よりも明日よりも、さらに輝きを増すようサポートします。</CustomTypography>,
  // },
  // {
  //   id: "painting",
  //   name: "塗装",
  //   imageUrl: "url('/images/painting.jpg')",
  //   link: "/service",
  //   content: <CustomTypography>お車の外観は、その人の個性やステータスを象徴するもの、私たちはあなたの理想を形にします。熟練の技で、細部にまでこだわり抜いた仕上がりを実現し、お客様一人ひとりのニーズに合わせたカスタム塗装を提供します。<br />あなたの愛車が、ただの車でなく、あなたの誇りとなるよう、心を込めて丁寧に塗装します。</CustomTypography>
  // }
];