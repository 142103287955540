import React from 'react';
import { TextLink, theme } from '../../theme';
import { SxProps, Theme } from '@mui/material';

interface OwnProps {
  content: string | JSX.Element;
  href: string;
  sx: SxProps<Theme>;
  underline: 'none' | 'hover' | 'always' | undefined;
}

export const TextLinkComponent: React.FC<OwnProps> = (props) => {
  const { content, href, sx, underline } = props;
  return (
    <TextLink
      rel="noopener noreferrer"
      target="_blank"
      variant="body2"
      href={href}
      underline={underline}
      sx={{
        ...sx,
        [theme.breakpoints.down('md')]: {
          cursor: 'default!important',
          opacity: '1!important'
        }
      }}
    >
      {content}
    </TextLink>
  )
}
