import React, { useContext } from 'react';
import Iframe from 'react-iframe';
import { CompanyContext } from '../../contexts/Company';

interface OwnProps {
  height: string;
  width: string;
}

export const GoogleMap: React.FC<OwnProps> = (props) => {
  const { height, width } = props;
  const { company } = useContext(CompanyContext);
  return (
    <Iframe
      url={company.iframe}
      width={width}
      height={height}
      loading={'lazy'}
    />
  )
}
