import { Box, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { styled } from '@mui/system';
import { MainImage } from './MainImage';
import { palette, theme } from '../../theme';
import { useTranslation } from 'react-i18next';

interface OwnProps {
  title: string;
  imageName: string;
}

const CustomBox = styled(Box)({
  position: 'absolute',
  background: palette.secondary.contrastText,
  bottom: -48,
  margin: 'auto',
  height: 100,
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: '32px 48px 40px',
  width: '35%',
  "&::before": {
    content: '""',
    position: 'absolute',
    bottom: 0,
    display: 'block',
    width: 72,
    height: 2,
    background: palette.primary.light
  },
  [theme.breakpoints.down('md')]: {
    padding: 16,
    width: '90%',
    "&::before": {
      width: 48
    }
  },
  [theme.breakpoints.down('sm')]: {
    width: '85%'
  }
});

export const TopView: React.FC<OwnProps> = (props) => {
  const { title, imageName } = props;
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  return (
    <Box
      p={'16px 0'}
      position={'relative'}
    >
      <MainImage imageName={imageName} />
      <CustomBox>
        <Typography
          color={palette.primary.main}
          fontSize={isSmallScreen ? 30 : 40}
        >
          {title}
        </Typography>
        <Typography
          color={palette.primary.contrastText}
          fontSize={isSmallScreen ? 15 : 20}
        >
          {t(title)}
        </Typography>
      </CustomBox>
    </Box>
  )
}
