import React from 'react';
import { Box } from '@mui/material';

interface OwnProps {
  imageName: string;
}

export const MainImage: React.FC<OwnProps> = (props) => {
  const { imageName } = props;
  return (
    <Box
      style={{
        backgroundImage: `url("/images/${imageName}")`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50% 50%',
        backgroundSize: 'cover',
        filter: 'brightness(0.6)',
        height: 200,
        width: '100%',
      }}
    />
  )
}
