import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';
import { palette, theme } from '../../theme';
import { useWindowSize } from 'react-use';
import { headerContents, serviceList } from '../../utils/MenuList';
import { Box, Button, ClickAwayListener, Collapse, IconButton, List, ListItemButton, ListItemText, Typography, useMediaQuery } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import { StateContext } from '../../contexts/State';

const CustomTypography = styled(Typography)({
  fontSize: 15,
  fontWeight: 'bold',
  color: palette.primary.contrastText,
  letterSpacing: '0.03em'
});

const CustomSubTypography = styled(Typography)({
  fontSize: '11px !important',
  fontWeight: 400,
  color: 'rgba(0, 0, 0, 0.6)',
  letterSpacing: '0.03em'
});

const CustomMenu = styled(Box)({
  marginLeft: 12,
  "&:before": {
    marginRight: 15,
    width: 1,
    height: 16,
    background: 'rgba(0, 0, 0, 0.2)',
    transform: 'translateY(2px)',
    display: 'inline-block',
    content: "''",
  }
});

const CustomButton = styled(Button)({
  fontSize: 14,
  transition: 'opacity 0.6s ease',
  color: palette.primary.contrastText,
  lineHeight: 2,
  letterSpacing: '0.03em',
  "&:hover": {
    background: 'none !important',
    opacity: 0.5,
  }
});

const CustomIconButton = styled(IconButton)({
  transition: 'opacity 0.6s ease',
  color: palette.primary.contrastText,
  letterSpacing: '0.03em',
  "& p": {
    fontSize: 14,
  },
  "& svg": {
    fontSize: '1.6rem'
  },
  "&:hover": {
    background: 'none !important',
    opacity: 0.5,
  }
});

const CustomCollapse = styled(Collapse)({
  position: 'absolute',
  background: 'rgb(0, 58, 136, .9)'
});

const CustomListItemButton = styled(ListItemButton)({
  padding: '5px 0',
  transition: 'opacity 0.6s ease',
  "& div": {
    marginTop: 0,
    marginBottom: 0
  },
  "&:hover": {
    background: 'none !important',
    opacity: 0.5,
  }
});

export const HeaderMenu: React.FC = () => {
  const navigate = useNavigate();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();
  const { headerRef, setLoading } = useContext(StateContext);
  const { height, width } = useWindowSize();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [menuWidth, setMenuWidth] = useState<number>(0);
  const [menuLeft, setMenuLeft] = useState<number>(0);
  const ele = useRef<HTMLButtonElement>(null);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleClickAway = () => {
    setIsOpen(false);
  }

  useEffect(() => {
    if (ele.current) {
      const rect = ele.current.getBoundingClientRect();
      setMenuWidth(rect.width * 1.8);
      setMenuLeft(-(rect.width / 2));
    }
  }, [ele, height, width]);

  return (
    <Box
      mr={6}
      sx={{
        flexGrow: 1,
        display: {
          xs: 'none',
          md: 'flex'
        }
      }}
    >
      {headerContents.flatMap((content, idx) => {
        if (idx === 0) {
          return (
            <Box
              key={idx}
              position="relative"
            >
              <CustomIconButton
                ref={ele}
                key={idx}
                disableRipple
                onClick={handleClick}
              >
                <Box>
                  <CustomTypography>{content.text}</CustomTypography>
                  <CustomSubTypography>{t(content.text)}</CustomSubTypography>
                </Box>
                <Box pr={.5} />
                {isOpen ? (<ExpandLessIcon />) : (<ExpandMoreIcon />)}
              </CustomIconButton>
              <CustomCollapse
                in={isOpen}
                timeout="auto"
                unmountOnExit
                style={{
                  left: menuLeft,
                  width: menuWidth
                }}
              >
                <ClickAwayListener onClickAway={handleClickAway}>
                  <List
                    disablePadding
                    sx={{
                      margin: '10px 0'
                    }}
                  >
                    {serviceList.flatMap((item, idx) => {
                      return (
                        <CustomListItemButton
                          key={idx}
                          disableRipple
                          onClick={() => {
                            setLoading(true);
                            const pathName = window.location.pathname;
                            if (pathName === '/service') {
                              const id = item.link.replace("#", "");
                              const element = document.getElementById(id);
                              if (element && headerRef && headerRef.current) {
                                const headerRect = headerRef.current.getBoundingClientRect();
                                const rect = element.getBoundingClientRect();
                                let targetTop = 0;
                                if (isMediumScreen) {
                                  targetTop = (rect.top + window.scrollY + 72) - headerRect.height * 2;
                                } else {
                                  targetTop = (rect.top + window.scrollY + 72) - headerRect.height;
                                }
                                setIsOpen(false);
                                setTimeout(() => {
                                  window.scrollTo({
                                    top: targetTop,
                                    behavior: 'smooth'
                                  });
                                }, 1000);
                                setTimeout(() => {
                                  setLoading(false);
                                }, 2000);
                              }
                            } else {
                              setTimeout(() => {
                                setIsOpen(false);
                                navigate(`/service${item.link}`);
                                window.scrollTo({
                                  top: 0,
                                  behavior: 'smooth',
                                });
                              }, 1000);
                            }
                          }}
                        >
                          <ListItemText
                            primary={item.text}
                            primaryTypographyProps={{
                              color: palette.secondary.contrastText,
                              fontSize: 15,
                              fontWeight: 'bold',
                              textAlign: 'center'
                            }}
                            secondary={t(item.text)}
                            secondaryTypographyProps={{
                              color: 'rgb(255, 255, 255, .5)',
                              fontSize: 11,
                              textAlign: 'center',
                            }}
                          />
                        </CustomListItemButton>
                      );
                    })}
                  </List>
                </ClickAwayListener>
              </CustomCollapse>
            </Box>
          );
        } else {
          return (
            <CustomMenu key={idx}>
              <CustomButton
                key={idx}
                disableRipple
                onClick={() => {
                  setLoading(true);
                  setTimeout(() => {
                    navigate(content.link);
                  }, 1000);
                  setTimeout(() => {
                    window.scrollTo({
                      top: 0,
                      behavior: 'smooth'
                    });
                  }, 2000);
                  setTimeout(() => {
                    setLoading(false);
                  }, 3000);
                }}
              >
                <Box>
                  <CustomTypography>{content.text}</CustomTypography>
                  <CustomSubTypography>{t(content.text)}</CustomSubTypography>
                </Box>
              </CustomButton>
            </CustomMenu>
          );
        }
      })}
    </Box>
  )
}