import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Divider, Typography, useMediaQuery } from '@mui/material';
import { palette, theme } from '../../theme';
import { styled } from '@mui/system';
import { newsPathReference } from '../../firebase';
import { getBytes } from 'firebase/storage';
import { NewsContext } from '../../contexts/News';
import { TStorage } from '../../models/TStorage';
import { NewsInfoComponent } from './NewsInfoComponent';
import { NewsContentComponent } from './NewsContentComponent';
import { TNews } from '../../models/TNews';
import { useNavigate } from 'react-router-dom';
import { StateContext } from '../../contexts/State';
import { IcoArrowRight } from '../../images/IcoArrowRight';

interface OwnProps {
  onSetDetail: React.Dispatch<React.SetStateAction<TNews>>;
}

const CustomContentTitle = styled(Typography)({
  fontSize: 25,
  [theme.breakpoints.down('md')]: {
    fontSize: 20,
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 18,
  }
});

export const NewNewsInfo: React.FC<OwnProps> = (props) => {
  const { onSetDetail } = props;
  const { news } = useContext(NewsContext);
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { setLoading } = useContext(StateContext);
  const [images, setImages] = useState<TStorage[]>([]);
  const [isHover, setIsHover] = useState<number | null>(null);
  const [currentNews, setCurrentNews] = useState<TNews[]>([]);

  useEffect(() => {
    if (news.length === 0) return;
    setCurrentNews(news.slice(0, 6));
    const getImageName = news.flatMap((v) => v.imageName);
    if (getImageName.length === 0) return;
    (async () => {
      for (let i = 0; i < news.length; i++) {
        if (i > 0) return;
        const getImage = await getBytes(newsPathReference(news[i].imageName));
        const binaryData = btoa(Array.from(new Uint8Array(getImage), e => String.fromCharCode(e)).join(""));
        setImages((previous) => {
          return [
            ...previous.filter((v) => v.id !== news[i].id),
            {
              id: news[i].id,
              image: binaryData
            }
          ];
        });
      }
    })()
  }, [news]);

  return (
    <>
      {!isMediumScreen && !isSmallScreen && (
        <NewsInfoComponent
          images={images}
          isHover={isHover}
          news={currentNews}
          onSetIsHover={setIsHover}
          onSetDetail={onSetDetail}
        />
      )}
      {isMediumScreen && (
        <Box p={2}>
          <Box
            bgcolor={palette.background.paper}
            borderTop={`2px solid ${palette.primary.main}`}
          >
            <Box mt={5} />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <CustomContentTitle
                color={palette.primary.contrastText}
                fontWeight="bold"
              >
                最新のお知らせ
              </CustomContentTitle>
            </Box>
            {!isSmallScreen ? (<Box mt={3} />) : (<Box mt={4} />)}
            <Box
              mr={2}
              ml={2}
            >
              {!isSmallScreen && (
                <Divider
                  variant="fullWidth"
                  style={{
                    borderColor: palette.info.dark
                  }}
                />
              )}
            </Box>
            <NewsContentComponent
              backgroundColor={palette.background.paper}
              images={images}
              isChange={null}
              isHover={isHover}
              news={currentNews}
              onSetIsHover={setIsHover}
              onSetDetail={onSetDetail}
            />
            <Box
              display="flex"
              justifyContent="center"
            >
              <Button
                disableRipple
                variant="contained"
                sx={{
                  background: palette.primary.dark,
                  border: `1px solid ${palette.primary.dark}`,
                  color: palette.secondary.contrastText,
                  fontWeight: 'bold',
                  padding: '15px 30px',
                  transition: '.3s background ease-out',
                  [theme.breakpoints.up('md')]: {
                    "&:hover": {
                      background: palette.secondary.contrastText,
                      border: `1px solid ${palette.primary.dark}`,
                      color: palette.primary.contrastText,
                    }
                  },
                  [theme.breakpoints.down('md')]: {
                    cursor: 'default',
                    "&:hover": {
                      boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)!important'
                    }
                  }
                }}
                onClick={() => {
                  setLoading(true);
                  setTimeout(() => {
                    navigate('/news');
                  }, 1000);
                  setTimeout(() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }, 2000);
                  setTimeout(() => {
                    setLoading(false);
                  }, 3500);
                }}
              >
                <Typography
                  fontSize={14}
                  fontWeight="bold"
                  color={palette.secondary.contrastText}
                >
                  一覧へ戻る
                </Typography>
                <Box mr={.5} />
                <IcoArrowRight
                  height={'100%'}
                  width={20}
                  color={palette.secondary.contrastText}
                />
              </Button>
            </Box>
            <Box pt={4} />
          </Box>
        </Box>
      )}
    </>
  )
}