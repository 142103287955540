import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/system';
import { theme } from './theme';
import { RequireAuth } from './components/atoms/RequireAuth';
import { Page } from './components/molecules/Page';
import { NotFound } from './components/pages/NotFound';
import { Home } from './components/pages/Home';
import { Provider } from './components/molecules/Provider';
import Login from './components/pages/Login';
import { Contact } from './components/pages/Contact';
import { Company } from './components/pages/Company';
import { Service } from './components/pages/Service';
import { Employment } from './components/pages/Employment';
import { News } from './components/pages/News';
import { NewsDetail } from './components/pages/NewsDetail';
import { RequireProject } from './components/atoms/RequireProject';
import { PrivacyPolicy } from './components/pages/PrivacyPolicy';

export const App = () => {
	return (
		<ThemeProvider theme={theme}>
			<Provider>
				<Router>
					<Routes>
						<Route path="/login" element={
							<RequireProject>
								<Page component={Login} />
							</RequireProject>
						} />
						<Route path="/" element={
							<RequireAuth>
								<Page component={Home} />
							</RequireAuth>
						} />
						<Route path="/contact" element={
							<RequireAuth>
								<Page component={Contact} />
							</RequireAuth>
						} />
						<Route path="/company" element={
							<RequireAuth>
								<Page component={Company} />
							</RequireAuth>
						} />
						<Route path="/service" element={
							<RequireAuth>
								<Page component={Service} />
							</RequireAuth>
						} />
						<Route path="/service:id" element={
							<RequireAuth>
								<Page component={Service} />
							</RequireAuth>
						} />
						<Route path="/employment" element={
							<RequireAuth>
								<Page component={Employment} />
							</RequireAuth>
						} />
						<Route path="/news" element={
							<RequireAuth>
								<Page component={News} />
							</RequireAuth>
						} />
						<Route path="/news/:id" element={
							<RequireAuth>
								<Page component={NewsDetail} />
							</RequireAuth>
						} />
						<Route path="/privacy" element={
							<RequireAuth>
								<Page component={PrivacyPolicy}/>
							</RequireAuth>
						} />
						<Route path="*" element={<NotFound />} />
					</Routes>
				</Router>
			</Provider>
		</ThemeProvider>
	);
}