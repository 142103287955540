import { Box } from '@mui/material';
import React from 'react';
import { styled } from '@mui/system';
import { palette, theme } from '../../theme';
import { Copyright } from '../atoms/Copyright';
import { FooterLogo } from '../atoms/FooterLogo';
import { FooterMenu } from '../molecules/FooterMenu';

const CustomFooterInner = styled(Box)({
  padding: '100px 100px 30px',
  [theme.breakpoints.down('md')]: {
    padding: '65px 30px 30px',
    width: 'auto'
  }
});

const CustomContents = styled(Box)({
  display: 'flex',
  maxWidth: 1152,
  width: '100%',
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'center'
  }
});

export const Footer: React.FC = () => {
  return (
    <Box
      id="footer"
      m={'0 auto'}
      bgcolor={palette.primary.main}
      borderTop={'2px solid rgb(255, 255, 255, .1)'}
      position="relative"
      zIndex={1}
    >
      <CustomFooterInner
        m={'auto'}
        boxSizing={'border-box'}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <CustomContents>
          <FooterMenu />
          <FooterLogo />
        </CustomContents>
        <Copyright />
      </CustomFooterInner>
    </Box>
  )
}