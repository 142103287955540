import React, { useContext, useEffect, useRef } from 'react';
import { styled } from '@mui/system';
import { ListItemText, Typography, useMediaQuery } from '@mui/material';
import { palette, theme } from '../../theme';
import LaunchIcon from '@mui/icons-material/Launch';
import { TextLinkComponent } from './TextLinkComponent';
import { formatTelNumber } from '../../utils/Format';
import { language } from '../../config';
import { StateContext } from '../../contexts/State';

interface OwnProps {
  content: string;
  title: string;
  height: number;
  width: number;
  onSetAddressHeight: React.Dispatch<React.SetStateAction<number>>;
}

const CustomContent = styled(ListItemText)({
  margin: 0,
  padding: '16px 40px',
  width: '80%',
  [theme.breakpoints.down('md')]: {
    padding: 16
  },
  [theme.breakpoints.down('sm')]: {
    padding: '24px 0',
    width: '100%'
  }
});

export const CompanyContent: React.FC<OwnProps> = (props) => {
  const { content, height, title, width, onSetAddressHeight } = props;
  const lang = language === 'ja' ? 'jp' : language;
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const ref = useRef<HTMLDivElement>(null);
  const { loading } = useContext(StateContext);

  useEffect(() => {
    if (ref) {
      const current = ref.current;
      if (current) {
        const rect = current.getBoundingClientRect();
        onSetAddressHeight(rect.height);
      }
    }
    // eslint-disable-next-line
  }, [height, loading, ref, width]);

  return (
    <>
      {title !== '電話番号' && title !== '所在地' && (
        <CustomContent
          primary={content}
          primaryTypographyProps={{
            color: palette.primary.contrastText,
            fontSize: isSmallScreen ? 16 : 18,
            textAlign: isSmallScreen ? 'center' : 'unset'
          }}
        />
      )}
      {title === '所在地' && (
        <CustomContent
          ref={ref}
          primary={content}
          primaryTypographyProps={{
            color: palette.primary.contrastText,
            fontSize: isSmallScreen ? 16 : 18,
            textAlign: isSmallScreen ? 'center' : 'unset'
          }}
          secondary={
            <TextLinkComponent
              href={`https://www.google.com/maps/search/?api=1&query=${content}`}
              content={
                <>
                  <Typography
                    variant="caption"
                    color={'#003A88'}
                    fontSize={14}
                    style={{ marginRight: 4 }}
                  >
                    GoogleMapで見る
                  </Typography>
                  <LaunchIcon
                    sx={{
                      color: '#003A88',
                      width: 15,
                      height: 15
                    }}
                  />
                </>
              }
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: 'fit-content',
                "&:hover": { opacity: .8 }
              }}
              underline={'none'}
            />
          }
          secondaryTypographyProps={{
            fontSize: isSmallScreen ? 14 : 16,
            display: 'flex',
            textAlign: isSmallScreen ? 'center' : 'unset',
            justifyContent: isSmallScreen ? 'center' : 'flex-start'
          }}
        />
      )}
      {title === '電話番号' && (
        <CustomContent
          primary={
            isSmallScreen ? (
              <TextLinkComponent
                content={formatTelNumber(content, lang)}
                href={`tel:${formatTelNumber(content, lang)}`}
                sx={{
                  fontSize: 20,
                  color: '#003A88',
                  letterSpacing: '0.2em',
                }}
                underline={'none'}
              />
            ) : (
              <Typography
                color={palette.primary.contrastText}
                fontSize={isSmallScreen ? 16 : 18}
                letterSpacing={'0.2em'}
              >
                {formatTelNumber(content, lang)}
              </Typography>
            )
          }
          primaryTypographyProps={{
            color: palette.primary.contrastText,
            fontSize: isSmallScreen ? 16 : 18,
            textAlign: isSmallScreen ? 'center' : 'unset'
          }}
        />
      )}
    </>
  )
}