import { FormControl, MenuItem, OutlinedInput, Select, SelectChangeEvent, SxProps, Theme } from '@mui/material';
import React from 'react';
import { palette, theme } from '../../theme';

interface OwnProps {
  items: string[];
  selectedItem: string;
  sx: SxProps<Theme>;
  width: number;
  onHandleChange: (e: SelectChangeEvent) => void;
}

export const SelectBox: React.FC<OwnProps> = (props) => {
  const { items, selectedItem, sx, width, onHandleChange } = props;
  return (
    <FormControl
      sx={{
        m: 1,
        background: palette.secondary.contrastText,
        width: width
      }}
    >
      <Select
        input={
          <OutlinedInput
            sx={{
              fontSize: 16,
              fontWeight: 'bold',
              [theme.breakpoints.down('md')]: {
                '& .MuiSelect-select': {
                  cursor: 'default!important'
                }
              }
            }}
          />
        }
        value={selectedItem}
        onChange={onHandleChange}
      >
        {items.map((item) => (
          <MenuItem
            key={item}
            value={item}
            sx={sx}
          >
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
