import dayjs from 'dayjs';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { palette } from '../../theme';
import { Events, animateScroll as scroll, scrollSpy } from 'react-scroll';
import { useWindowSize } from 'react-use';
import { useScroll, useMotionValueEvent } from 'framer-motion';
import { useCookies } from 'react-cookie';
import { StateContext } from '../../contexts/State';
import { Car } from '../../images/Car';

const CustomBox = styled(Box)({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  display: 'flex',
  justifyContent: 'flex-start',
  zIndex: 20,
});

const CustomMotionBar = styled(Box)({
  animation: 'progress-bar-stripes 1s linear infinite',
  color: palette.primary.contrastText,
  backgroundImage: 'linear-gradient(45deg,#ffffff26 25%,#0000 0,#0000 50%,#ffffff26 0,#ffffff26 75%,#0000 0,#0000)',
  backgroundSize: '1rem 1rem',
  height: 16,
  backgroundColor: palette.primary.main,
  transition: 'width .5s',
});

const CustomIconBox = styled(Box)({
  position: 'fixed',
  transition: 'left .5s',
});

const CustomAnimation = styled(Box)({
  position: 'absolute',
  top: -8,
});

export const ProgressBar: React.FC = () => {
  const { scrollY, scrollYProgress } = useScroll();
  const { width } = useWindowSize();
  const { setLoading } = useContext(StateContext);
  const [cookies, setCookie, removeCookie] = useCookies();
  const [pw, setPw] = useState<number>(0);
  const [pageScrollY, setPageScrollY] = useState<number>(0);

  useEffect(() => {
    setPw(Math.floor(width * scrollYProgress.get()));
    // eslint-disable-next-line
  },[scrollYProgress.get(), width]);

  useMotionValueEvent(scrollY, 'change', (latest) => {
    setPageScrollY(latest);
  });

  useEffect(() => {
    if (cookies && cookies.scroll) {
      scroll.scrollTo(cookies.scroll, 'smooth');

      Events.scrollEvent.register('begin', (to, element) => {
        removeCookie('scroll');
      });

      Events.scrollEvent.register('end', (to, element) => {
        setLoading(false);
      });

      scrollSpy.update();

      return () => {
        Events.scrollEvent.remove('begin');
        Events.scrollEvent.remove('end');
      };
    } else {
      setLoading(false);
      return;
    }
    // eslint-disable-next-line
  }, [cookies]);

  const handleBeforeUnload = useCallback(() => {
    const cookieExpiration = dayjs().add(1, 'days').format('YYYY-MM-DD HH:mm:ss').toString();
    const cookieDate = new Date(cookieExpiration);
    const y = pageScrollY;
    const options = {
      path: '/',
      expires: cookieDate
    };
    setCookie('scroll', y, options);
    // eslint-disable-next-line
  }, [pageScrollY]);

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
  }, [handleBeforeUnload])

  return (
    <CustomBox>
      <CustomMotionBar
        className='progress'
        style={{
          width: pw > width - 30 ? pw + 30 : pw,
          borderRadius: pw > width - 30 ? 'unset' : '0 30px 30px 0'
        }}
      />
      <CustomIconBox
        width={16}
        height={16}
        left={pw > width - 30 ? pw : pw - 20}
      >
        <CustomAnimation
          left={pw > width - 30 ? 30 : -18}
          style={{
            transition: pw > width - 30 ? 'left 2s ease' : 'unset'
          }}
        >
          <Car
            width={32}
            height={32}
            color={palette.secondary.contrastText}
          />
        </CustomAnimation>
      </CustomIconBox>
    </CustomBox>
  )
}