import React, { useContext } from 'react';
import { styled } from '@mui/system';
import { Box } from '@mui/material';
import { sideMenuBottom } from '../../utils/MenuList';
import { CustomItemButton, CustomItemTypography, CustomSubItemTypography, palette } from '../../theme';
import { SocialMedia } from './SocialMedia';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'react-use';
import { StateContext } from '../../contexts/State';
import { useNavigate } from 'react-router-dom';

interface OwnProps {
  onSetIsToggled: React.Dispatch<React.SetStateAction<boolean>>;
  onSetSideMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CustomMenuItem = styled(Box)({
  width: 'calc((100% - 25px) / 2) !important',
  borderBottom: '1px solid rgba(255, 255, 255, 0.15) !important',
  "& .ps-menu-button": {
    padding: '0 !important',
    paddingBottom: '10px !important',
    transition: 'opacity 0.6s ease',
  },
  "& .ps-menu-button:hover": {
    background: 'transparent !important',
    opacity: 0.5,
  }
});

const CustomMenu = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  marginBottom: 48
});

export const SideMenuBottom: React.FC<OwnProps> = (props) => {
  const { onSetIsToggled, onSetSideMenuOpen } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const { setLoading } = useContext(StateContext);
  return (
    <Box>
      <CustomMenu>
        {sideMenuBottom.flatMap((item, idx) => {
          return (
            <CustomMenuItem
              key={idx}
              style={{
                marginLeft: [1].includes(item.id) ? 25 : 0,
              }}
            >
              <CustomItemButton
                disableRipple
                onClick={() => {
                  setLoading(true);
                  setTimeout(() => {
                    navigate(item.link);
                  }, 1000);
                  setTimeout(() => {
                    onSetIsToggled(false);
                    onSetSideMenuOpen(false);
                    window.scrollTo({
                      top: 0,
                      behavior: 'smooth'
                    });
                  }, 2000);
                  setTimeout(() => {
                    setLoading(false);
                  }, 3500);
                }}
              >
                <Box>
                  <CustomItemTypography fontSize={width > 320 ? `15px!important` : `11px!important`}>{item.text}</CustomItemTypography>
                  <CustomSubItemTypography fontSize={width > 320 ? `9!important` : `8px!important`}>{t(item.text)}</CustomSubItemTypography>
                </Box>
              </CustomItemButton>
            </CustomMenuItem>
          );
        })}
      </CustomMenu>
      <SocialMedia color={palette.secondary.contrastText} />
    </Box>
  )
}