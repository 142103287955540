import React from 'react';
import Login from '../pages/Login';
import { TContext } from '../../models/TContext';
import { NotFound } from '../pages/NotFound';

export const RequireProject: React.FC<TContext> = () => {
  const prd = process.env.REACT_APP_ENV === "prd";
  if (!prd) {
    return LoginComponent();
  }
  return <NotFound />;
}

const LoginComponent = () => {
  return <Login />;
};