import React, { useContext, useMemo } from 'react';
import { TextLink, palette, theme } from '../../theme';
import { styled } from '@mui/system';
import { Box, Divider, IconButton, Typography, useMediaQuery } from '@mui/material';
import { Logo } from '../../images/Logo';
import { SocialMedia } from '../molecules/SocialMedia';
import { CompanyContext } from '../../contexts/Company';
import { formatDay, formatTelNumber } from '../../utils/Format';
import { language } from '../../config';
import { toDayjs } from '../../firebase';
import { StateContext } from '../../contexts/State';
import { useNavigate } from 'react-router-dom';
import { TextLinkComponent } from './TextLinkComponent';
import { useWindowSize } from 'react-use';

const CustomFooterLogo = styled(Box)({
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    width: '100%',
  }
});

const CustomDivider = styled(Divider)({
  borderColor: 'rgb(255, 255, 255, .1)',
  borderWidth: 1
});

export const FooterLogo: React.FC = () => {
  const lang = language === 'ja' ? 'jp' : language;
  const googleLink = "https://www.google.com/maps/search/?api=1";
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const { setLoading } = useContext(StateContext);
  const { company } = useContext(CompanyContext);
  const businessHours = useMemo(() => {
    const openingTime = toDayjs(company.openingTime).format('HH:mm').toString();
    const closingTime = toDayjs(company.closingTime).format('HH:mm').toString();
    return `${openingTime}〜${closingTime}`;
  }, [company]);

  const widthLogo = () => {
    if (960 <= width && width < 1100) {
      return '50%';
    } else if (isMediumScreen || isSmallScreen) {
      return '100%';
    }
    return '40%';
  };

  return (
    <CustomFooterLogo
      display="flex"
      style={{
        width: widthLogo()
      }}
    >
      {isMediumScreen && (<Box mt={6} />)}
      <CustomDivider
        orientation="vertical"
        variant="middle"
        flexItem
      />
      {isMediumScreen && (<Box mt={5} />)}
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        width={1}
      >
        {/* [TODO]: リンク先を指定する */}
        <IconButton
          disableRipple
          sx={{
            [theme.breakpoints.down('md')]: {
              cursor: 'default'
            }
          }}
          onClick={() => {
            setLoading(true);
            setTimeout(() => {
              navigate('/');
            }, 1000);
            setTimeout(() => {
              window.scrollTo({
                top: 0,
                behavior: 'smooth'
              });
            }, 2000);
            setTimeout(() => {
              setLoading(false);
            }, 3500);
          }}
        >
          <Logo
            fill={palette.secondary.contrastText}
            transform={isMediumScreen ? 'scale(1.0)' : 'scale(1.3)'}
            height={102}
            width={177}
          />
        </IconButton>
        {isMediumScreen ? (<Box mb={2} />) : (<Box mb={3} />)}
        <Box
          display="flex"
          flexDirection="column"
        >
          <TextLink
            rel="noopener noreferrer"
            target="_blank"
            variant="body2"
            /// [TODO]: 住所がわかったら書き換える
            href={`${googleLink}&query=${company.address}`}
            underline={'none'}
            sx={{
              fontSize: 16,
              padding: '2px 8px',
              [theme.breakpoints.down('md')]: {
                cursor: 'default'
              }
            }}
          >
            {company.address}
          </TextLink>
          {company.tel && (
            <>
              {isSmallScreen ? (
                <TextLinkComponent
                  content={`TEL.${formatTelNumber(company.tel, lang)}`}
                  href={`tel:${formatTelNumber(company.tel, lang)}`}
                  sx={{
                    fontSize: 16,
                    padding: '2px 8px',
                  }}
                  underline={'none'}
                />
              ) : (
                <Typography
                  variant="body2"
                  color={palette.secondary.contrastText}
                  fontSize={16}
                  p={'2px 8px'}
                >
                  TEL.{formatTelNumber(company.tel, lang)}
                </Typography>
              )}
            </>
          )}
          {/* [TODO] 営業時間と休業日を確認する */}
          <Box
            display="flex"
            alignItems="center"
            padding={'2px 8px'}
          >
            <Typography
              variant="body2"
              color={palette.secondary.contrastText}
            >
              営業時間: {businessHours}
            </Typography>
            <Box pr={.5} />
            <Typography
              variant="body2"
              color={palette.secondary.contrastText}
            >
              [定休日 {formatDay(company.regularClosingDays)}・祝日]
            </Typography>
          </Box>
        </Box>
        <Box mb={3} />
        <SocialMedia color={palette.secondary.contrastText} />
      </Box>
    </CustomFooterLogo>
  )
}