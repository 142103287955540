import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';
import { CenterBox, CenterColumnBox, CustomMainText, palette, theme } from '../../theme';
import { ContentTitle } from '../atoms/ContentTitle';
import { IcoArrowRight } from '../../images/IcoArrowRight';
import { IcoArrowLeft } from '../../images/IcoArrowLeft';

interface OwnProps {
  name: string;
  email: string;
  tel: string;
  content: string;
  textCount: number;
  onSetConfirmStatus: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: () => Promise<void>;
}

const CustomLabelRequired = styled(Box)({
  background: palette.primary.main,
  borderRadius: 5,
  paddingTop: 2,
  paddingBottom: 2,
  paddingRight: 10,
  paddingLeft: 10
});

const CustomRequiredLabel = styled(Typography)({
  fontSize: 18,
  fontWeight: 400,
  color: palette.secondary.contrastText,
  [theme.breakpoints.down('sm')]: {
    fontSize: 16
  }
});

const CustomLabelBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

const CustomLabel = styled(Typography)({
  fontSize: 18,
  fontWeight: 'bold',
  color: palette.primary.contrastText,
  [theme.breakpoints.down('sm')]: {
    fontSize: 16
  }
});

const CustomTypography = styled(Typography)({
  border: '1px solid black',
  borderRadius: 4,
  color: palette.primary.contrastText,
  fontSize: 18,
  height: 29,
  padding: 8,
  width: '-webkit-fill-available',
  [theme.breakpoints.down('sm')]: {
    fontSize: 16
  }
});

export const ConfirmContactForm: React.FC<OwnProps> = (props) => {
  const { name, email, tel, content, textCount, onSetConfirmStatus, handleSubmit } = props;
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [isBackButtonHover, setIsBackButtonHover] = useState<boolean>(false);
  const [isSendButtonHover, setIsSendButtonHover] = useState<boolean>(false);

  useEffect(() => {
    const el = document.getElementById('confirm');
    if (el) {
      el.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }, []);
  return (
    <Box
      id="confirm"
      bgcolor={palette.secondary.contrastText}
      borderTop={`2px solid ${palette.primary.main}`}
      maxWidth={1152}
      width={1}
    >
      {isSmallScreen ? (<Box mt={3} />) : (<Box mt={5} />)}
      <CenterColumnBox>
        <Box p={'8px 16px'}>
          <ContentTitle title={'お問い合わせ確認フォーム'} />
        </Box>
      </CenterColumnBox>
      {isSmallScreen ? (<Box mt={3} />) : (<Box mt={5} />)}
      <Box
        display="flex"
        justifyContent="center"
      >
        <Box
          width={.9}
          p={'8px 16px'}
        >
          <CustomMainText textAlign="center">
            ご返信に3営業日ほどお時間をいただいております。<br />
            3営業日を過ぎても返信がない場合は、お手数ですが再度お問い合わせをお願いいたします。
          </CustomMainText>
        </Box>
      </Box>
      {isSmallScreen ? (<Box mt={3} />) : (<Box mt={5} />)}
      <CenterBox>
        <CenterColumnBox
          pt={3}
          pb={3}
          pr={2}
          pl={2}
          width={.9}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: isSmallScreen ? '100%' : '70%'
            }}
          >
            <Box width={1}>
              <CustomLabelBox>
                <CustomLabel>お名前</CustomLabel>
                <Box mr={4} />
                <CustomLabelRequired>
                  <CustomRequiredLabel>
                    必須
                  </CustomRequiredLabel>
                </CustomLabelRequired>
              </CustomLabelBox>
              <Box mt={.5} />
              <CustomTypography>{name}</CustomTypography>
            </Box>
            <Box mt={2} />
            <Box width={1}>
              <CustomLabelBox>
                <CustomLabel>メールアドレス</CustomLabel>
                <Box mr={4} />
                <CustomLabelRequired>
                  <CustomRequiredLabel>
                    必須
                  </CustomRequiredLabel>
                </CustomLabelRequired>
              </CustomLabelBox>
              <Box mt={.5} />
              <CustomTypography>{email}</CustomTypography>
            </Box>
            <Box mt={2} />
            <Box width={1}>
              <CustomLabel>電話番号</CustomLabel>
              <Box mt={.5} />
              <CustomTypography>{tel}</CustomTypography>
            </Box>
            <Box mt={2} />
            <Box width={1}>
              <CustomLabelBox>
                <CustomLabel>お問い合わせ内容</CustomLabel>
                <Box mr={4} />
                <CustomLabelRequired>
                  <CustomRequiredLabel>
                    必須
                  </CustomRequiredLabel>
                </CustomLabelRequired>
              </CustomLabelBox>
              <Box mt={.5} />
              <Box>
                <CustomTypography
                  style={{
                    height: 300,
                    padding: 8,
                    width: '-webkit-fill-available'
                  }}
                >
                  {content}
                </CustomTypography>
                <Box
                  display="flex"
                  justifyContent="space-between"
                >
                  <Typography
                    color={palette.primary.contrastText}
                    fontSize={16}
                  >
                    {textCount}/500
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box mt={5} />
            <Box
              width={1}
              display="flex"
              justifyContent="space-between"
            >
              <Button
                disableRipple
                variant="contained"
                sx={{
                  background: palette.primary.dark,
                  border: `1px solid ${palette.primary.dark}`,
                  color: palette.secondary.contrastText,
                  fontWeight: 'bold',
                  padding: '15px 30px',
                  transition: '.3s background-color ease-out',
                  width: '40%',
                  [theme.breakpoints.up('md')]: {
                    "&:hover": {
                      background: palette.secondary.contrastText,
                      border: `1px solid ${palette.primary.dark}`,
                      color: palette.primary.contrastText,
                    }
                  },
                  [theme.breakpoints.down('md')]: {
                    cursor: 'default',
                    "&:hover": {
                      boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)!important'
                    }
                  }
                }}
                onClick={() => onSetConfirmStatus(false)}
                onMouseEnter={() => setIsBackButtonHover(true)}
                onMouseLeave={() => setIsBackButtonHover(false)}
              >
                <IcoArrowLeft
                  height={'100%'}
                  width={20}
                  color={!isMediumScreen && isBackButtonHover ? palette.primary.contrastText : palette.secondary.contrastText}
                />
                <Box ml={.5} />
                <Typography
                  fontSize={14}
                  fontWeight="bold"
                  color={!isMediumScreen && isBackButtonHover ? palette.primary.contrastText : palette.secondary.contrastText}
                >
                  戻る
                </Typography>
              </Button>
              <Button
                disableRipple
                variant="contained"
                sx={{
                  background: palette.primary.dark,
                  border: `1px solid ${palette.primary.dark}`,
                  color: palette.secondary.contrastText,
                  fontWeight: 'bold',
                  padding: '15px 30px',
                  transition: '.3s background-color ease-out',
                  width: '40%',
                  [theme.breakpoints.up('md')]: {
                    "&:hover": {
                      background: palette.secondary.contrastText,
                      border: `1px solid ${palette.primary.dark}`,
                      color: palette.primary.contrastText,
                    }
                  },
                  [theme.breakpoints.down('md')]: {
                    cursor: 'default',
                    "&:hover": {
                      boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)!important'
                    }
                  }
                }}
                onClick={handleSubmit}
                onMouseEnter={() => setIsSendButtonHover(true)}
                onMouseLeave={() => setIsSendButtonHover(false)}
              >
                <Typography
                  fontSize={14}
                  fontWeight="bold"
                  color={!isMediumScreen && isSendButtonHover ? palette.primary.contrastText : palette.secondary.contrastText}
                >
                  送信
                </Typography>
                <Box mr={.5} />
                <IcoArrowRight
                  height={'100%'}
                  width={20}
                  color={!isMediumScreen && isSendButtonHover ? palette.primary.contrastText : palette.secondary.contrastText}
                />
              </Button>
            </Box>
          </Box>
        </CenterColumnBox>
      </CenterBox>
      {isSmallScreen ? (<Box mt={5} />) : (<Box mt={10} />)}
    </Box>
  )
}
