import { Box, Dialog, Typography, useMediaQuery } from '@mui/material';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import React from 'react';
import { styled } from '@mui/system';
import { SubmitButton } from '../atoms/SubmitButton';
import { CenterColumnBox, FlexBox, palette, theme } from '../../theme';

interface OwnProps {
  open: boolean;
  title: string;
  message: string;
  submitLabel: string;
  onSubmit(): void;
  onClose(): void;
}

const CustomTypography = styled(Typography)({
  fontSize: 16,
  color: palette.primary.contrastText,
});

export const ConfirmDialog: React.FC<OwnProps> = (props) => {
  const { open, title, message, submitLabel, onClose, onSubmit } = props;
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = () => {
    onClose();
  }
  const handleSubmit = () => {
    onSubmit();
    onClose();
  }

  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      open={open}
      onClose={handleClose}
    >
      <Box p={3} width={isSmallScreen ? 'auto' : 300}>
        <CenterColumnBox>
          <MarkEmailReadIcon fontSize="large" />
          <Box mt={1} />
          <CustomTypography>
            {title}
          </CustomTypography>
          <Box mt={2} />
          <CustomTypography>
            {message}
          </CustomTypography>
        </CenterColumnBox>
        <Box mt={2} />
        <FlexBox justifyContent="center">
          <FlexBox>
            <SubmitButton
              disabled={false}
              text={submitLabel}
              onSubmit={handleSubmit}
            />
          </FlexBox>
        </FlexBox>
      </Box>
    </Dialog>
  )
}
