import { styled } from '@mui/system';
import { Box, Typography, useMediaQuery } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { palette, theme } from '../../theme';
import { StateContext } from '../../contexts/State';
import { useWindowSize } from 'react-use';

const CustomCopy = styled(Box)({
  position: 'absolute',
  top: '30%',
  left: '5vw',
  zIndex: 1
});

const CustomCopyText = styled(Typography)({
  color: palette.secondary.contrastText,
  fontFamily: "'Noto Sans JP', sans-serif",
  fontSize: '40px',
  textShadow: '0 0 5px rgba(0,0,0,0.3)',
  [theme.breakpoints.down('md')]: {
    fontSize: '30px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '23px',
  }
});

const CustomCopySubText = styled(Typography)({
  color: palette.secondary.contrastText,
  fontFamily: "'Noto Sans JP', sans-serif",
  fontSize: '18px',
  lineHeight: '180%',
  textShadow: '0 0 5px rgba(0,0,0,0.3)',
  [theme.breakpoints.down('md')]: {
    fontSize: '16px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
  }
});

const CustomMainImage = styled(Box)({
  backgroundImage: 'url("/images/mainview.png")',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '50% 50%',
  backgroundSize: 'cover',
  height: '100%',
  width: '100%',
});

export const MainView: React.FC = () => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { width } = useWindowSize();
  const { headerRef } = useContext(StateContext);
  const [headerHeight, setHeaderHeight] = useState<number>(0);

  useEffect(() => {
    if (headerRef) {
      const current = headerRef.current;
      if (current) {
        const rect = current.getBoundingClientRect();
        const height = rect.height;
        setHeaderHeight(height);
      }
    }
  }, [headerRef, width]);

  return (
    <Box
      id="mainview"
      height={'90vh'}
      position="relative"
      style={{
        marginTop: headerHeight
      }}
    >
      <Box>
        <CustomCopy>
          <CustomCopyText>
            あなたの
            <CustomCopyText
              variant="caption"
              color={`${palette.primary.light}!important`}
              fontWeight="bold"
            >
              大切なお車
            </CustomCopyText>
          </CustomCopyText>
          <CustomCopyText>預ける安心、ここにあります。</CustomCopyText>
          {isSmallScreen ? (<Box mt={.5} />) : (<Box mt={2} />)}
          <Box>
            <CustomCopySubText>当社では、自動車整備士が厳格にチェックし、<br />不正のない車検、安全性を追求した<br />お車の点検をお約束します。</CustomCopySubText>
          </Box>
        </CustomCopy>
      </Box>
      <CustomMainImage />
    </Box>
  )
}