import React from 'react';
import { NoImage } from '../../images/NoImage';

interface OwnProps {
  alt: string;
  image: string | null;
  style: React.CSSProperties;
}

export const Image: React.FC<OwnProps> = (props) => {
  const { alt, image, style } = props;
  return (
    <>
      {!image ? (
        <NoImage
          className="news-image"
          style={{
            ...style,
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: '50%',
            left: '50%',
            display: 'block',
            transition: '.3s transform ease-in'
          }}
        />
      ) : (
        <img
          className="news-image"
          alt={alt}
          src={`data:image/png;base64,${image}`}
          style={{
            ...style,
            width: '100%',
            position: 'absolute',
            top: '50%',
            left: '50%',
            display: 'block',
            transition: '.3s transform ease-in'
          }}
        />
      )}
    </>
  )
}