import { Box, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { styled } from '@mui/system';
import { theme } from '../../theme';
import { useWindowSize } from 'react-use';

const CustomTypography = styled(Typography)({
  transform: 'scale(0.95)',
});

export const Copyright: React.FC = () => {
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { width } = useWindowSize();
  return (
    <Box m={isMediumScreen ? '30px 0 10px 0' : '80px 0 10px 0'}>
      <CustomTypography
        textAlign="center"
        letterSpacing="0.1em"
        color="rgba(185, 188, 190, 0.7)"
        fontSize={width < 300 ? 6 : 8}
        fontWeight={400}
        fontFamily='"EB Garamond", serif'
      >
        Copyright © 2024 Garage Wide. All rights reserved
      </CustomTypography>
    </Box>
  )
}
