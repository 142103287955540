import React, { useContext, useEffect, useState } from 'react';
import { styled } from '@mui/system';
import { CenterColumnBox, palette, theme } from '../../theme';
import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import { ContentTitle } from '../atoms/ContentTitle';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import InstagramIcon from '@mui/icons-material/Instagram';
import { IcoArrowRight } from '../../images/IcoArrowRight';
import { InstagramContext } from '../../contexts/Instagram';
import { CompanyContext } from '../../contexts/Company';

const CustomMainImageBox = styled(Box)({
  aspectRatio: '1/1',
  overflow: 'hidden',
  position: 'relative',
  [theme.breakpoints.up('md')]: {
    cursor: 'pointer',
  }
});

const CustomMainImage = styled(Box)({
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '0 0',
  backgroundSize: 'cover',
  transition: '.5s transform ease-in',
  width: '100%',
  position: 'absolute',
  top: '50%',
  left: '50%',
  display: 'block',
});

export const InstagramView: React.FC = () => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { posts } = useContext(InstagramContext);
  const { company } = useContext(CompanyContext);
  const [isHover, setIsHover] = useState<number | null>(null);
  const [isButtonHover, setIsButtonHover] = useState<boolean>(false);
  const [blanks, setBlanks] = useState<number[]>([]);

  useEffect(() => {
    if (posts.length > 0 && posts.slice(0, 12)) {
      const list = [];
      let count = 0;
      if (![4, 8, 12].includes(posts.slice(0, 12).length)) {
        if (posts.slice(0, 12).length < 4) {
          count = 4 - posts.slice(0, 12).length;
        } else if (posts.slice(0, 12).length < 8) {
          count = 8 - posts.slice(0, 12).length;
        } else if (posts.slice(0, 12).length < 12) {
          count = 12 - posts.slice(0, 12).length;
        }
        for (let n = 0; n < count; n++) {
          list.push(n);
        }
      }
      setBlanks(list);
    }
  }, [posts]);

  return (
    <>
      {posts.length === 0 ? (
        <Box />
      ) : (
        <Box
          display="flex"
          justifyContent="center"
        >
          <Box
            maxWidth={1152}
            width={1}
          >
            <Box
              bgcolor={palette.secondary.contrastText}
              borderTop={`2px solid ${palette.primary.main}`}
            >
              <CenterColumnBox justifyContent="center">
                <Box pt={3} />
                <Box
                  borderRadius={1.5}
                  p={'8px 16px'}
                >
                  <ContentTitle title={'インスタグラム'} />
                </Box>
                <Box pt={5} />
                <Box
                  display="flex"
                  flexWrap="wrap"
                  justifyContent="center"
                  width={1}
                >
                  {posts.length > 0 && posts.slice(0, 12).flatMap((post, idx) => {
                    return (
                      <CustomMainImageBox
                        key={idx}
                        className="news-image-box"
                        m={.5}
                        width={isMediumScreen ? .45 : .23}
                        onMouseEnter={() => setIsHover(idx)}
                        onMouseLeave={() => setIsHover(null)}
                      >
                        <CustomMainImage
                          className="news-image"
                          height={1}
                          style={{
                            backgroundImage: `url(${post.mediaUrl})`,
                            transform: !isMediumScreen && isHover === idx ? 'translate(-50%, -50%) scale(1.1)' : 'translate(-50%, -50%) scale(1.0)',
                          }}
                        />
                        <Button
                          disableRipple
                          component={Link}
                          target="_blank"
                          to={post.permalink}
                          sx={{
                            height: '100%',
                            width: '100%',
                            [theme.breakpoints.down('md')]: {
                              cursor: 'default',
                            }
                          }}
                        >
                          <Box
                            position="absolute"
                            top={0}
                            bottom={0}
                            right={0}
                            left={0}
                            pb={2.5}
                            pl={2.5}
                            margin="auto"
                            zIndex={1}
                            bgcolor={!isMediumScreen && isHover === idx ? 'rgb(0,0,0,.3)' : 'transparent'}
                          >
                            {post.children && (
                              <FilterNoneIcon
                                sx={{
                                  color: palette.secondary.contrastText,
                                  top: 10,
                                  right: 10,
                                  margin: 'auto',
                                  position: 'absolute'
                                }}
                              />
                            )}
                            {/* コメント数、いいね数を表示する */}
                            {!isMediumScreen && isHover === idx && (
                              <Box
                                display="flex"
                                alignItems="flex-end"
                                justifyContent="flex-start"
                                height={1}
                              >
                                <Box
                                  display="flex"
                                  justifyContent="center"
                                >
                                  <FavoriteIcon sx={{ color: palette.secondary.contrastText }} />
                                  <Box mr={1} />
                                  <Typography
                                    color={palette.secondary.contrastText}
                                    fontSize={14}
                                    fontWeight="bold"
                                  >
                                    {post.likeCount}
                                  </Typography>
                                </Box>
                                <Box mr={3} />
                                <Box
                                  display="flex"
                                  justifyContent="center"
                                >
                                  <ChatBubbleIcon sx={{ color: palette.secondary.contrastText }} />
                                  <Box mr={1} />
                                  <Typography
                                    color={palette.secondary.contrastText}
                                    fontSize={14}
                                    fontWeight="bold"
                                  >
                                    {post.commentsCount}
                                  </Typography>
                                </Box>
                              </Box>
                            )}
                          </Box>
                        </Button>
                      </CustomMainImageBox>
                    );
                  })}
                  {posts.length > 0 && blanks.length && (
                    <>
                      {blanks.flatMap((blank) => {
                        return (
                          <CustomMainImageBox
                            key={blank}
                            className="news-image-box"
                            m={.5}
                            width={isMediumScreen ? .45 : .23}
                          />
                        );
                      })}
                    </>
                  )}
                </Box>
                <Box pt={10} />
                <Button
                  disableRipple
                  component={Link}
                  variant="contained"
                  target="_blank"
                  to={`https://www.instagram.com/${company.instagram}/`}
                  sx={{
                    background: palette.primary.dark,
                    border: `1px solid ${palette.primary.dark}`,
                    color: palette.secondary.contrastText,
                    fontWeight: 'bold',
                    padding: '15px 30px',
                    transition: '.3s background ease-out',
                    [theme.breakpoints.up('md')]: {
                      "&:hover": {
                        background: palette.secondary.contrastText,
                        border: `1px solid ${palette.primary.dark}`,
                        color: palette.primary.contrastText,
                      }
                    },
                    [theme.breakpoints.down('md')]: {
                      cursor: 'default',
                      "&:hover": {
                        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)!important'
                      }
                    }
                  }}
                  onMouseEnter={() => setIsButtonHover(true)}
                  onMouseLeave={() => setIsButtonHover(false)}
                >
                  <InstagramIcon />
                  <Box mr={1} />
                  <Typography
                    fontSize={16}
                    fontWeight="bold"
                    variant="caption"
                    color={!isMediumScreen && isButtonHover ? palette.primary.contrastText : palette.secondary.contrastText}
                  >
                    フォローする
                  </Typography>
                  <Box mr={.5} />
                  <IcoArrowRight
                    height={'100%'}
                    width={20}
                    color={!isMediumScreen && isButtonHover ? palette.primary.contrastText : palette.secondary.contrastText}
                  />
                </Button>
                <Box pt={8} />
              </CenterColumnBox>
            </Box>
          </Box>
        </Box>
      )}
      {isSmallScreen ? (<Box mt={5} />) : (<Box mt={10} />)}
    </>
  )
}