import { Box, useMediaQuery } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { TopView } from '../atoms/TopView';
import { CustomMainText, palette, theme } from '../../theme';
import { ContentTitle } from '../atoms/ContentTitle';
import { StateContext } from '../../contexts/State';
import { useWindowSize } from 'react-use';

export const Employment: React.FC = () => {
  const { headerRef } = useContext(StateContext);
  const { width } = useWindowSize();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [headerHeight, setHeaderHeight] = useState<number>(0);

  useEffect(() => {
    window.document.title = "GARAGE WIDE | 採用情報";
  },[]);

  useEffect(() => {
    if (headerRef) {
      const current = headerRef.current;
      if (current) {
        const rect = current.getBoundingClientRect();
        const height = rect.height;
        setHeaderHeight(height);
      }
    }
  }, [headerRef, width]);

  return (
    <Box style={{ marginTop: headerHeight }}>
      <TopView title={'採用情報'} imageName={'maintenance2.jpg'} />
      <Box mt={15} />
      {isSmallScreen ? (<Box mt={3} />) : (<Box mt={8} />)}
      <Box
        display="flex"
        justifyContent="center"
        p={2}
      >
        <Box
          bgcolor={palette.secondary.contrastText}
          borderTop={`2px solid ${palette.primary.main}`}
          maxWidth={1152}
          width={1}
        >
          <Box>
            {isSmallScreen ? (<Box mt={3} />) : (<Box mt={5} />)}
            <ContentTitle title={'募集要項'} />
            {isSmallScreen ? (<Box mt={3} />) : (<Box mt={5} />)}
            <CustomMainText>
              現在募集中の職種はございません。<br />
              募集が決まり次第、情報を更新いたします。
            </CustomMainText>
            {isSmallScreen ? (<Box mt={3} />) : (<Box mt={10} />)}
          </Box>
        </Box>
      </Box>
      {isSmallScreen ? (<Box mt={3} />) : (<Box mt={8} />)}
    </Box>
  )
}