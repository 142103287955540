import { styled } from '@mui/system';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { CenterBox, palette, theme } from '../../theme';
import { useWindowSize } from 'react-use';
import { serviceItems } from '../../utils/MenuList';
import { ServiceCard } from '../atoms/ServiceCard';
import { useNavigate } from 'react-router-dom';
import { StateContext } from '../../contexts/State';
import { ContentTitle } from '../atoms/ContentTitle';

const CustomMainText = styled(Typography)({
  color: palette.primary.contrastText,
  fontSize: '18px',
  lineHeight: '180%',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '16px',
  }
});

const CustomCenterBox = styled(CenterBox)({
  boxShadow: '0 0 5px rgba(255,255,255,.1)',
  overflow: 'hidden',
  position: 'relative',
  cursor: 'pointer',
  [theme.breakpoints.down('md')]: {
    cursor: 'default'
  }
});

const CustomAbsoluteBox = styled(Box)({
  position: 'absolute',
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  margin: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column'
});

const CustomTypography = styled(Typography)({
  color: palette.secondary.contrastText,
  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
  fontSize: '30px',
  fontWeight: 'bold',
  [theme.breakpoints.down('sm')]: {
    fontSize: '16px',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '20px',
  }
});

const CustomSubTypography = styled(Typography)({
  color: palette.secondary.contrastText,
  fontSize: '20px',
  opacity: .8,
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '16px',
  }
});

export const ServiceView: React.FC = () => {
  const ref = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const { setLoading } = useContext(StateContext);
  const [height, setHeight] = useState<number>(0);
  const [boxNum, setBoxNum] = useState<number | undefined>();

  useEffect(() => {
    if (ref) {
      const current = ref.current;
      if (current) {
        const rect = current.getBoundingClientRect();
      if (rect) {
        setHeight(rect.width / 2);
      }
      }
    }
  }, [ref, width]);

  return (
    <Box
      id="service-view"
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
    >
      {isSmallScreen ? (<Box mt={5} />) : (<Box mt={10} />)}
      <Box
        display="flex"
        justifyContent="center"
        width={1}
      >
        <Box
          bgcolor={palette.secondary.contrastText}
          borderTop={`2px solid ${palette.primary.main}`}
          p={2}
          width={1}
          maxWidth={1152}
        >
          <Box
            borderRadius={1.5}
            p={'8px 16px'}
          >
            <ContentTitle title={'サービス案内'} />
          </Box>
          {isSmallScreen ? (<Box mt={2} />) : (<Box mt={3} />)}
          <CustomMainText>
            Garage Wideは、中古車販売をはじめ、車検や点検サービスを提供しておりいます。<br />
            幅広い中古車を取扱っており、お客様の目的・条件に合わせた最適なお車をご提案させていただきます。<br/>
            また、経験豊富な整備士たちが、納車後の車検やアフターケアなども承っておりますので、お気軽にご相談ください。
          </CustomMainText>
          <Box mt={2} />
        </Box>
      </Box>
      {isSmallScreen ? (<Box mt={3} />) : (<Box mt={6} />)}
      <Box
        display="flex"
        justifyContent="center"
        width={1}
      >
        <Grid
          width={'85%!important'}
          container
          spacing={{ xs: 2, sm: 2, md: 2, lg: 2 }}
          columns={{ xs: 1, sm: 8, md: 8, lg: 8 }}
        >
          {serviceItems.flatMap((v, idx) => {
            return (
              <Grid
                key={idx}
                item
                xs={1}
                sm={4}
                md={4}
                lg={4}
              >
                <CustomCenterBox
                  onClick={() => {
                    setLoading(true);
                    setTimeout(() => {
                      navigate(`/service#${v.id}`);
                    }, 1000);
                  }}
                  onMouseEnter={() => setBoxNum(idx)}
                  onMouseLeave={() => setBoxNum(undefined)}
                >
                  <ServiceCard
                    boxNum={boxNum}
                    height={height}
                    imageUrl={v.imageUrl}
                    index={idx}
                    cardRef={ref}
                  />
                  <CustomAbsoluteBox>
                    <CustomTypography>{v.name}</CustomTypography>
                    <CustomSubTypography>{t(v.name)}</CustomSubTypography>
                  </CustomAbsoluteBox>
                </CustomCenterBox>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  )
}