import { Box, Divider, Typography, useMediaQuery } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { styled } from '@mui/system';
import { StateContext } from '../../contexts/State';
import { useWindowSize } from 'react-use';
import { TopView } from '../atoms/TopView';
import { palette, TextLink, theme } from '../../theme';
import { CompanyContext } from '../../contexts/Company';
import { formatTelNumber } from '../../utils/Format';
import { TextLinkComponent } from '../atoms/TextLinkComponent';
import { language } from '../../config';

const CustomMainTypography = styled(Typography)({
  color: palette.primary.main,
  fontSize: 20,
  fontWeight: 'bold',
  [theme.breakpoints.down('sm')]: {
    fontSize: 18,
    fontWeight: 'bold'
  }
});

const CustomSubTypography = styled(Typography)({
  color: palette.primary.contrastText,
  fontSize: 18,
  fontWeight: 400,
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
  }
});

const CustomTextLink = styled(TextLink)({
  color: palette.info.light,
  fontSize: 18,
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
  }
});

export const PrivacyPolicy: React.FC = () => {
  const lang = language === 'ja' ? 'jp' : language;
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { width } = useWindowSize();
  const { headerRef } = useContext(StateContext);
  const { company } = useContext(CompanyContext);
  const [headerHeight, setHeaderHeight] = useState<number>(0);

  useEffect(() => {
    window.document.title = "GARAGE WIDE | 個人情報保護方針";
  }, []);

  useEffect(() => {
    if (headerRef) {
      const current = headerRef.current;
      if (current) {
        const rect = current.getBoundingClientRect();
        const height = rect.height;
        setHeaderHeight(height);
      }
    }
  }, [headerRef, width]);

  return (
    <Box style={{ marginTop: headerHeight }}>
      <TopView title={'個人情報保護方針'} imageName={'privacy-policy.jpg'} />
      <Box mt={15} />
      {isSmallScreen ? (<Box mt={3} />) : (<Box mt={8} />)}
      <Box p={2}>
        <Box
          display="flex"
          justifyContent="center"
        >
          <Box
            bgcolor={palette.secondary.contrastText}
            borderTop={`2px solid ${palette.primary.main}`}
            maxWidth={1152}
            p={4}
            pb={5}
            width={1}
          >
            <CustomSubTypography>
              Garage Wide（以下，「当社」といいます。）は，本ウェブサイト上で提供するサービス（以下,「本サービス」といいます。）における，ユーザーの個人情報の取扱いについて，以下のとおりプライバシーポリシー（以下，「本ポリシー」といいます。）を定めます。
            </CustomSubTypography>
            <Box mt={3} />
            <Box>
              <Box>
                <Divider
                  orientation="vertical"
                  variant="fullWidth"
                  sx={{
                    bgcolor: palette.primary.main
                  }}
                />
                <CustomMainTypography>1. 個人情報</CustomMainTypography>
              </Box>
              <Box mt={2} />
              <Box>
                <CustomSubTypography>
                  「個人情報」とは，個人情報保護法にいう「個人情報」を指すものとし，生存する個人に関する情報であって，当該情報に含まれる氏名，生年月日，住所，電話番号，連絡先その他の記述等により特定の個人を識別できる情報及び容貌，指紋，声紋にかかるデータ，及び健康保険証の保険者番号などの当該情報単体から特定の個人を識別できる情報（個人識別情報）を指します。
                </CustomSubTypography>
              </Box>
            </Box>
            <Box mt={3} />
            <Box>
              <Box>
                <Divider
                  orientation="vertical"
                  variant="fullWidth"
                  sx={{
                    bgcolor: palette.primary.main
                  }}
                />
                <CustomMainTypography>2. 個人情報の収集方法</CustomMainTypography>
              </Box>
              <Box mt={2} />
              <Box>
                <CustomSubTypography>
                  「個人情報」とは，個人情報保護法にいう「個人情報」を指すものとし，生存する個人に関する情報であって，当該情報に含まれる氏名，生年月日，住所，電話番号，連絡先その他の記述等により特定の個人を識別できる情報及び容貌，指紋，声紋にかかるデータ，及び健康保険証の保険者番号などの当該情報単体から特定の個人を識別できる情報（個人識別情報）を指します。
                </CustomSubTypography>
              </Box>
            </Box>
            <Box mt={3} />
            <Box>
              <Box>
                <Divider
                  orientation="vertical"
                  variant="fullWidth"
                  sx={{
                    bgcolor: palette.primary.main
                  }}
                />
                <CustomMainTypography>3. 個人情報を収集・利用する目的</CustomMainTypography>
              </Box>
              <Box mt={2} />
              <Box>
                <CustomSubTypography>
                  当社が個人情報を収集・利用する目的は，以下のとおりです。
                </CustomSubTypography>
                <Box mt={2} />
                <CustomSubTypography>
                  1. 当社サービスの提供・運営のため
                </CustomSubTypography>
                <Box mt={1} />
                <CustomSubTypography>
                  2. ユーザーからのお問い合わせに回答するため（本人確認を行うことを含む）
                </CustomSubTypography>
                <Box mt={1} />
                <CustomSubTypography>
                  3. アクセス解析ツール運用のため
                </CustomSubTypography>
                <Box mt={1} />
                <CustomSubTypography>
                  4. 上記の利用目的に付随する目的
                </CustomSubTypography>
              </Box>
            </Box>
            <Box mt={3} />
            <Box>
              <Box>
                <Divider
                  orientation="vertical"
                  variant="fullWidth"
                  sx={{
                    bgcolor: palette.primary.main
                  }}
                />
                <CustomMainTypography>4. アクセス解析ツールについて</CustomMainTypography>
              </Box>
              <Box mt={2} />
              <Box>
                <CustomSubTypography>
                  当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を使用しています。このGoogleアナリティクスはデータの収集のためにCookieを使用しています。このデータは匿名で収集されており、個人を特定するものではありません。
                </CustomSubTypography>
                <Box mt={1} />
                <CustomSubTypography>
                  この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。この規約に関しての詳細は
                  のページや<CustomTextLink target="_blank" rel="noreferrer noopener" href={`https://marketingplatform.google.com/about/analytics/terms/jp/`}>Googleアナリティクスサービス利用規約</CustomTextLink>のページや<CustomTextLink target="_blank" rel="noreferrer noopener" href={"https://policies.google.com/technologies/ads?hl=ja"}>Googleポリシーと規約ページ</CustomTextLink>をご覧ください。
                </CustomSubTypography>
              </Box>
            </Box>
            <Box mt={3} />
            <Box>
              <Box>
                <Divider
                  orientation="vertical"
                  variant="fullWidth"
                  sx={{
                    bgcolor: palette.primary.main
                  }}
                />
                <CustomMainTypography>5. 利用目的の変更</CustomMainTypography>
              </Box>
              <Box mt={2} />
              <Box>
                <CustomSubTypography>
                  1. 当社は，利用目的が変更前と関連性を有すると合理的に認められる場合に限り，個人情報の利用目的を変更するものとします。
                </CustomSubTypography>
                <Box mt={1} />
                <CustomSubTypography>
                  2. 利用目的の変更を行った場合には，変更後の目的について，当社所定の方法により，ユーザーに通知し，または本ウェブサイト上に公表するものとします。
                </CustomSubTypography>
              </Box>
            </Box>
            <Box mt={3} />
            <Box>
              <Box>
                <Divider
                  orientation="vertical"
                  variant="fullWidth"
                  sx={{
                    bgcolor: palette.primary.main
                  }}
                />
                <CustomMainTypography>6. 個人情報の第三者提供</CustomMainTypography>
              </Box>
              <Box mt={2} />
              <Box>
                <CustomSubTypography>
                  1. 当社は，次に掲げる場合を除いて，あらかじめユーザーの同意を得ることなく，第三者に個人情報を提供することはありません。ただし，個人情報保護法その他の法令で認められる場合を除きます。
                </CustomSubTypography>
                <Box mt={1} />
                <Box ml={3}>
                  <CustomSubTypography>
                    1. 人の生命，身体または財産の保護のために必要がある場合であって，本人の同意を得ることが困難であるとき
                  </CustomSubTypography>
                  <Box mt={1} />
                  <CustomSubTypography>
                    2. 公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって，本人の同意を得ることが困難であるとき
                  </CustomSubTypography>
                  <Box mt={1} />
                  <CustomSubTypography>
                    3. 国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって，本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
                  </CustomSubTypography>
                  <Box mt={1} />
                  <CustomSubTypography>
                    4. 予め次の事項を告知あるいは公表し，かつ当社が個人情報保護委員会に届出をしたとき
                  </CustomSubTypography>
                  <Box mt={2} />
                  <Box ml={2}>
                    <CustomSubTypography>
                      1. 利用目的に第三者への提供を含むこと
                    </CustomSubTypography>
                    <Box mt={1} />
                    <CustomSubTypography>
                      2. 第三者に提供されるデータの項目
                    </CustomSubTypography>
                    <Box mt={1} />
                    <CustomSubTypography>
                      3. 第三者への提供の手段または方法
                    </CustomSubTypography>
                    <Box mt={1} />
                    <CustomSubTypography>
                      3. 本人の求めに応じて個人情報の第三者への提供を停止すること
                    </CustomSubTypography>
                    <Box mt={1} />
                    <CustomSubTypography>
                      4. 本人の求めを受け付ける方法
                    </CustomSubTypography>
                  </Box>
                </Box>
                <Box mt={1} />
                <CustomSubTypography>
                  2. 前項の定めにかかわらず，次に掲げる場合には，当該情報の提供先は第三者に該当しないものとします。
                </CustomSubTypography>
                <Box mt={1} />
                <Box ml={3}>
                  <CustomSubTypography>
                    1. 当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合
                  </CustomSubTypography>
                  <Box mt={1} />
                  <CustomSubTypography>
                    2. 合併その他の事由による事業の承継に伴って個人情報が提供される場合
                  </CustomSubTypography>
                  <Box mt={1} />
                  <CustomSubTypography>
                    3. 個人情報を特定の者との間で共同して利用する場合であって，その旨並びに共同して利用される個人情報の項目，共同して利用する者の範囲，利用する者の利用目的および当該個人情報の管理について責任を有する者の氏名または名称について，あらかじめ本人に通知し，または本人が容易に知り得る状態に置いた場合
                  </CustomSubTypography>
                </Box>
              </Box>
            </Box>
            <Box mt={3} />
            <Box>
              <Box>
                <Divider
                  orientation="vertical"
                  variant="fullWidth"
                  sx={{
                    bgcolor: palette.primary.main
                  }}
                />
                <CustomMainTypography>7. 個人情報の開示</CustomMainTypography>
              </Box>
              <Box mt={2} />
              <Box>
                <CustomSubTypography>
                  1. 当社は，本人から個人情報の開示を求められたときは，本人に対し，遅滞なくこれを開示します。ただし，開示することにより次のいずれかに該当する場合は，その全部または一部を開示しないこともあり，開示しない決定をした場合には，その旨を遅滞なく通知します。
                </CustomSubTypography>
                <Box mt={1} />
                <Box ml={3}>
                  <CustomSubTypography>
                    1. 本人または第三者の生命，身体，財産その他の権利利益を害するおそれがある場合
                  </CustomSubTypography>
                  <Box mt={1} />
                  <CustomSubTypography>
                    2. 当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合
                  </CustomSubTypography>
                  <Box mt={1} />
                  <CustomSubTypography>
                    3. その他法令に違反することとなる場合
                  </CustomSubTypography>
                </Box>
                <Box mt={1} />
                <CustomSubTypography>
                  2. 前項の定めにかかわらず，履歴情報および特性情報などの個人情報以外の情報については，原則として開示いたしません。
                </CustomSubTypography>
              </Box>
            </Box>
            <Box mt={3} />
            <Box>
              <Box>
                <Divider
                  orientation="vertical"
                  variant="fullWidth"
                  sx={{
                    bgcolor: palette.primary.main
                  }}
                />
                <CustomMainTypography>8. 個人情報の訂正および削除</CustomMainTypography>
              </Box>
              <Box mt={2} />
              <Box>
                <CustomSubTypography>
                  1. ユーザーは，当社の保有する自己の個人情報が誤った情報である場合には，当社が定める手続きにより，当社に対して個人情報の訂正，追加または削除（以下，「訂正等」といいます。）を請求することができます。
                </CustomSubTypography>
                <Box mt={1} />
                <CustomSubTypography>
                  2. 当社は，ユーザーから前項の請求を受けてその請求に応じる必要があると判断した場合には，遅滞なく，当該個人情報の訂正等を行うものとします。
                </CustomSubTypography>
                <Box mt={1} />
                <CustomSubTypography>
                  3. 当社は，前項の規定に基づき訂正等を行った場合，または訂正等を行わない旨の決定をしたときは遅滞なく，これをユーザーに通知します。
                </CustomSubTypography>
              </Box>
            </Box>
            <Box mt={3} />
            <Box>
              <Box>
                <Divider
                  orientation="vertical"
                  variant="fullWidth"
                  sx={{
                    bgcolor: palette.primary.main
                  }}
                />
                <CustomMainTypography>9. 個人情報の利用停止等</CustomMainTypography>
              </Box>
              <Box mt={2} />
              <Box>
                <CustomSubTypography>
                  1. 当社は，本人から，個人情報が，利用目的の範囲を超えて取り扱われているという理由，または不正の手段により取得されたものであるという理由により，その利用の停止または消去（以下，「利用停止等」といいます。）を求められた場合には，遅滞なく必要な調査を行います。
                </CustomSubTypography>
                <Box mt={1} />
                <CustomSubTypography>
                  2. 前項の調査結果に基づき，その請求に応じる必要があると判断した場合には，遅滞なく，当該個人情報の利用停止等を行います。
                </CustomSubTypography>
                <Box mt={1} />
                <CustomSubTypography>
                  3. 当社は，前項の規定に基づき利用停止等を行った場合，または利用停止等を行わない旨の決定をしたときは，遅滞なく，これをユーザーに通知します。
                </CustomSubTypography>
                <Box mt={1} />
                <CustomSubTypography>
                  4. 前2項にかかわらず，利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって，ユーザーの権利利益を保護するために必要なこれに代わるべき措置をとれる場合は，この代替策を講じるものとします。
                </CustomSubTypography>
              </Box>
            </Box>
            <Box mt={3} />
            <Box>
              <Box>
                <Divider
                  orientation="vertical"
                  variant="fullWidth"
                  sx={{
                    bgcolor: palette.primary.main
                  }}
                />
                <CustomMainTypography>10. プライバシーポリシーの変更</CustomMainTypography>
              </Box>
              <Box mt={2} />
              <Box>
                <CustomSubTypography>
                  1. 本ポリシーの内容は，法令その他本ポリシーに別段の定めのある事項を除いて，ユーザーに通知することなく，変更することができるものとします。
                </CustomSubTypography>
                <Box mt={1} />
                <CustomSubTypography>
                  2. 当社が別途定める場合を除いて，変更後のプライバシーポリシーは，本ウェブサイトに掲載したときから効力を生じるものとします。
                </CustomSubTypography>
              </Box>
            </Box>
            <Box mt={3} />
            <Box>
              <Box>
                <Divider
                  orientation="vertical"
                  variant="fullWidth"
                  sx={{
                    bgcolor: palette.primary.main
                  }}
                />
                <CustomMainTypography>11. お問い合わせ窓口</CustomMainTypography>
              </Box>
              <Box mt={2} />
              <Box>
                <CustomSubTypography>
                  本ポリシーに関するお問い合わせは，下記の窓口までお願いいたします。
                </CustomSubTypography>
                <Box mt={2} />
                <Box>
                  <CustomSubTypography>住所: {company.address}</CustomSubTypography>
                  <Box mt={.5} />
                  <CustomSubTypography>社名: {company.name}</CustomSubTypography>
                  <Box mt={.5} />
                  <CustomSubTypography>代表: {company.representative}</CustomSubTypography>
                  <Box mt={.5} />
                  <CustomSubTypography>メールアドレス: {company.email}</CustomSubTypography>
                  <Box mt={.5} />
                  {isSmallScreen ? (
                    <CustomSubTypography>
                      電話番号:
                      <Box mr={.2}/>
                      <TextLinkComponent
                        content={formatTelNumber(company.tel, lang)}
                        href={`tel:${formatTelNumber(company.tel, lang)}`}
                        sx={{
                          color: palette.info.light,
                          fontSize: 16,
                        }}
                        underline={'none'}
                      />
                    </CustomSubTypography>
                  ) : (
                    <CustomSubTypography>
                      電話番号: {formatTelNumber(company.tel, lang)}
                    </CustomSubTypography>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box mt={5} />
    </Box>
  )
}
