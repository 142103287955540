import React from 'react';
import { CenterColumnBox, CustomContentSubTitle, CustomContentTitle, palette } from '../../theme';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface OwnProps {
  title: string;
}

export const ContentTitle: React.FC<OwnProps> = (props) => {
  const { title } = props;
  const { t } = useTranslation();
  return (
    <CenterColumnBox>
      <CustomContentTitle variant="h1" >{title}</CustomContentTitle>
      <Box pt={.5} />
      <CustomContentSubTitle
        color={palette.primary.main}
        variant="body2"
        textAlign="center"
      >
        {t(title)}
      </CustomContentSubTitle>
    </CenterColumnBox>
  )
}