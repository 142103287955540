import { Box, IconButton, useMediaQuery } from '@mui/material';
import { CenterBox, CenterColumnBox, CustomMainText, palette, theme } from '../../theme';
import React from 'react';
import { ContentTitle } from '../atoms/ContentTitle';

export const ContactLine: React.FC = () => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box
      display="flex"
      justifyContent="center"
    >
      <Box
        bgcolor={palette.secondary.contrastText}
        borderTop={`2px solid ${palette.primary.main}`}
        maxWidth={1152}
        width={1}
      >
        {isSmallScreen ? (<Box mt={3} />) : (<Box mt={5} />)}
        <CenterColumnBox>
          <Box p={'8px 16px'}>
            <ContentTitle title={'LINEによるお問い合わせ'} />
          </Box>
        </CenterColumnBox>
        {isSmallScreen ? (<Box mt={3} />) : (<Box mt={5} />)}
        <Box
          display="flex"
          justifyContent="center"
        >
          <Box
            width={isMediumScreen ? .9 : .75}
            p={'8px 16px'}
          >
            <CustomMainText textAlign="center">
              LINEによるご予約も可能です。<br />
              以下のボタンもしくはQRコードにて友達追加のうえ、ご予約ください。
            </CustomMainText>
          </Box>
        </Box>
        {isSmallScreen ? (<Box mt={3} />) : (<Box mt={5} />)}
        <CenterBox>
          <CenterBox
            width={.8}
            pt={3}
            pb={3}
            pr={2}
            pl={2}
          >
            <IconButton
              disableRipple
              sx={{
                [theme.breakpoints.down('md')]: {
                  cursor: 'default'
                }
              }}
            >
              <Box
                width={230}
                component={'img'}
                src={'/images/line.png'}
              />
            </IconButton>
            {isSmallScreen ? (
              <Box />
            ) : (
              <>
                <Box mr={3} />
                <Box
                  width={180}
                  height={180}
                  component={'img'}
                  src={'/images/qr-code.png'}
                />
              </>
            )}
          </CenterBox>
        </CenterBox>
        {isSmallScreen ? (<Box mt={5} />) : (<Box mt={10} />)}
      </Box>
    </Box>
  )
}
