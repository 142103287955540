import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { MainView } from '../organisms/MainView';
import { ServiceView } from '../organisms/ServiceView';
import { InfoView } from '../organisms/InfoView';
import { NewsView } from '../organisms/NewsView';
import { InstagramView } from '../organisms/InstagramView';
import { AccessView } from '../organisms/AccessView';

export const Home: React.FC = () => {

  useEffect(() => {
    window.document.title = "GARAGE WIDE | 沖縄で中古車探し、車検・点検はガレージワイド";
  },[]);

  return (
    <Box
      mr={1}
      ml={1}
    >
      <MainView />
      <ServiceView />
      <InfoView />
      <NewsView />
      <InstagramView />
      <AccessView />
    </Box>
  )
}