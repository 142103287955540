
import { motion } from 'framer-motion';
import { styled } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { Flex } from '@chakra-ui/react';
import { palette, theme } from '../../theme';

interface OwnProps {
  boxRef: React.MutableRefObject<HTMLElement | null>;
  isLoop: boolean;
  parentSize: number;
}

const CustomDot = styled(Box)({
  borderRadius: 10,
  background: palette.secondary.contrastText
});

export const TextLoop: React.FC<OwnProps> = (props) => {
  const { boxRef, isLoop, parentSize } = props;
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [height, setHeight] = useState<number>(0);
  const [itemWidth, setItemWidth] = useState<number>(0);
  const [itemWidthWithGap, setItemWidthWithGap] = useState<number>(0);

  useEffect(() => {
    if (boxRef && boxRef.current) {
      const rect = boxRef.current.getBoundingClientRect();
      setHeight(rect.height - (13 * parentSize));
    }
    setItemWidth(5 * parentSize);
    setItemWidthWithGap((10 * parentSize) + 6);
  }, [boxRef, parentSize]);

  return (
    <Box
      position="relative"
      mx={'auto'}
      overflow="hidden"
      width={(itemWidthWithGap * 3) / 2}
    >
      <motion.div
        initial={{
          translateX: '-10%'
        }}
        animate={{
          translateX: '-50%'
        }}
        transition={{
          duration: 2,
          repeat: isLoop ? Infinity : undefined,
          ease: 'linear'
        }}
      >
        <Flex
          w={`${itemWidthWithGap * 6}px`}
          h={height}
          ml={`-${itemWidth}px`}
          gap={isSmallScreen ? 3 : 6}
          alignItems="flex-end"
        >
          {[".",".",".",".",".","."].flatMap((item, idx) => {
            return (
              <CustomDot
                key={idx}
                width={itemWidth}
                height={itemWidth}
              />
            );
          })}
        </Flex>
      </motion.div>
    </Box>
  )
};

