import { Box, useMediaQuery } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { theme } from '../../theme';
import { TopView } from '../atoms/TopView';
import { StateContext } from '../../contexts/State';
import { useWindowSize } from 'react-use'
import { ContactCall } from '../organisms/ContactCall';
import { ContactLine } from '../organisms/ContactLine';
import { ContactForm } from '../organisms/ContactForm';
import { CompanyContext } from '../../contexts/Company';

export const Contact: React.FC = () => {
  const { headerRef } = useContext(StateContext);
  const { width } = useWindowSize();
  const { company } = useContext(CompanyContext);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [headerHeight, setHeaderHeight] = useState<number>(0);

  useEffect(() => {
    window.document.title = "GARAGE WIDE | お問い合わせ";
  },[]);

  useEffect(() => {
    if (headerRef) {
      const current = headerRef.current;
      if (current) {
        const rect = current.getBoundingClientRect();
        const height = rect.height;
        setHeaderHeight(height);
      }
    }
  }, [headerRef, width]);

  return (
    <Box style={{ marginTop: headerHeight }}>
      <TopView title={'お問い合わせ'} imageName={'contact.jpg'} />
      <Box mt={15} />
      {isSmallScreen ? (<Box mt={3} />) : (<Box mt={8} />)}
      <Box p={2}>
        {company.tel && (<ContactCall />)}
        {isSmallScreen ? (<Box mt={5} />) : (<Box mt={10} />)}
        {company.tel && (<ContactLine />)}
        {isSmallScreen ? (<Box mt={5} />) : (<Box mt={10} />)}
        <ContactForm />
        {isSmallScreen ? (<Box mt={5} />) : (<Box mt={10} />)}
      </Box>
      {isSmallScreen ? (<Box mt={5} />) : (<Box mt={10} />)}
    </Box>
  )
}