import React, { useContext, useEffect, useRef, useState } from 'react';
import { FlexBox, palette, theme } from '../../theme';
import { Box, Toolbar, AppBar, IconButton, Container, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router';
import { HamburgerMenu } from '../atoms/HamburgerMenu';
import { SideMenu } from './SideMenu';
import { HeaderMenu } from '../molecules/HeaderMenu';
import { StateContext } from '../../contexts/State';
import { HeaderLogo } from '../../images/HeaderLogo';
import { HeaderText } from '../../images/HeaderText';

const CustomAppBar = styled(AppBar)({
  left: 0,
  right: 0,
  margin: '0 auto',
  transition: 'top 0.6s ease',
  paddingTop: 8,
  paddingBottom: 8,
  zIndex: 2
});

const CustomIconButton = styled(IconButton)({
  [theme.breakpoints.down('md')]: {
    cursor: 'pointer',
  },
  "&:hover": {
    background: 'none'
  }
});

export const Header: React.FC = () => {
  const ref = useRef<HTMLElement | null>(null);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const { bodyRef, handleHeaderRef, setLoading } = useContext(StateContext);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isToggled, setIsToggled] = useState<boolean>(false);

  const handleHamburgerMenu = () => {
    setIsOpen(!isOpen);
    setIsToggled(!isToggled);
  };

  useEffect(() => {
    if (ref) { handleHeaderRef(ref) }
    // eslint-disable-next-line
  }, [ref]);

  useEffect(() => {
    /// [TODO] サイドメニューを表示中はスクロールを禁止するか確認する
    if (bodyRef && isToggled) {
      bodyRef.classList.add('no_scroll');
    } else if (bodyRef && !isToggled) {
      bodyRef.classList.remove('no_scroll');
    }
  }, [bodyRef, isToggled]);

  return (
    <>
      <Box style={{ display: 'flex' }}>
        <CustomAppBar
          id="header"
          ref={ref}
          color="inherit"
          elevation={0}
          style={{
            top: 0,
            zIndex: 10,
            position: isMediumScreen ? 'fixed' : 'absolute'
          }}
        >
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <FlexBox
                width={1}
                justifyContent="space-between"
                alignItems="center"
              >
                <CustomIconButton
                  disableRipple
                  onClick={() => {
                    setLoading(true);
                    setTimeout(() => {
                      navigate('/');
                    }, 1000)
                    setTimeout(() => {
                      window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                      });
                    }, 2000);
                    setTimeout(() => {
                      setLoading(false);
                    }, 3500);
                  }}
                >
                  {isSmallScreen ? (
                    <>
                      <HeaderLogo height={42.17} width={60} fill={palette.primary.dark} />
                      <Box mr={1} />
                      <HeaderText height={10.91} width={150} fill={palette.primary.dark} />
                    </>
                  ) : (
                    <>
                      <HeaderLogo height={56.22} width={80} fill={palette.primary.dark} />
                      <Box mr={1} />
                      <HeaderText height={14.55} width={200} fill={palette.primary.dark} />
                    </>
                  )}
                </CustomIconButton>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <HeaderMenu />
                  <Box
                    pb={1.25}
                    width={48}
                    height={48}
                  >
                    <HamburgerMenu
                      isOpen={isOpen}
                      onHandleHamburgerMenu={handleHamburgerMenu}
                    />
                  </Box>
                </Box>
                <SideMenu
                  isOpen={isOpen}
                  isToggled={isToggled}
                  onHandleHamburgerMenu={handleHamburgerMenu}
                  onSetIsToggled={setIsToggled}
                  onSetIsOpen={setIsOpen}
                />
              </FlexBox>
            </Toolbar>
          </Container>
        </CustomAppBar>
      </Box>
    </>
  )
}