import React, { useContext } from 'react';
import { List, ListItem, ListItemButton, ListItemText, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';
import { theme } from '../../theme';
import { footerContents } from '../../utils/MenuList';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'react-use';
import { useNavigate } from 'react-router-dom';
import { StateContext } from '../../contexts/State';

const CustomList = styled(List)({
  width: '55%',
  display: 'flex',
  flexWrap: 'wrap',
  [theme.breakpoints.down('md')]: {
    width: '100%'
  }
});

const CustomListItem = styled(ListItem)({
  padding: '5px 0',
  width: 'calc((100% - 30px) / 2)',
  [theme.breakpoints.down('md')]: {
    width: 'calc(100% / 2)'
  }
});

const CustomListItemButton = styled(ListItemButton)({
  padding: 0,
  transition: 'opacity 0.6s ease',
  [theme.breakpoints.up('md')]: {
    '&:hover': {
      background: 'transparent !important',
      opacity: 0.5,
    }
  },
  [theme.breakpoints.down('md')]: {
    cursor: 'default',
    '&:hover': {
      background: 'transparent !important',
    }
  }
});

export const FooterMenu: React.FC = () => {
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const { setLoading } = useContext(StateContext);

  const widthMenu = () => {
    if (960 <= width && width < 1100) {
      return '50%';
    } else if (isMediumScreen || isSmallScreen) {
      return '100%';
    }
    return '55%';
  };

  const primaryFontSize = () => {
    if (width < 300) {
      return 12;
    } else if (isSmallScreen) {
      return 14;
    } else {
      return 16;
    }
  };

  const secondaryFontSize = () => {
    if (width < 300) {
      return 8;
    } else if (isSmallScreen) {
      return 10;
    } else {
      return 12;
    }
  };

  return (
    <CustomList
      style={{
        width: widthMenu()
      }}
    >
      {footerContents.flatMap((item, idx) => {
        return (
          <CustomListItem key={idx}>
            <CustomListItemButton
              disableRipple
              onClick={() => {
                setLoading(true);
                setTimeout(() => {
                  navigate(item.link);
                }, 1000);
                setTimeout(() => {
                  window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                  });
                }, 2000);
                setTimeout(() => {
                  setLoading(false);
                }, 3500);
              }}
            >
              <ListItemText
                primary={item.text}
                primaryTypographyProps={{
                  color: 'white',
                  fontSize: primaryFontSize(),
                  textAlign: isMediumScreen ? 'center' : 'unset',
                }}
                secondary={t(item.text)}
                secondaryTypographyProps={{
                  color: 'rgb(255, 255, 255, .5)',
                  fontSize: secondaryFontSize(),
                  textAlign: isMediumScreen ? 'center' : 'unset',
                }}
              />
            </CustomListItemButton>
          </CustomListItem>
        );
      })}
    </CustomList>
  )
}