import React from 'react';
import { CenterBox, palette, theme } from '../../theme';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { TServiceContent } from '../../models/TServiceContent';
import { useTranslation } from 'react-i18next';
import { DownArrowRight } from '../../images/DownArrowRight';
import { ContentTitle } from '../atoms/ContentTitle';

interface OwnProps {
  id: string;
  content: TServiceContent;
}

export const ServiceCardRight: React.FC<OwnProps> = (props) => {
  const { id, content } = props;
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();
  return (
    <CenterBox
      mt={3}
      mb={3}
      width={1}
    >
      <CenterBox
        id={id}
        position="relative"
        bgcolor={palette.secondary.contrastText}
        boxShadow={'0 0 5px rgba(0,0,0,0.3)'}
        borderRadius={.9}
        height={isMediumScreen ? 'auto' : '90vh'}
        flexDirection={isMediumScreen ? 'column' : 'unset'}
        m={'0 16px'}
      >
        {isMediumScreen && (
          <>
            <Box mt={2} />
            <ContentTitle title={content.name} />
          </>
        )}
        {isMediumScreen && (<Box mt={3} />)}
        <Box width={.85}>
          <CenterBox
            display="flex!important"
            alignItems="center"
            justifyContent="center"
            flexDirection={isMediumScreen ? 'column' : 'unset'}
          >
            <Box
              style={{
                backgroundImage: content.imageUrl,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: '50% 50%',
                backgroundSize: 'cover',
                borderRadius: isMediumScreen ? 0 : 10,
                width: isMediumScreen ? '100%' : '50%',
                height: 400,
              }}
            />
            <Box
              height={1}
              width={isMediumScreen ? 'auto' : .5}
              alignSelf={isMediumScreen ? 'unset' : 'flex-end'}

            >
              <Box
                children={content.content}
                style={{
                  padding: isMediumScreen ? '48px 24px' : 32,
                }}
              />
            </Box>
          </CenterBox>
        </Box>
        {!isMediumScreen && (
          <Box
            position="absolute"
            top={35}
            right={40}
            margin={'auto'}
          >
            <Box
              display="flex"
              alignSelf="flex-start"
            >
              <Box mt={1} />
              <Typography
                color={palette.primary.contrastText}
                fontSize={25}
                letterSpacing={'0.25em'}
                lineHeight={1.2}
                style={{
                  writingMode: 'vertical-rl'
                }}
              >
                {content.name}
              </Typography>
              <Typography
                // color={'rgba(0, 0, 0, 0.6)'}
                color={palette.primary.main}
                fontSize={16}
                letterSpacing={'0.25em'}
                lineHeight={1.2}
                style={{
                  writingMode: 'vertical-rl'
                }}
              >
                {t(content.name)}
              </Typography>
            </Box>
            <Box mt={11} />
            <Box
              style={{
                animation: 'floating-y 1.8s ease-in-out infinite alternate-reverse',
              }}
              ml={1.9}
            >
              <DownArrowRight />
            </Box>
          </Box>
        )}
      </CenterBox>
    </CenterBox>
  )
}