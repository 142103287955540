import { Box, Divider, Typography, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';
import { palette, theme } from '../../theme';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { TStorage } from '../../models/TStorage';
import { TNews } from '../../models/TNews';
import { Image } from '../atoms/Image';
import { toDayjs } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import { StateContext } from '../../contexts/State';
import { useWindowSize } from 'react-use';

interface OwnProps {
  backgroundColor: string;
  isChange: boolean | null;
  images: TStorage[];
  isHover: number | null;
  news: TNews[];
  onSetIsHover: React.Dispatch<React.SetStateAction<number | null>>;
  onSetDetail: React.Dispatch<React.SetStateAction<TNews>> | null;
}

const CustomCard = styled(Box)({
  alignItems: 'flex-start',
  cursor: 'pointer',
  display: 'flex',
  padding: '32px 16px',
  [theme.breakpoints.down('md')]: {
    cursor: 'default',
  },
  [theme.breakpoints.down('sm')]: {
    borderTopLeftRadius: '5px!important',
    borderTopRightRadius: '5px!important',
    borderBottomLeftRadius: '5px!important',
    borderBottomRightRadius: '5px!important',
    boxShadow: '0 0 5px rgba(0,0,0,0.3)',
    flexDirection: 'column'
  }
});

const CustomImageBox = styled(Box)({
  "&::before": {
    content: '""',
    background: 'rgba(0, 0, 0, 0)',
    borderRadius: 3,
    display: 'block',
    inset: 1,
    position: 'absolute',
    zIndex: 1,
  },
});

const CustomTitle = styled(Typography)({
  fontSize: 25,
  fontWeight: 'bold',
  transition: '.3s color ease-in',
  width: '100%'
});

const CustomDateTimeTitle = styled(Typography)({
  fontSize: 14,
  fontWeight: 'bold',
  color: palette.info.dark,
});

const CustomSubTitle = styled(Typography)({
  color: palette.info.light,
  fontSize: 14,
  fontWeight: 'bold',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

export const NewsContentComponent: React.FC<OwnProps> = (props) => {
  const { backgroundColor, isChange, images, isHover, news, onSetIsHover, onSetDetail } = props;
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { width } = useWindowSize();
  const { setLoading } = useContext(StateContext);
  const [itemHeight, setItemHeight] = useState<number>(0);
  const [itemsHeight, setItemsHeight] = useState<number>(0);

  useEffect(() => {
    if (ref) {
      const current = ref.current;
      if (current) {
        const rect = current.getBoundingClientRect();
        const height = rect.height;
        const data = news.slice(0, 6);
        setItemHeight(height + 16);
        const count = data.length === 1 ? 2 : data.length;
        if (isSmallScreen) {
          setItemsHeight((height + 16) * data.length);
        } else if (isMediumScreen) {
          if (isChange !== null && isChange) {
            setItemsHeight((height + 16) * data.length);
          } else {
            setItemsHeight((height + 16) * count);
          }
        } else {
          if (data.length === 1) {
            setItemsHeight((height + 16) * 2);
          } else {
            setItemsHeight((height + 16) * data.length);
          }
        }
      }
    }
  }, [isChange, isSmallScreen, isMediumScreen, news, ref, width]);

  return (
    <Box
      p={2}
      display="flex"
      justifyContent="center"
      width='-webkit-fill-available'
      height={isSmallScreen || news.length > 1 ? 'auto' : itemsHeight}
    >
      <Box maxWidth={1152} width={1}>
        {news.flatMap((value, idx) => {
          const find = images.find((v) => v.id === value.id);
          return (
            <Box key={idx} height={news.length < 2 ? itemHeight : 'auto'}>
              <CustomCard
                mt={isSmallScreen ? 2 : 0}
                mb={isSmallScreen ? 2 : 0}
                ref={idx === 0 ? ref : null}
                bgcolor={backgroundColor}
                borderRadius={idx === 0 || idx === news.length - 1 ? '5px 5px 0 0' : '0'}
                style={{
                  borderTopLeftRadius: !isSmallScreen && idx === 0 ? 5 : 0,
                  borderTopRightRadius: !isSmallScreen && idx === 0 ? 5 : 0,
                  borderBottomLeftRadius: !isSmallScreen && idx === news.length - 1 ? 5 : 0,
                  borderBottomRightRadius: !isSmallScreen && idx === news.length - 1 ? 5 : 0,
                }}
                onClick={() => {
                  setLoading(true);
                  setTimeout(() => {
                    if (onSetDetail) {
                      onSetDetail(value);
                    }
                    navigate(`/news/${value.id}`);
                  }, 1000);
                  setTimeout(() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }, 2000);
                  setTimeout(() => {
                    setLoading(false);
                  }, 3500);
                }}
                onMouseEnter={() => onSetIsHover(idx)}
                onMouseLeave={() => onSetIsHover(null)}
              >
                <CustomImageBox
                  className="news-image-box"
                  borderRadius={1}
                  height={160}
                  width={isSmallScreen ? 1 : .25}
                  overflow="hidden"
                  position="relative"
                  sx={{
                    "&::before": {
                      background: isHover === idx && !isMediumScreen && find ? 'rgba(23,28,44,.1)' : 'transparent'
                    }
                  }}
                >
                  <Image
                    alt={'new image'}
                    image={find ? find.image : null}
                    style={{
                      transform: isHover === idx && !isMediumScreen ? 'translate(-50%, -50%) scale(1.1)' : 'translate(-50%, -50%)'
                    }}
                  />
                </CustomImageBox>
                {isSmallScreen ? <Box mt={2} /> : <Box mr={5} />}
                <Box
                  display="flex"
                  alignItems="flex-start"
                  flexDirection="column"
                  width={isSmallScreen ? 1 : .7}
                >
                  <Box
                    className="news-title"
                    display="block"
                    overflow="hidden"
                    width={1}
                  >
                    <CustomTitle
                      color={isHover === idx && !isMediumScreen ? palette.info.light : palette.primary.contrastText}
                    >
                      {value.title}
                    </CustomTitle>
                  </Box>
                  <Box mt={2} />
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                    width={1}
                  >
                    <CustomDateTimeTitle>{toDayjs(value.updatedAt).format('YYYY.MM.DD')}</CustomDateTimeTitle>
                    <Box mr={1} />
                    <Divider
                      flexItem
                      orientation="vertical"
                      variant="fullWidth"
                      style={{
                        borderColor: palette.info.dark,
                        marginTop: 4,
                        marginBottom: 4,
                      }}
                    />
                    <Box mr={1} />
                    <CustomSubTitle>{value.category}</CustomSubTitle>
                  </Box>
                </Box>
              </CustomCard>
              {!isSmallScreen && idx !== news.length - 1 && (
                <Divider
                  variant="fullWidth"
                  style={{
                    borderColor: palette.info.dark
                  }}
                />
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  )
}