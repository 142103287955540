import React, { useContext, useEffect, useState } from 'react';
import { CenterColumnBox, palette, theme } from '../../theme';
import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import { GoogleMap } from '../atoms/GoogleMap';
import { ContentTitle } from '../atoms/ContentTitle';
import { TGeolocation } from '../../models/TCompany';
import { CompanyContext } from '../../contexts/Company';
import { IcoArrowRight } from '../../images/IcoArrowRight';

export const AccessView: React.FC = () => {
  const { company } = useContext(CompanyContext);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [location, setLocation] = useState<TGeolocation>({ lat: 0, lng: 0 });
  const [isButtonHover, setIsButtonHover] = useState<boolean>(false);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      let lat = position.coords.latitude;
      let lng = position.coords.longitude;
      setLocation({ lat, lng });
    });
  }, []);

  return (
    <Box
      display="flex"
      justifyContent="center"
    >
      <Box
        maxWidth={1152}
        width={1}
      >
        <Box
          bgcolor={palette.secondary.contrastText}
          borderTop={`2px solid ${palette.primary.main}`}
        >
          <CenterColumnBox justifyContent="center">
            <Box pt={3} />
            <Box
              borderRadius={1.5}
              p={'8px 16px'}
            >
              <ContentTitle title={'アクセス'} />
            </Box>
            <Box pt={5} />
            <GoogleMap width={'95%'} height={'450px'} />
            <Box pt={5} />
            <Button
              disableRipple
              component={Link}
              variant="contained"
              target="_blank"
              to={`https://www.google.com/maps/dir/?api=1&origin=${location.lat},${location.lng}&destination=${company.address}`}
              sx={{
                background: palette.primary.dark,
                border: `1px solid ${palette.primary.dark}`,
                color: palette.secondary.contrastText,
                fontWeight: 'bold',
                padding: '15px 30px',
                transition: '.3s background-color ease-out',
                [theme.breakpoints.up('md')]: {
                  "&:hover": {
                    background: palette.secondary.contrastText,
                    border: `1px solid ${palette.primary.dark}`,
                    color: palette.primary.contrastText,
                  }
                },
                [theme.breakpoints.down('md')]: {
                  cursor: 'default',
                  "&:hover": {
                    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)!important'
                  }
                }
              }}
              onMouseEnter={() => setIsButtonHover(true)}
              onMouseLeave={() => setIsButtonHover(false)}
            >
              <Typography
                fontSize={14}
                fontWeight="bold"
                color={!isMediumScreen && isButtonHover ? palette.primary.contrastText : palette.secondary.contrastText}
              >
                Google Mapsでナビする
              </Typography>
              <Box mr={.5} />
              <IcoArrowRight
                height={'100%'}
                width={20}
                animation={'floating-x 1.8s ease-in-out infinite alternate-reverse'}
                color={!isMediumScreen && isButtonHover ? palette.primary.contrastText : palette.secondary.contrastText}
              />
            </Button>
            <Box pt={5} />
          </CenterColumnBox>
        </Box>
        {isSmallScreen ? (<Box mt={10} />) : (<Box mt={20} />)}
      </Box>
    </Box>
  )
}