import { Box, Typography } from '@mui/material';
import React from 'react';
import { styled } from '@mui/system';
import { palette, theme } from '../../theme';
import { TextLinkComponent } from '../atoms/TextLinkComponent';
import { TNews } from '../../models/TNews';

interface OwnProps {
  news: TNews;
}

const CustomContent = styled(Box)({
  paddingRight: 64,
  paddingLeft: 64,
  [theme.breakpoints.down('md')]: {
    paddingRight: 16,
    paddingLeft: 16,
  }
});

const CustomContentText = styled(Typography)({
  color: palette.primary.contrastText,
  fontSize: 20,
  whiteSpace: 'pre-wrap',
  [theme.breakpoints.down('md')]: {
    fontSize: 20,
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
  }
});

export const NewsDetailContent: React.FC<OwnProps> = (props) => {
  const { news } = props;
  return (
    <Box
      p={2}
      display="flex"
      justifyContent="center"
    >
      <Box width={1} maxWidth={1152}>
        <Box
          bgcolor={palette.secondary.contrastText}
          borderTop={`2px solid ${palette.primary.main}`}
          width={1}
        >
          <Box pt={10} />
          <CustomContent>
            <CustomContentText>
              {news.content}
            </CustomContentText>
            {news.url && (
              <>
                <Box pt={3} />
                <TextLinkComponent
                  content={news.title}
                  href={news.url}
                  sx={{
                    color: '#003A88',
                    cursor: 'pointer',
                    fontSize: 20,
                    fontWeight: 'bold',
                    [theme.breakpoints.down('md')]: {
                      fontSize: 20,
                    },
                    [theme.breakpoints.down('sm')]: {
                      fontSize: 16,
                    }
                  }}
                  underline={'always'}
                />
              </>
            )}
          </CustomContent>
          <Box pt={10} />
        </Box>
      </Box>
    </Box>
  )
}