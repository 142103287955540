import { createContext, useEffect, useState } from 'react';
import { TPost } from '../models/TInstagram';
import { getInstagramPosts } from '../apis/InstagramApi';

interface IInstagramContext {
  posts: TPost[];
}

const InstagramContext = createContext<IInstagramContext>({
  posts: [],
});

const InstagramProvider = (props: any) => {
  const [posts, setPosts] = useState<TPost[]>([]);

  useEffect(() => {
    (async () => {
      const data = await getInstagramPosts();
      setPosts(data);
    })()
  }, []);

  return (
    <InstagramContext.Provider value={{ posts }}>
      {props.children}
    </InstagramContext.Provider>
  );
};

export { InstagramContext, InstagramProvider };