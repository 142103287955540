import React, { useEffect, useState, useCallback, useRef } from 'react';

export const onClickAction = (id: string) => {
  const el = document.getElementById(id);
  if (el) {
    return el.click();
  }
  return;
};

function useThrottle<T>(
  fn: (args?: T) => void,
  durationMS: number
) {
  const scrollingTimer = useRef<undefined | NodeJS.Timeout>();
  return useCallback(
    (args?: T) => {
      if (scrollingTimer.current) return;
      scrollingTimer.current = setTimeout(() => {
        fn(args);
        scrollingTimer.current = undefined;
      }, durationMS);
    },
    [scrollingTimer, fn, durationMS]
  );
}

export const useOffsetTop = (ref?: React.RefObject<HTMLElement>) => {
  const [viewportTop, setViewportTop] = useState<number | undefined>(undefined);
  const [pageOffsetTop, setPageOffsetTop] = useState<number | undefined>(undefined);
  const handler = useThrottle(() => {
    if (!ref?.current) return;

    const clientRect = ref.current.getBoundingClientRect();
    setViewportTop(clientRect.top);
    const newPageOffsetTop = clientRect.top + window.scrollY;
    setPageOffsetTop(newPageOffsetTop);
  }, 100);

  useEffect(() => {
    if (!ref?.current) return;

    handler();
    window.addEventListener('scroll', handler, { passive: true });

    return () => window.removeEventListener('scroll', handler);
    // eslint-disable-next-line
  }, [handler]);

  return { viewportTop, pageOffsetTop };
}