import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, List, ListItem, ListItemText, Typography, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';
import { palette, theme } from '../../theme';
import { CompanyContext } from '../../contexts/Company';
import { CompanyContent } from '../atoms/CompanyContent';
import { toDayjs } from '../../firebase';
import { formatDay } from '../../utils/Format';
import { useWindowSize } from 'react-use';
import { TCompanyDetail } from '../../models/TCompany';

const CustomList = styled(List)(({ theme }) => ({
  background: palette.secondary.contrastText,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: 0,
  width: '100%'
}));

const CustomListItem = styled(ListItem)(({ theme }) => ({
  padding: 0,
  [theme.breakpoints.down('md')]: {
    padding: 0
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    padding: 0
  }
}));

const CustomTitle = styled(ListItemText)({
  background: 'rgb(242, 245, 248)',
  borderRight: '1px solid #ccd1db',
  padding: '24px 30px',
  margin: 0,
  width: '20%',
  [theme.breakpoints.down('md')]: {
    padding: '24px 0 24px 20px'
  },
  [theme.breakpoints.down('sm')]: {
    borderRight: 'none',
    borderBottom: '1px solid #ccd1db',
    padding: '24px 0',
    width: '100%'
  }
});

const CustomDetailBox = styled(Box)({
  display: 'flex',
  alignItems: 'flex-start',
  flexWrap: 'wrap',
  padding: '16px 40px',
  width: '80%',
  [theme.breakpoints.down('md')]: {
    padding: 16
  },
  [theme.breakpoints.down('sm')]: {
    borderLeft: 'none',
    padding: '20px 16px',
    width: 'auto'
  }
});

export const CompanyOverview: React.FC = () => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { height, width } = useWindowSize();
  const { company } = useContext(CompanyContext);
  const detailsRef = useRef<HTMLDivElement>(null);
  const [detailsHeight, setDetailsHeight] = useState<number>(0);
  const [addressHeight, setAddressHeight] = useState<number>(0);
  const [details, setDetails] = useState<TCompanyDetail[]>([]);

  useEffect(() => {
    if (company) {
      /// [TODO] 電話番号を新規でしたらコメントアウトを外す
      setDetails([
        {
          title: "社名",
          value: company.name
        }, {
          title: "代表",
          value: company.representative
        }, {
          title: "所在地",
          value: company.address
        }, {
          title: "電話番号",
          value: company.tel
        }, {
          title: "営業時間",
          value: `${toDayjs(company.openingTime).format('HH時mm分')}〜${toDayjs(company.closingTime).format('HH時mm分')}`
        }, {
          title: "定休日",
          value: `${formatDay(company.regularClosingDays)}・祝日`
        }, {
          title: "創業",
          value: "2024年4月1日"
        }, {
          title: "設立",
          value: "2024年4月1日"
        }
      ]);
    }
  },[company]);

  useEffect(() => {
    if (detailsRef) {
      const detailsRect = detailsRef.current?.getBoundingClientRect();
      if (!detailsRect) return;
      setDetailsHeight(detailsRect.height);
    }
  }, [detailsRef, height, width]);

  return (
    <Box display="flex" justifyContent="center">
      <CustomList>
        {details.flatMap((detail, idx) => {
          if (detail.title === '電話番号' && !detail.value) return <Box key={idx} />;
          return (
            <CustomListItem
              key={idx}
              divider
              sx={{
                borderTop: !isSmallScreen && idx === 0 ? '1px solid #ccd1db' : 'none',
                borderBottom: isSmallScreen ? 'none' : '1px solid #ccd1db'
              }}
            >
              <CustomTitle
                primary={detail.title}
                primaryTypographyProps={{
                  color: palette.primary.main,
                  fontSize: isSmallScreen ? 16 : 18,
                  textAlign: isSmallScreen ? 'center' : 'unset',
                }}
                style={{ height: detail.title === '所在地' && !isSmallScreen ? addressHeight - 48 : 'auto' }}
              />
              {isSmallScreen && (<Box mt={1} />)}
              <CompanyContent
                content={detail.value}
                height={height}
                title={detail.title}
                width={width}
                onSetAddressHeight={setAddressHeight}
              />
            </CustomListItem>
          );
        })}
        <CustomListItem>
          <CustomTitle
            primary={'事業内容'}
            primaryTypographyProps={{
              color: palette.primary.main,
              fontSize: isSmallScreen ? 16 : 18,
              textAlign: isSmallScreen ? 'center' : 'unset'
            }}
            sx={{
              alignSelf: isSmallScreen ? 'none' : 'flex-start',
              borderRight: '1px solid #ccd1db',
              height: detailsHeight && !isSmallScreen ? detailsHeight : 'auto',
              width: isSmallScreen ? 1 : '20%'
            }}
          />
          {isSmallScreen && (<Box mt={1} />)}
          <CustomDetailBox ref={detailsRef}>
            {company.businessDetails.flatMap((detail, idx) => {
              return (
                <Box
                  key={idx}
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                  width={isSmallScreen ? 1 : .5}
                  mt={1}
                  mb={1}
                >
                  <Typography
                    variant='h1'
                    fontSize={isSmallScreen ? 16 : 18}
                  >
                    ○
                  </Typography>
                  <Box mr={.5} />
                  <Typography
                    variant='h1'
                    fontSize={isSmallScreen ? 16 : 18}
                  >
                    {detail}
                  </Typography>
                </Box>
              );
            })}
          </CustomDetailBox>
        </CustomListItem>
      </CustomList>
    </Box>
  )
}
