import { PhoneNumberUtil, PhoneNumber, PhoneNumberFormat } from 'google-libphonenumber';

export const svgImage = (svg: string) => `data:image/svg+xml,${encodeURIComponent(svg)}`;

export const formatTelNumber = (tel: string, lang: string) => {
  if (!tel) return tel;
  const region = lang.toUpperCase();

  const util: PhoneNumberUtil = PhoneNumberUtil.getInstance();

  /// 番号と地域を設定
  const number:PhoneNumber = util.parseAndKeepRawInput(tel, region);

  // 電話番号の有効性チェック
  if (!util.isValidNumberForRegion(number, region)) {
    return tel;
  }

  // ハイフン付きの形式で返却
  return util.format(number, PhoneNumberFormat.NATIONAL);
};

export const formatDay = (day: number) => {
  const days = ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'];
  return days[day];
};