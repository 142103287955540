import { Box, Typography, useMediaQuery } from '@mui/material';
import React, { useContext } from 'react';
import { CenterBox, CenterColumnBox, CustomMainText, palette, theme } from '../../theme';
import { ContentTitle } from '../atoms/ContentTitle';
import { CompanyContext } from '../../contexts/Company';
import { TextLinkComponent } from '../atoms/TextLinkComponent';
import { formatDay, formatTelNumber } from '../../utils/Format';
import { toDayjs } from '../../firebase';
import { language } from '../../config';

export const ContactCall: React.FC = () => {
  const lang = language === 'ja' ? 'jp' : language;
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { company } = useContext(CompanyContext);

  return (
    <Box
      display="flex"
      justifyContent="center"
    >
      <Box
        bgcolor={palette.secondary.contrastText}
        borderTop={`2px solid ${palette.primary.main}`}
        maxWidth={1152}
        width={1}
      >
        {isSmallScreen ? (<Box mt={3} />) : (<Box mt={5} />)}
        <ContentTitle title={'お電話によるお問い合わせ'} />
        {isSmallScreen ? (<Box mt={3} />) : (<Box mt={5} />)}
        <CustomMainText>
          お電話によるご来店予約も承っております。<br />
          お気軽にお電話くださいませ。
        </CustomMainText>
        {isSmallScreen ? (<Box mt={3} />) : (<Box mt={5} />)}
        <CenterBox>
          <CenterColumnBox
            pt={3}
            pb={3}
            pr={2}
            pl={2}
            width={.8}
          >
            {isSmallScreen ? (
              <TextLinkComponent
                content={formatTelNumber(company.tel, lang)}
                href={`tel:${formatTelNumber(company.tel, lang)}`}
                sx={{
                  fontSize: isSmallScreen ? 30 : 45,
                  color: '#003A88',
                  letterSpacing: '0.2em',
                }}
                underline={'none'}
              />
            ) : (
              <Typography
                color={palette.primary.contrastText}
                fontSize={isSmallScreen ? 30 : 45}
                letterSpacing={'0.2em'}
              >
                {formatTelNumber(company.tel, lang)}
              </Typography>
            )}
            <Typography
              fontSize={isSmallScreen ? 14 : 18}
              fontWeight={400}
              color={palette.primary.contrastText}
            >
              {toDayjs(company.openingTime).format('HH:mm')}〜{toDayjs(company.closingTime).format('HH:mm')} [定休日 {formatDay(company.regularClosingDays)}・祝日]
            </Typography>
          </CenterColumnBox>
        </CenterBox>
        {isSmallScreen ? (<Box pt={3} />) : (<Box pt={5} />)}
      </Box>
    </Box>
  )
}
