export const HeaderText = (props: any) => {
  const iconStyle = { width: 497, height: 37, ...props };
  return (
    <svg {...iconStyle} viewBox="0 0 497 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.7404 3.06663C8.40704 5.3333 6.40704 8.66663 3.60704 16.1333C-1.05963 28.1333 -1.05963 29.4666 2.80704 33.2C5.47371 35.7333 7.47371 36.1333 17.4737 36.1333C27.207 36.1333 30.2737 35.6 35.607 32.8C41.0737 30.1333 42.407 28.6666 44.1404 23.4666C46.007 18.1333 46.007 17.3333 44.2737 15.4666C41.3404 12.6666 26.9404 12.6666 25.8737 15.6C24.2737 19.7333 25.0737 21.4666 28.407 21.4666C31.4737 21.4666 31.607 21.7333 30.407 24.1333C29.207 26.4 27.607 26.8 20.9404 26.8C11.7404 26.8 11.7404 26.6666 17.4737 14.8L20.407 8.79997L34.5404 8.39997C48.1404 7.99997 48.6737 7.86663 50.2737 4.66663C51.0737 2.79997 51.7404 1.06663 51.7404 0.799966C51.7404 0.399966 43.7404 0.133299 33.8737 0.133299C17.607 0.133299 15.7404 0.399966 11.7404 3.06663Z" fill={props.fill} />
      <path d="M64.9404 2.9333C61.0737 5.19997 58.8071 8.66663 52.6737 20.9333L45.0737 36.1333H51.7404C58.1404 36.1333 58.5404 35.8666 60.6737 31.4666C62.9404 26.6666 64.2737 25.7333 65.7404 28.1333C66.1404 28.8 69.3404 29.4666 72.6737 29.4666C78.4071 29.4666 78.6737 29.6 77.8737 32.8C77.0737 36 77.2071 36.1333 83.4737 36.1333H89.8737L94.9404 22L100.007 7.86663L96.2737 3.99997C92.8071 0.266632 92.0071 0.133299 81.2071 0.133299C72.1404 0.133299 68.8071 0.799966 64.9404 2.9333ZM85.0737 10.6666C85.0737 12 84.4071 14.9333 83.6071 17.3333C82.1404 21.3333 81.8737 21.4666 74.2737 21.4666C70.0071 21.4666 66.4071 21.2 66.4071 20.8C66.4071 19.7333 70.5404 11.7333 72.0071 9.7333C72.8071 8.9333 76.0071 8.1333 79.2071 8.1333C84.0071 8.1333 85.0737 8.5333 85.0737 10.6666Z" fill={props.fill} />
      <path d="M109.874 1.3333C109.474 1.99997 106.807 9.06663 103.874 16.9333C100.94 24.9333 98.2737 32.5333 97.7404 33.7333C96.9404 35.8666 97.607 36.1333 103.474 36.1333H110.007L112.94 28.1333C114.54 23.7333 116.14 20.1333 116.407 20.1333C116.674 20.1333 118.54 23.7333 120.54 28.1333L124.14 36.1333H131.874C136.274 36.1333 139.74 35.7333 139.74 35.3333C139.74 34.9333 138.14 32 136.274 28.6666C133.074 23.0666 132.94 22.8 135.474 22.8C139.207 22.8 145.074 17.7333 147.207 12.6666C151.74 1.7333 149.207 0.133299 127.74 0.133299C118.274 0.133299 110.274 0.666632 109.874 1.3333ZM134.407 9.99997C134.407 15.0666 132.807 16.1333 124.94 16.1333H117.207L118.54 12.1333C119.874 8.39997 120.407 8.1333 127.207 8.1333C132.54 8.1333 134.407 8.66663 134.407 9.99997Z" fill={props.fill} />
      <path d="M163.34 2.93323C159.34 5.06657 157.34 7.73323 152.274 17.5999C142.54 37.0666 142.674 36.1332 150.14 36.1332C156.274 36.1332 156.674 35.8666 158.94 31.3332C160.674 27.5999 161.607 26.7999 162.807 27.9999C163.607 28.7999 167.207 29.4666 170.674 29.4666C176.94 29.4666 177.074 29.5999 176.274 32.9332C175.474 36.2666 175.474 36.2666 181.874 35.8666L188.274 35.4666L192.54 24.1332C198.007 9.86657 198.14 6.53323 193.607 2.93323C188.674 -0.933433 170.807 -0.933433 163.34 2.93323ZM183.74 9.5999C183.74 10.5332 183.074 13.4666 182.14 16.2666L180.674 21.4666H172.807C164.674 21.4666 164.007 20.7999 166.54 15.7332C169.74 9.73323 172.007 8.13323 178.007 8.13323C181.34 8.13323 183.74 8.7999 183.74 9.5999Z" fill={props.fill} />
      <path d="M210.94 2.66663C207.607 4.66663 205.874 7.59997 202.54 16.1333C200.274 22.1333 198.407 27.8666 198.407 28.8C198.407 29.8666 200.007 31.8666 201.874 33.3333C204.94 35.7333 207.34 36.1333 217.474 36.1333C232.807 36.1333 239.607 32.8 243.074 23.4666C246.274 15.2 244.674 13.4666 233.74 13.4666C226.14 13.4666 225.34 13.7333 224.54 16.4C223.34 21.0666 223.474 21.4666 227.207 21.4666C229.874 21.4666 230.54 22 229.874 23.6C229.474 24.8 229.074 26 229.074 26.2666C229.074 26.5333 225.34 26.8 220.94 26.8H212.807L213.607 22.5333C214.007 20.1333 215.474 16.1333 216.807 13.4666L219.074 8.79997L233.474 8.39997C247.474 7.99997 247.874 7.99997 249.074 4.66663C249.874 2.79997 250.407 1.06663 250.407 0.666632C250.407 0.399966 242.407 0.133299 232.807 0.133299C217.74 0.133299 214.407 0.533299 210.94 2.66663Z" fill={props.fill} />
      <path d="M257.74 1.7333C257.34 2.66663 255.074 9.3333 252.407 16.5333L247.74 29.6L251.207 32.8C254.54 35.8666 255.74 36.1333 269.74 36.1333C284.14 36.1333 284.807 36 285.607 33.0666C287.207 26.9333 287.074 26.8 274.274 26.8C263.474 26.8 262.274 26.5333 262.674 24.4C263.074 22.5333 264.94 22.1333 275.34 21.7333C287.474 21.3333 287.474 21.3333 288.94 17.4666L290.274 13.4666H278.274C266.674 13.4666 266.407 13.3333 267.74 10.8C269.074 8.39997 270.54 8.1333 281.874 8.1333C294.14 8.1333 294.54 7.99997 295.74 4.66663C296.54 2.79997 297.074 1.06663 297.074 0.666632C297.074 0.399966 288.407 0.133299 277.74 0.133299C263.474 0.133299 258.14 0.533299 257.74 1.7333Z" fill={props.fill} />
      <path d="M323.34 15.0666C321.34 23.3333 319.74 31.0666 319.74 32.2666C319.74 34.9333 322.407 36.1333 328.274 36.1333C332.274 36.1333 333.474 35.0666 340.54 25.2C348.007 14.8 348.407 14.4 348.807 18C349.074 20.1333 349.074 25.0666 348.807 29.0666L348.407 36.1333H352.807C361.474 36.1333 364.007 33.4666 372.007 16.1333L379.607 -3.44366e-05L372.807 0.399966L365.874 0.799966L361.874 10.6666L357.74 20.5333L357.34 10.4C356.94 0.533299 356.807 0.133299 353.74 0.133299C351.207 0.133299 348.807 2.39997 343.474 9.46663C339.607 14.5333 336.407 18.5333 336.14 18.4C336.007 18.1333 336.94 14.1333 338.407 9.3333C339.874 4.66663 341.074 0.666632 341.074 0.399966C341.074 0.266632 337.874 0.133299 334.007 0.133299H326.94L323.34 15.0666Z" fill={props.fill} />
      <path d="M388.007 2.3999C386.54 3.73324 383.207 11.0666 380.407 18.7999C377.607 26.5332 375.074 33.5999 374.807 34.3999C373.74 37.0666 382.94 36.5332 386.274 33.7332C388.407 31.8666 399.607 4.7999 399.74 1.06657C399.74 -0.8001 390.674 0.133236 388.007 2.3999Z" fill={props.fill} />
      <path d="M408.54 1.3333C408.14 1.99997 405.474 9.06663 402.54 16.9333C399.607 24.9333 396.94 32.5333 396.407 33.7333C395.474 36 396.807 36.1333 411.607 36.1333C425.074 36.1333 428.674 35.7333 432.94 33.6C439.474 30.2666 440.14 29.2 444.274 18.2666C448.407 7.3333 448.674 3.3333 445.207 1.46663C442.007 -0.133368 409.474 -0.400034 408.54 1.3333ZM433.474 9.06663C433.874 9.7333 432.807 13.7333 431.207 18.1333L428.14 26.1333L420.14 26.5333L412.007 26.9333L415.474 17.4666L418.807 8.1333H425.874C429.74 8.1333 433.207 8.5333 433.474 9.06663Z" fill={props.fill} />
      <path d="M452.674 13.7333C450.007 21.3333 447.74 28.1333 447.74 29.0666C447.74 29.8666 449.34 31.8666 451.207 33.3333C454.274 35.7333 456.674 36.1333 469.34 36.1333C483.607 36.1333 483.874 36.1333 485.074 32.6666C485.874 30.8 486.407 28.6666 486.407 28C486.407 27.3333 480.94 26.8 474.274 26.8C463.474 26.8 462.274 26.5333 462.674 24.4C463.074 22.5333 464.94 22.1333 474.807 21.7333C485.874 21.3333 486.54 21.2 487.74 18C488.54 16.1333 489.074 14.4 489.074 14C489.074 13.7333 484.007 13.4666 477.74 13.4666C471.474 13.4666 466.407 13.2 466.407 12.9333C466.407 8.79997 468.407 8.1333 481.074 8.1333H494.14L495.607 4.1333L496.94 0.133299H477.34H457.607L452.674 13.7333Z" fill={props.fill} />
    </svg>

  );
}