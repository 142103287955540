import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import React, { useContext, useEffect, useState } from 'react';
import { palette, theme } from '../../theme';
import { NewsInfoContent } from '../organisms/NewsInfoContent';
import { NewsContext } from '../../contexts/News';
import { TStorage } from '../../models/TStorage';
import { TNews } from '../../models/TNews';
import { useWindowSize } from 'react-use';
import { StateContext } from '../../contexts/State';
import { useNavigate } from 'react-router-dom';
import { IcoArrowRight } from '../../images/IcoArrowRight';
import { NewsContentComponent } from './NewsContentComponent';
import { getBytes } from 'firebase/storage';
import { newsPathReference } from '../../firebase';

const CustomTitle = styled(Typography)({
  color: palette.secondary.contrastText,
  fontSize: 30,
  fontWeight: 'bold',
});

const CustomSubTitle = styled(Typography)({
  color: palette.secondary.contrastText,
  fontSize: 25,
  fontWeight: 'bold',
});

const CustomMainImageBox = styled(Box)({
  aspectRatio: '1/1',
  overflow: 'hidden',
  position: 'absolute',
});

const CustomMainImage = styled(Box)({
  backgroundImage: 'url("/images/news-view.jpg")',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '0 0',
  backgroundSize: 'cover',
  transition: '.5s transform ease-in',
  width: '100%',
  position: 'absolute',
  top: '50%',
  left: '50%',
  display: 'block',
});

export const NewsView: React.FC = () => {
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const { news } = useContext(NewsContext);
  const { setLoading } = useContext(StateContext);
  const [images, setImages] = useState<TStorage[]>([]);
  const [isHover, setIsHover] = useState<number | null>(null);
  const [isButtonHover, setIsButtonHover] = useState<boolean>(false);
  const [currentNews, setCurrentNews] = useState<TNews[]>([]);
  const [isChange, setIsChange] = useState<boolean>(false);

  useEffect(() => {
    if (width < 730) {
      setIsChange(true);
    } else {
      setIsChange(false);
    }
  }, [width]);

  useEffect(() => {
    if (news.length === 0) return;
    setCurrentNews(news.slice(0, 6));
    const getImageName = news.flatMap((v) => v.imageName);
    if (getImageName.length === 0) return;
    (async () => {
      for (let i = 0; i < news.length; i++) {
        if (news[i].imageName) {
          const getImage = await getBytes(newsPathReference(news[i].imageName));
          const binaryData = btoa(Array.from(new Uint8Array(getImage), e => String.fromCharCode(e)).join(""));
          setImages((previous) => {
            return [
              ...previous.filter((v) => v.id !== news[i].id),
              {
                id: news[i].id,
                image: binaryData
              }
            ];
          });
        }
      }
    })()
  }, [news]);

  return (
    <>
      {news.length === 0 ? (
        <>
          {isSmallScreen ? (<Box pt={5} />) : (<Box pt={6} />)}
        </>
      ) : (
        <Box>
          {isSmallScreen ? (<Box mt={5} />) : (<Box mt={10} />)}
          {isChange && (
            <Box position="relative">
              <Box height={300}>
                <CustomMainImageBox
                  className="news-image-box"
                  top={30}
                  height={1}
                  width={1}
                  zIndex={0}
                >
                  <CustomMainImage
                    className="news-image"
                    height={1}
                  />
                  <Box
                    position="relative"
                    width={1}
                  >
                    <Box
                      position="absolute"
                      top={30}
                      left={30}
                      zIndex={2}
                      display="flex"
                      alignItems="flex-start"
                      flexDirection="column"
                      width={1}
                    >
                      <CustomTitle>お知らせ</CustomTitle>
                      <CustomSubTitle>{t('お知らせ')}</CustomSubTitle>
                      <Box mt={2} />
                      <Button
                        disableRipple
                        variant="contained"
                        sx={{
                          background: `${palette.secondary.contrastText}!important`,
                          border: `1px solid ${palette.primary.contrastText}`,
                          borderRadius: 30,
                          padding: '15px 30px',
                          transition: '.3s background ease-out',
                          boxShadow: 'none!important',
                          [theme.breakpoints.down('md')]: {
                            cursor: 'default',
                            padding: 2,
                            "&:hover": {
                              boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)!important'
                            }
                          }
                        }}
                        onClick={() => {
                          setLoading(true);
                          setTimeout(() => {
                            navigate('/news');
                          }, 1000);
                          setTimeout(() => {
                            window.scrollTo({
                              top: 0,
                              behavior: "smooth",
                            });
                          }, 2000);
                          setTimeout(() => {
                            setLoading(false);
                          }, 3500);
                        }}
                        onMouseEnter={() => setIsButtonHover(true)}
                        onMouseLeave={() => setIsButtonHover(false)}
                      >
                        <Typography
                          fontSize={14}
                          fontWeight="bold"
                          color={!isMediumScreen && isButtonHover ? palette.primary.main : palette.primary.contrastText}
                        >
                          お知らせ一覧へ
                        </Typography>
                        <Box mr={.5} />
                        <IcoArrowRight
                          height={'100%'}
                          width={20}
                          color={!isMediumScreen && isButtonHover ? palette.primary.main : palette.primary.contrastText}
                        />
                      </Button>
                    </Box>
                  </Box>
                </CustomMainImageBox>
              </Box>
            </Box>
          )}
          <Box
            display="flex"
            justifyContent="center"
            width={1}
          >
            <Box
              bgcolor={palette.secondary.contrastText}
              display="flex"
              alignItems="flex-start"
              maxWidth={1152}
              position="relative"
              width={1}
            >
              {!isChange && (
                <>
                  <CustomMainImageBox
                    className="news-image-box"
                    height={1}
                    width={1}
                    zIndex={0}
                  >
                    <CustomMainImage
                      className="news-image"
                      height={1}
                    />
                  </CustomMainImageBox>
                  <Box
                    position="relative"
                    width={.25}
                  >
                    <Box
                      position="absolute"
                      top={30}
                      left={isMediumScreen ? 10 : 20}
                      zIndex={2}
                      display="flex"
                      alignItems="flex-start"
                      flexDirection="column"
                      width={1}
                    >
                      <CustomTitle>お知らせ</CustomTitle>
                      <CustomSubTitle>{t('お知らせ')}</CustomSubTitle>
                      <Box mt={2} />
                      <Button
                        disableRipple
                        variant="contained"
                        sx={{
                          background: `${palette.secondary.contrastText}!important`,
                          border: `1px solid ${palette.primary.contrastText}`,
                          borderRadius: 30,
                          padding: '15px 30px',
                          transition: '.3s background ease-out',
                          boxShadow: 'none!important',
                          [theme.breakpoints.down('md')]: {
                            cursor: 'default',
                            padding: 2,
                            "&:hover": {
                              boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)!important'
                            }
                          }
                        }}
                        onClick={() => {
                          setLoading(true);
                          setTimeout(() => {
                            navigate('/news');
                          }, 1000);
                          setTimeout(() => {
                            window.scrollTo({
                              top: 0,
                              behavior: "smooth",
                            });
                          }, 2000);
                          setTimeout(() => {
                            setLoading(false);
                          }, 3500);
                        }}
                        onMouseEnter={() => setIsButtonHover(true)}
                        onMouseLeave={() => setIsButtonHover(false)}
                      >
                        <Typography
                          fontSize={14}
                          fontWeight="bold"
                          color={!isMediumScreen && isButtonHover ? palette.primary.main : palette.primary.contrastText}
                        >
                          お知らせ一覧へ
                        </Typography>
                        <Box mr={.5} />
                        <IcoArrowRight
                          height={'100%'}
                          width={20}
                          color={!isMediumScreen && isButtonHover ? palette.primary.main : palette.primary.contrastText}
                        />
                      </Button>
                    </Box>
                  </Box>
                </>
              )}
              <Box
                bgcolor={palette.secondary.contrastText}
                zIndex={1}
                width={isChange ? 1 : .75}
              >
                {isChange ? (
                  <NewsContentComponent
                    backgroundColor={palette.secondary.contrastText}
                    isChange={isChange}
                    images={images}
                    isHover={isHover}
                    news={currentNews}
                    onSetIsHover={setIsHover}
                    onSetDetail={null}
                  />
                ) : (
                  <>
                    {news.length < 4 ? (
                      <NewsContentComponent
                        backgroundColor={palette.secondary.contrastText}
                        images={images}
                        isChange={null}
                        isHover={isHover}
                        news={currentNews}
                        onSetIsHover={setIsHover}
                        onSetDetail={null}
                      />
                    ) : (
                      <NewsInfoContent
                        backgroundColor={palette.secondary.contrastText}
                        isDivider={isMediumScreen ? false : true}
                        images={images}
                        isHover={isHover}
                        news={currentNews}
                        pb={0}
                        onSetIsHover={setIsHover}
                        onSetDetail={null}
                      />
                    )}
                  </>
                )}
              </Box>
            </Box>
          </Box>
          {isSmallScreen ? (<Box mt={5} />) : (<Box mt={10} />)}
        </Box >
      )}
    </>
  )
}
