import { Box, SelectChangeEvent, useMediaQuery } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { TopView } from '../atoms/TopView';
import { theme } from '../../theme';
import { NewsContext } from '../../contexts/News';
import { NewsContent } from '../organisms/NewsContent';
import { SelectBox } from '../atoms/SelectBox';
import { TNews } from '../../models/TNews';
import { PaginationComponent } from '../atoms/PaginationComponent';
import { StateContext } from '../../contexts/State';
import { useWindowSize } from 'react-use';

export const News: React.FC = () => {
  const { headerRef } = useContext(StateContext);
  const { width } = useWindowSize();
  const { categories, news } = useContext(NewsContext);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [selectedCategory, setSelectedCategory] = useState<string>('すべて');
  const [contents, setContents] = useState<TNews[]>(news);
  const [currentContents, setCurrentContents] = useState<TNews[]>(news);
  const [count, setCount] = useState<number>(1);
  const [selectedPageNum, setSelectedPageNum] = useState<number>(1);
  const [headerHeight, setHeaderHeight] = useState<number>(0);

  const handleChange = (e: SelectChangeEvent) => {
    const value = e.target.value;
    setSelectedCategory(value);
  }

  useEffect(() => {
    window.document.title = "GARAGE WIDE | お知らせ";
  },[]);

  useEffect(() => {
    const el = document.querySelector('[aria-label="Go to first page"]');
    const handleSetCount = (value: TNews[]) => {
      const ceil = Math.ceil(value.length / 6);
      setCount(ceil);
    };
    if (news.length > 0 && selectedCategory) {
      if (selectedCategory === 'すべて') {
        const filter = news.filter((v, idx) => idx >= 0 && idx <= 6);
        setContents(filter);
        setCurrentContents(news);
        handleSetCount(news);
        if (el) {
          const isContain = el.classList.contains('Mui-disabled');
          if (!isContain) {
            const element = (el as HTMLElement);
            element.click();
          }
        }
      } else {
        const filter = news.filter((v) => v.category === selectedCategory);
        const firstViewContents = filter.filter((v, idx) => idx >= 0 && idx <= 6);
        setContents(firstViewContents);
        setCurrentContents(filter);
        handleSetCount(filter);
        if (el) {
          const isContain = el.classList.contains('Mui-disabled');
          if (!isContain) {
            const element = (el as HTMLElement);
            element.click();
          }
        }
      }
    }
    return;
  }, [news, selectedCategory]);

  useEffect(() => {
    const prev = (selectedPageNum - 1) * 6;
    const next = selectedPageNum * 6;
    const filter = currentContents.filter((v, idx) => idx > prev - 1 && idx <= next - 1);
    setContents(filter);
  }, [currentContents, selectedPageNum]);

  useEffect(() => {
    if (headerRef) {
      const current = headerRef.current;
      if (current) {
        const rect = current.getBoundingClientRect();
        const height = rect.height;
        setHeaderHeight(height);
      }
    }
  }, [headerRef, width]);

  return (
    <Box style={{ marginTop: headerHeight }}>
      <TopView title={'お知らせ'} imageName={'sale.jpg'} />
      <Box mt={15} />
      <Box
        display="flex"
        justifyContent="center"
        width={1}
      >
        <Box
          display="flex"
          justifyContent="flex-end"
          maxWidth={1152}
          width={1}
        >
          <SelectBox
            selectedItem={selectedCategory}
            items={categories}
            sx={{
              fontSize: 16,
              fontWeight: 'bold'
            }}
            width={200}
            onHandleChange={handleChange}
          />
        </Box>
      </Box>
      <Box
        id="news-content"
        display="flex"
        justifyContent="center"
        width={1}
      >
        <Box
          display="flex"
          alignItems={isMediumScreen ? 'center' : 'flex-start'}
          flexDirection={isMediumScreen ? 'column' : 'row'}
          width={1}
        >
          <NewsContent news={contents} />
          {isMediumScreen && !isSmallScreen && (<Box mt={8} />)}
        </Box>
      </Box>
      {isSmallScreen ? (<Box mt={3} />) : (<Box mt={5} />)}
      <PaginationComponent
        count={count}
        selectedPageNum={selectedPageNum}
        onSetSelectedPageNum={setSelectedPageNum}
      />
      <Box mt={8} />
    </Box>
  )
}