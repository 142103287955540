import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import React, { useContext, useState } from 'react';
import { styled } from '@mui/system';
import { palette, theme } from '../../theme';
import { TNews } from '../../models/TNews';
import { TStorage } from '../../models/TStorage';
import { useNavigate } from 'react-router-dom';
import { StateContext } from '../../contexts/State';
import { NewsInfoContent } from './NewsInfoContent';
import { IcoArrowRight } from '../../images/IcoArrowRight';

interface OwnProps {
  images: TStorage[];
  isHover: number | null;
  news: TNews[];
  onSetIsHover: React.Dispatch<React.SetStateAction<number | null>>;
  onSetDetail: React.Dispatch<React.SetStateAction<TNews>> | null;
}

const CustomContentTitle = styled(Typography)({
  fontSize: 25,
  [theme.breakpoints.down('md')]: {
    fontSize: 20,
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 18,
  }
});

export const NewsInfoComponent: React.FC<OwnProps> = (props) => {
  const { images, isHover, news, onSetIsHover, onSetDetail } = props;
  const navigate = useNavigate();
  const { setLoading } = useContext(StateContext);
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [isButtonHover, setIsButtonHover] = useState<boolean>(false);

  return (
    <Box
      p={2}
      display="flex"
      justifyContent="center"
    >
      <Box maxWidth={1152} width={1}>
        <Box
          bgcolor={palette.background.paper}
          borderTop={`2px solid ${palette.primary.main}`}
        >
          <Box mt={5} />
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CustomContentTitle
              color={palette.primary.contrastText}
              fontWeight="bold"
            >
              最新のお知らせ
            </CustomContentTitle>
          </Box>
          <Box mt={4} />
          <NewsInfoContent
            backgroundColor={palette.background.paper}
            isDivider={false}
            images={images}
            isHover={isHover}
            news={news}
            pb={2}
            onSetIsHover={onSetIsHover}
            onSetDetail={onSetDetail}
          />
          <Box
            display="flex"
            justifyContent="center"
          >
            <Button
              disableRipple
              variant="contained"
              sx={{
                background: palette.primary.dark,
                border: `1px solid ${palette.primary.dark}`,
                padding: '15px 30px',
                transition: '.3s background ease-out',
                [theme.breakpoints.up('md')]: {
                  "&:hover": {
                    background: palette.secondary.contrastText,
                    border: `1px solid ${palette.primary.dark}`,
                  }
                },
                [theme.breakpoints.down('md')]: {
                  cursor: 'default',
                  "&:hover": {
                    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)!important'
                  }
                }
              }}
              onClick={() => {
                setLoading(true);
                setTimeout(() => {
                  navigate('/news');
                }, 1000);
                setTimeout(() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }, 2000);
                setTimeout(() => {
                  setLoading(false);
                }, 3500);
              }}
              onMouseEnter={() => setIsButtonHover(true)}
              onMouseLeave={() => setIsButtonHover(false)}
            >
              <Typography
                fontSize={14}
                fontWeight="bold"
                color={!isMediumScreen && isButtonHover ? palette.primary.contrastText : palette.secondary.contrastText}
              >
                一覧へ戻る
              </Typography>
              <Box mr={.5} />
              <IcoArrowRight
                height={'100%'}
                width={20}
                color={!isMediumScreen && isButtonHover ? palette.primary.contrastText : palette.secondary.contrastText}
              />
            </Button>
          </Box>
          <Box pt={4} />
        </Box>
      </Box>
    </Box >
  )
}