import { createContext, useState } from 'react';

interface IStateContext {
  handleHeaderRef: any;
  bodyRef: HTMLElement | null;
  headerRef: React.RefObject<HTMLElement> | undefined;
  loading: boolean;
  setLoading: any;
}

const StateContext = createContext<IStateContext>({
  handleHeaderRef: undefined,
  bodyRef: null,
  headerRef: undefined,
  loading: true,
  setLoading: undefined,
});

const StateProvider = (props: any) => {
  const [bodyRef] = useState<HTMLElement | null>(document.getElementById('body'));
  const [headerRef, setHeaderRef] = useState<React.RefObject<HTMLElement>>();
  const [loading, setLoading] = useState<boolean>(true);

  const handleHeaderRef = (v: React.RefObject<HTMLElement>) => { return setHeaderRef(v) };

  return (
    <StateContext.Provider
      value={{
        bodyRef,
        handleHeaderRef,
        headerRef,
        loading,
        setLoading,
      }}
    >
      {props.children}
    </StateContext.Provider>
  );
};

export { StateContext, StateProvider };