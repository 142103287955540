import React, { useContext, useState } from 'react';
import dayjs from 'dayjs';
import { Box, Button, FormHelperText, OutlinedInput, Typography, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';
import { CenterBox, CenterColumnBox, CustomMainText, palette, theme } from '../../theme';
import { PhoneNumberUtil, PhoneNumber } from 'google-libphonenumber';
import { ContentTitle } from '../atoms/ContentTitle';
import { IcoArrowRight } from '../../images/IcoArrowRight';
import { func } from '../../firebase';
import { httpsCallable } from 'firebase/functions';
import { TSendEmail } from '../../models/TSendEmail';
import { ConfirmDialog } from '../dialogs/ConfirmDialog';
import { ConfirmContactForm } from './ConfirmContactForm';
import { StateContext } from '../../contexts/State';

const CustomLabelRequired = styled(Box)({
  background: palette.primary.main,
  borderRadius: 5,
  paddingTop: 2,
  paddingBottom: 2,
  paddingRight: 10,
  paddingLeft: 10
});

const CustomRequiredLabel = styled(Typography)({
  fontSize: 18,
  fontWeight: 400,
  color: palette.secondary.contrastText,
  [theme.breakpoints.down('sm')]: {
    fontSize: 16
  }
});

const CustomLabelBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

const CustomLabel = styled(Typography)({
  fontSize: 18,
  fontWeight: 'bold',
  color: palette.primary.contrastText,
  [theme.breakpoints.down('sm')]: {
    fontSize: 16
  }
});

const CustomOutlinedInput = styled(OutlinedInput)({
  fontSize: 18,
  padding: 8,
  width: '-webkit-fill-available',
  [theme.breakpoints.down('sm')]: {
    fontSize: 16
  }
});

const CustomFormHelperText = styled(FormHelperText)({
  fontSize: 16,
  marginTop: 4,
  [theme.breakpoints.down('sm')]: {
    fontSize: 14
  }
});

export const ContactForm: React.FC = () => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { setLoading } = useContext(StateContext);
  const [isButtonHover, setIsButtonHover] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [tel, setTel] = useState<string>('');
  const [content, setContent] = useState<string>('');
  const [textCount, setTextCount] = useState<number>(0);
  const [nameError, setNameError] = useState<String>('');
  const [emailError, setEmailError] = useState<String>('');
  const [telError, setTelError] = useState<String>('');
  const [contentError, setContentError] = useState<String>('');
  const [confirmStatus, setConfirmStatus] = useState(false);
  const [dialogStatus, setDialogStatus] = useState(false);

  const handleClose = () => {
    setDialogStatus(false);
  }

  const onHandleSubmit = () => {
    setName('');
    setEmail('');
    setTel('');
    setContent('');
    setTextCount(0);
    setConfirmStatus(false);
    setDialogStatus(false);
  };

  const handleBlur = (
    type: string,
    field: string,
    setError: React.Dispatch<React.SetStateAction<String>>,
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => {
    const value = event.target.value;
    if (type === 'blank') {
      if (!value) {
        return setError(`${field}を入力してください`);
      }
    }

    if (type === 'regexp') {
      if (field === '電話番号') {
        if (!value) {
          return setError('');
        }
        try {
          const region = 'JP';
          const util: PhoneNumberUtil = PhoneNumberUtil.getInstance();
          const number: PhoneNumber = util.parseAndKeepRawInput(value, region);
          if (!util.isValidNumberForRegion(number, region)) {
            return setError(`正しい${field}を入力してください`);
          }
        } catch {
          return setError(`正しい${field}を入力してください`);
        }
      } else if (field === 'メールアドレス') {
        const mailCheck = (regexp: RegExp) => {
          return regexp.test(value);
        }
        if (
          !mailCheck(/^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/i)
        ) {
          return setError(`正しい${field}を入力してください`);
        }
      }
    }
    return setError("");
  };

  const handleClick = () => {
    if (nameError || emailError || telError || contentError) {
      return;
    }
    if (!name || !email || !content) {
      return;
    }
    setIsButtonHover(false);
    setConfirmStatus(true);
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (nameError || emailError || telError || contentError) {
      setLoading(false);
      return;
    }
    if (!name || !email || !content) {
      setLoading(false);
      return;
    }
    const data = {
      name: name,
      email: email,
      tel: tel,
      content: content,
      dateTime: dayjs().format('YYYY/MM/DD HH:mm:ss')
    };
    const sendMail = httpsCallable(func, 'sendEmail');
    await sendMail(data).then((val) => {
      const data = val.data as TSendEmail;
      if (data.status === 'error') {
        if (data.field === 'email') {
          setEmailError(data.message);
        } else if (data.field === 'tel') {
          setTelError(data.message);
        } else if (data.field === 'content') {
          setTelError(data.message);
        } else if (data.field === 'all field' || data.field === 'send email') {
          alert(data.message);
        }
      } else {
        setDialogStatus(true);
      }
    });
    setLoading(false);
    return;
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
    >
      {!confirmStatus && (
        <Box
          bgcolor={palette.secondary.contrastText}
          borderTop={`2px solid ${palette.primary.main}`}
          maxWidth={1152}
          width={1}
        >
          {isSmallScreen ? (<Box mt={3} />) : (<Box mt={5} />)}
          <CenterColumnBox>
            <Box p={'8px 16px'}>
              <ContentTitle title={'お問い合わせフォーム'} />
            </Box>
          </CenterColumnBox>
          {isSmallScreen ? (<Box mt={3} />) : (<Box mt={5} />)}
          <Box
            display="flex"
            justifyContent="center"
          >
            <Box
              width={.9}
              p={'8px 16px'}
            >
              <CustomMainText textAlign="center">
                ご返信に3営業日ほどお時間をいただいております。<br />
                3営業日を過ぎても返信がない場合は、お手数ですが再度お問い合わせをお願いいたします。
              </CustomMainText>
            </Box>
          </Box>
          {isSmallScreen ? (<Box mt={3} />) : (<Box mt={5} />)}
          <CenterBox>
            <CenterColumnBox
              pt={3}
              pb={3}
              pr={2}
              pl={2}
              width={.9}
            >
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: isSmallScreen ? '100%' : '70%'
                }}
              >
                <Box width={1}>
                  <CustomLabelBox>
                    <CustomLabel>お名前</CustomLabel>
                    <Box mr={4} />
                    <CustomLabelRequired>
                      <CustomRequiredLabel>
                        必須
                      </CustomRequiredLabel>
                    </CustomLabelRequired>
                  </CustomLabelBox>
                  <Box mt={.5} />
                  <CustomOutlinedInput
                    value={name}
                    error={!!nameError}
                    placeholder="比嘉 ガレージ"
                    onChange={(event) => {
                      const value = event.target.value;
                      setName(value);
                    }}
                    onBlur={(e) => handleBlur('blank', 'お名前', setNameError, e)}
                  />
                  <CustomFormHelperText error>{nameError}</CustomFormHelperText>
                </Box>
                <Box mt={2} />
                <Box width={1}>
                  <CustomLabelBox>
                    <CustomLabel>メールアドレス</CustomLabel>
                    <Box mr={4} />
                    <CustomLabelRequired>
                      <CustomRequiredLabel>
                        必須
                      </CustomRequiredLabel>
                    </CustomLabelRequired>
                  </CustomLabelBox>
                  <Box mt={.5} />
                  <CustomOutlinedInput
                    value={email}
                    error={!!emailError}
                    placeholder="higa@gmail.com"
                    onChange={(event) => {
                      const value = event.target.value;
                      setEmail(value);
                    }}
                    onBlur={(e) => handleBlur('regexp', 'メールアドレス', setEmailError, e)}
                  />
                  <CustomFormHelperText error>{emailError}</CustomFormHelperText>
                </Box>
                <Box mt={2} />
                <Box width={1}>
                  <CustomLabel>電話番号</CustomLabel>
                  <Box mt={.5} />
                  <CustomOutlinedInput
                    value={tel}
                    error={!!telError}
                    placeholder="09012342345 (ハイフンなし)"
                    onChange={(event) => {
                      const value = event.target.value;
                      if (!value) {
                        setTel('');
                      }
                      const NUMERIC_DASH_REGEX = /^[0-9]+$/;
                      if (NUMERIC_DASH_REGEX.test(value)) {
                        setTel(value);
                      }
                    }}
                    onBlur={(e) => handleBlur('regexp', '電話番号', setTelError, e)}
                  />
                  <CustomFormHelperText error>{telError}</CustomFormHelperText>
                </Box>
                <Box mt={2} />
                <Box width={1}>
                  <CustomLabelBox>
                    <CustomLabel>お問い合わせ内容</CustomLabel>
                    <Box mr={4} />
                    <CustomLabelRequired>
                      <CustomRequiredLabel>
                        必須
                      </CustomRequiredLabel>
                    </CustomLabelRequired>
                  </CustomLabelBox>
                  <Box mt={.5} />
                  <Box>
                    <textarea
                      maxLength={500}
                      value={content}
                      style={{
                        border: !!contentError ? '1px solid #B00020' : '1px solid rgba(0, 0, 0, 0.23)',
                        borderRadius: 4,
                        fontSize: isSmallScreen ? 16 : 18,
                        height: 300,
                        padding: 8,
                        width: '-webkit-fill-available'
                      }}
                      onChange={(e) => {
                        const value = e.currentTarget.value;
                        const segmenterJa = new Intl.Segmenter('ja-JP', { granularity: 'grapheme' });
                        const segments = segmenterJa.segment(value);
                        setTextCount(Array.from(segments).length);
                        setContent(value);
                      }}
                      onBlur={(e) => handleBlur('blank', 'お問い合わせ内容', setContentError, e)}
                    />
                    <Box
                      display="flex"
                      justifyContent="space-between"
                    >
                      <CustomFormHelperText error>{contentError}</CustomFormHelperText>
                      <Typography
                        color={palette.primary.contrastText}
                        fontSize={16}
                      >
                        {textCount}/500
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box mt={5} />
                <Button
                  disableRipple
                  variant="contained"
                  sx={{
                    background: palette.primary.dark,
                    border: `1px solid ${palette.primary.dark}`,
                    color: palette.secondary.contrastText,
                    fontWeight: 'bold',
                    padding: '15px 30px',
                    transition: '.3s background-color ease-out',
                    width: '50%',
                    [theme.breakpoints.up('md')]: {
                      "&:hover": {
                        background: palette.secondary.contrastText,
                        border: `1px solid ${palette.primary.dark}`,
                        color: palette.primary.contrastText,
                      }
                    },
                    [theme.breakpoints.down('md')]: {
                      cursor: 'default',
                      "&:hover": {
                        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)!important'
                      }
                    }
                  }}
                  onClick={handleClick}
                  onMouseEnter={() => setIsButtonHover(true)}
                  onMouseLeave={() => setIsButtonHover(false)}
                >
                  <Typography
                    fontSize={14}
                    fontWeight="bold"
                    color={!isMediumScreen && isButtonHover ? palette.primary.contrastText : palette.secondary.contrastText}
                  >
                    確認
                  </Typography>
                  <Box mr={.5} />
                  <IcoArrowRight
                    height={'100%'}
                    width={20}
                    animation={'floating-x 1.8s ease-in-out infinite alternate-reverse'}
                    color={!isMediumScreen && isButtonHover ? palette.primary.contrastText : palette.secondary.contrastText}
                  />
                </Button>
              </Box>
            </CenterColumnBox>
          </CenterBox>
          {isSmallScreen ? (<Box mt={5} />) : (<Box mt={10} />)}
        </Box>
      )}
      {confirmStatus && (
        <ConfirmContactForm
          name={name}
          email={email}
          tel={tel}
          content={content}
          textCount={textCount}
          onSetConfirmStatus={setConfirmStatus}
          handleSubmit={handleSubmit}
        />
      )}
      <ConfirmDialog
        open={dialogStatus}
        title={'送信完了'}
        message={'正常に送信が完了しました'}
        submitLabel={'閉じる'}
        onSubmit={onHandleSubmit}
        onClose={handleClose}
      />
    </Box>
  )
}
