import React from 'react';
import { AuthProvider } from '../../contexts/Auth';
import { CompanyProvider } from '../../contexts/Company';
import { StateProvider } from '../../contexts/State';
import { CookiesProvider } from 'react-cookie';
import { NewsProvider } from '../../contexts/News';
import { InstagramProvider } from '../../contexts/Instagram';

interface OwnProps {
  children: any;
}

export const Provider: React.FC<OwnProps> = (props) => {
  const { children } = props;
  return (
    <>
      <CookiesProvider>
        <AuthProvider>
          <CompanyProvider>
            <NewsProvider>
              <InstagramProvider>
                <StateProvider>
                  {children}
                </StateProvider>
              </InstagramProvider>
            </NewsProvider>
          </CompanyProvider>
        </AuthProvider>
      </CookiesProvider>
    </>
  )
}
