import { collection, documentId, onSnapshot, orderBy, query } from 'firebase/firestore';
import { createContext, useMemo, useState } from 'react';
import { db, toDayjs } from '../firebase';
import { TNews } from '../models/TNews';

interface INewsContext {
  categories: string[];
  news: TNews[];
  titles: string[];
}

const NewsContext = createContext<INewsContext>({
  categories: [],
  news: [],
  titles: []
});

const NewsProvider = (props: any) => {
  const [news, setNews] = useState<TNews[]>([]);
  const categories = useMemo(() => {
    if (news.length > 0) {
      const categories = news.flatMap((v) => v.category);
      categories.unshift('すべて');
      return categories.filter((element, index) => {
        return categories.indexOf(element) === index;
      });
    }
    return [];
  }, [news]);

  const titles = useMemo(() => {
    if (news.length > 0) {
      const titles = news.flatMap((v) => v.title);
      return titles.filter((element, index) => {
        return titles.indexOf(element) === index;
      });
    }
    return [];
  }, [news]);

  // eslint-disable-next-line
  const col = useMemo(async () => {
    const col = collection(db, 'news');
    const q = query(col, orderBy(documentId()));
    onSnapshot(q, (snapshots) => {
      const docs = snapshots.docs.map((doc) => {
        return {
          ...doc.data(),
          id: doc.id
        } as TNews
      });
      setNews(docs.sort((a, b) => toDayjs(b.updatedAt).valueOf() - toDayjs(a.updatedAt).valueOf()));
    });
    return col;
  }, []);

  return (
    <NewsContext.Provider value={{ categories, news, titles }}>
      {props.children}
    </NewsContext.Provider>
  );
};

export { NewsContext, NewsProvider };