import React, { useContext, useState } from 'react';
import { styled } from '@mui/system';
import Hamburger from 'hamburger-react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Sidebar } from 'react-pro-sidebar';
import { CenterColumnBox, palette, theme } from '../../theme';
import { SideMenuTop } from '../molecules/SideMenuTop';
import { SideMenuBottom } from '../molecules/SideMenuBottom';
import { Box, IconButton, Typography, useMediaQuery } from '@mui/material';
import { AuthContext } from '../../contexts/Auth';
import { PopoverMenu } from '../molecules/PopoverMenu';
import { onClickAction } from '../../utils/Action';
import { useWindowSize } from 'react-use';

interface OwnProps {
  isOpen: boolean;
  isToggled: boolean;
  onHandleHamburgerMenu: () => void;
  onSetIsToggled: React.Dispatch<React.SetStateAction<boolean>>;
  onSetIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CustomSidebar = styled(Sidebar)({
  "&&&.closed-sidebar .ps-sidebar-container": {
    left: 100
  }
});

export const SideMenu: React.FC<OwnProps> = (props) => {
  const {
    isOpen,
    isToggled,
    onHandleHamburgerMenu,
    onSetIsToggled,
    onSetIsOpen
  } = props;

  const { user } = useContext(AuthContext);
  const { height } = useWindowSize();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'));
  const paddingTop = 100;
  const padding = isSmallScreen ? `${paddingTop}px 30px 0px 30px` : `${paddingTop}px 50px 0px 50px`;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  }

  const handlePopoverClose = () => {
    setOpen(false);
    setAnchorEl(null);
  }

  return (
    <CustomSidebar
      className={!isOpen ? 'closed-sidebar' : 'opened-sidebar'}
      rtl
      backgroundColor={palette.primary.main}
      breakPoint="all"
      collapsed={!isOpen}
      collapsedWidth="0px"
      toggled={isToggled}
      rootStyles={{
        position: 'fixed',
        borderColor: 'transparent',
        color: palette.secondary.contrastText,
        height: `${height}px!important`,
        width: isSmallScreen ? '100%' : 435,
        top: 0,
        right: 0,
        overflow: 'auto',
        '&&&.opened-sidebar .ps-sidebar-container': {
          height: `${height - paddingTop}px!important`,
          padding: padding,
        },
        "&&&.closed-sidebar .ps-sidebar-container": {
          height: `${height - paddingTop}px!important`,
          padding: padding,
        }
      }}
      onBackdropClick={() => {
        onSetIsOpen(false);
        onSetIsToggled(false);
        const el = document.getElementById('submenu');
        if (el) {
          const classList = el.classList;
          if (classList.contains('ps-open')) {
            onClickAction("submenu");
          }
        }
      }}
    >
      <Box
        position="absolute"
        top={28}
        left={24}
        width={48}
        height={48}
        display="flex"
        align-items="center"
        justify-content="center"
      >
        {user && (
          <IconButton
            color="primary"
            onClick={handlePopoverOpen}
          >
            <AccountCircleIcon
              style={{
                fontSize: '1.8rem',
                color: palette.secondary.contrastText
              }}
            />
          </IconButton>
        )}
      </Box>
      <PopoverMenu
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
      />
      <CenterColumnBox
        className="hamburger"
        position="absolute"
        top={28}
        right={isSmallScreen ? 16 : 24}
        onClick={() => {
          onHandleHamburgerMenu();
          if (isOpen) {
            const el = document.getElementById('submenu');
            if (el) {
              const classList = el.classList;
              if (!classList.contains("ps-open")) return;
              el.click();
            }
          }
        }}
        style={{ cursor: isMediumScreen ? 'pointer' : 'default' }}
      >
        {isOpen && (
          <Box
            sx={{
              "& div.hamburger-react": {
                [theme.breakpoints.down('md')]: {
                  cursor: 'default!important'
                }
              }
            }}
          >
            <Hamburger
              color={isOpen ? palette.secondary.contrastText : palette.secondary.dark}
              direction="right"
              label="Show Menu"
              size={28}
              toggled={isOpen}
            />
          </Box>
        )}
        <Box
          position="absolute"
          bottom={-3}
        >
          {isOpen && (
            <Typography
              color={isOpen ? palette.secondary.contrastText : palette.secondary.dark}
              variant="body1"
            >
              {isOpen ? 'CLOSE' : 'MENU'}
            </Typography>
          )}
        </Box>
      </CenterColumnBox>
      <SideMenuTop
        isOpen={isOpen}
        onSetIsToggled={onSetIsToggled}
        onSetSideMenuOpen={onSetIsOpen}
      />
      <Box mt={6} />
      <SideMenuBottom
        onSetIsToggled={onSetIsToggled}
        onSetSideMenuOpen={onSetIsOpen}
      />
      <Box mt={10} />
    </CustomSidebar>
  )
}