import { Button } from '@mui/material';
import React from 'react';
import { palette, theme } from '../../theme';

interface OwnProps {
  disabled: boolean;
  text: string;
  onSubmit: () => void;
}

export const SubmitButton: React.FC<OwnProps> = (props) => {
  const { disabled, text, onSubmit } = props;
  return (
    <Button
      disableRipple
      variant="contained"
      sx={{
        background: palette.primary.dark,
        border: `1px solid ${palette.primary.dark}`,
        color: palette.secondary.contrastText,
        fontWeight: 'bold',
        padding: '10px 30px',
        transition: '.3s background-color ease-out',
        [theme.breakpoints.up('md')]: {
          "&:hover": {
            background: palette.secondary.contrastText,
            border: `1px solid ${palette.primary.dark}`,
            color: palette.primary.contrastText,
          }
        },
        [theme.breakpoints.down('md')]: {
          cursor: 'default',
          "&:hover": {
            boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)!important'
          }
        }
      }}
      disabled={disabled}
      onClick={onSubmit}
    >
      {text}
    </Button>
  )
}
