import { Box, IconButton, Link, Typography } from '@mui/material';
import { createTheme, styled } from '@mui/material/styles';

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: { main: '#003A88', dark: '#00155F', light: '#7FBFFF', contrastText: '#000000' },
    secondary: { main: '#00E676', dark: '#000000', contrastText: '#FFFFFF' },
    warning: { main: '#B00020' },
    error: { main: '#B00020' },
    background: { default: '#FFFFFF', paper: '#f2f5f8' },
    info: { main: '#FFFFFF', light: '#003A88', dark: '#9eadc3' },
  },
  typography: {
    h1: {
      color: '#000000',
      fontSize: 16,
      fontWeight: 'bold',
      fontFamily: 'DIN Alternate',
    },
    h2: {
      color: '#000000',
      fontSize: 20,
      fontWeight: 'bold',
      fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    },
    subtitle1: {
      color: '#FFFFFF',
      fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
      fontWeight: 'bold'
    },
    subtitle2: {
      color: 'rgb(255, 255, 255, .5)',
      fontFamily: '"Roboto","Helvetica","Arial",sans-serif'
    },
    body1: {
      fontSize: 10,
      fontWeight: 'bold',
      color: 'rgba(0, 0, 0, 0.6)',
    },
    body2: {
      fontSize: 14,
      color: 'rgba(0, 0, 0, 0.6)',
    }
  },
});

export const palette = theme.palette;

export const FlexBox = styled(Box)({
  display: 'flex',
});

export const FlexRowReverse = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  flexDirection: 'row-reverse'
});

export const CenterBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

export const CenterColumnBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column'
});

export const AlignCenter = styled(Box)({
  display: 'flex',
  alignItems: 'center'
});

export const TextLink = styled(Link)({
  color: palette.secondary.contrastText
});

export const CustomText = styled(Typography)({
  fontSize: 16,
  fontWeight: 'bold',
  lastIndexOfetterSpacing: '0.1em',
  color: palette.primary.contrastText
});

export const CustomSubText = styled(Typography)({
  fontSize: 10,
  fontWeight: 400,
  letterSpacing: '0.15em',
  color: 'rgba(0, 0, 0, 0.5)'
});

export const CustomItemButton = styled(IconButton)({
  transition: 'opacity 0.6s ease',
  color: palette.primary.contrastText,
  padding: 0,
  letterSpacing: '0.03em',
  height: 50,
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  "& svg": {
    fontSize: '1.6rem'
  },
  [theme.breakpoints.up('md')]: {
    "&:hover": {
      background: 'none !important',
      opacity: 0.5,
    }
  },
  [theme.breakpoints.down('md')]: {
    cursor: 'default!important',
  }
});

export const CustomItemTypography = styled(Typography)({
  fontSize: 16,
  fontWeight: 'bold',
  color: palette.secondary.contrastText,
  letterSpacing: '0.03em',
  textAlign: 'end'
});

export const CustomSubItemTypography = styled(Typography)({
  fontSize: 10,
  fontWeight: 400,
  color: 'rgba(255, 255, 255, 0.5)',
  letterSpacing: '0.03em',
  textAlign: 'end'
});

export const CustomContentTitle = styled(Typography)({
  fontSize: '30px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '20px',
  }
});

export const CustomContentSubTitle = styled(Typography)({
  fontSize: '16px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
  }
});

export const CustomMainText = styled(Typography)({
  color: palette.primary.contrastText,
  fontSize: '18px',
  lineHeight: '180%',
  textAlign: 'center',
  textShadow: '0 0 5px rgba(255,255,255,1), 0 0 5px rgba(255,255,255,1), 0 0 5px rgba(255,255,255,1)',
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '16px',
  }
});