import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  /// 英語
  'en': {
    translation: {
      "title": "GARAGE WIDE",
      "description": "",
      // "新車・中古車販売": "新車・中古車販売",
      "中古車販売": "SALE",
      "車検・点検": "CAR INSPECTION",
      // "整備・修理": "MAINTENANCE AND REPAIR",
      // "塗装": "BODY REPAIR AND PAINT",
      "サービス案内": "SERVICE GUIDE",
      "安心整備": "MAINTENANCE",
      "安心点検": "INSPECTION",
      "お知らせ": "NEWS",
      "最新のお知らせ": "NEWS",
      "アクセス": "ACCESS",
      "インスタグラム": "INSTAGRAM",
      "採用情報": "EMPLOYMENT",
      "募集要項": "JOB DESCRIPTION",
      "会社案内": "COMPANY",
      "会社概要": "COMPANY",
      "個人情報保護方針": "PRIVACY POLICY",
      "お問い合わせ": "CONTACT",
      "お問い合わせ確認フォーム": "CONTACT",
      "お電話によるお問い合わせ": "CONTACT",
      "サービス": "SERVICE",
      "企業情報": "COMPANY",
      "スタッフ紹介": "STAFF",
      "スタッフ一覧": "STAFF",
      "LINEによるお問い合わせ": "LINE"
    }
  },
  /// 日本語
  'ja': {
    translation: {
      "title": "車屋",
      "description": "",
      // "新車・中古車販売": "新車・中古車販売",
      "中古車販売": "中古車販売",
      "車検・点検": "車検・点検",
      // "整備・修理": "整備・修理",
      // "塗装": "塗装",
      "サービス案内": "サービス案内",
      "安心整備": "安心整備",
      "安心点検": "安心点検",
      "お知らせ": "お知らせ",
      "最新のお知らせ": "最新のお知らせ",
      "アクセス": "アクセス",
      "インスタグラム": "インスタグラム",
      "採用情報": "採用情報",
      "募集要項": "募集要項",
      "会社概要": "会社概要",
      "個人情報保護方針": "個人情報保護方針",
      "お問い合わせ": "お問い合わせ",
      "お問い合わせ確認フォーム": "お問い合わせ確認フォーム",
      "お電話によるお問い合わせ": "お電話によるお問い合わせ",
      "サービス": "サービス",
      "企業情報": "企業情報",
      "スタッフ紹介": "スタッフ紹介",
      "スタッフ一覧": "スタッフ一覧",
      "LINEによるお問い合わせ": "LINEによるお問い合わせ"
    }
  }
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',
    debug: false,
    interpolation: {
      escapeValue: true,
    }
  });

export default i18n;