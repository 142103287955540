import { Backdrop, Box, Typography } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useWindowSize } from 'react-use';
import { CenterColumnBox, palette } from '../../theme';
import { Car } from '../../images/Car';
import { TextLoop } from './TextLoop';
import { StateContext } from '../../contexts/State';

interface OwnProps {
  open: boolean;
}

export const BasicBackdrop: React.FC<OwnProps> = (props) => {
  const { open } = props;
  const iconSize = {
    width: 110,
    height: 40
  };
  const boxRef = useRef<HTMLElement | null>(null);
  const { bodyRef } = useContext(StateContext);
  const { width, height } = useWindowSize();
  const [parentSize, setParentSize] = useState<number>(0);
  const [isLoop] = useState<boolean>(open);

  useEffect(() => {
    const CONTENT_WIDTH = 1280;
    const CONTENT_HEIGHT = 1024;
    const scaleX = (width / CONTENT_WIDTH) * 3;
    const scaleY = (height / CONTENT_HEIGHT) * 3;
    setParentSize(Math.min(scaleX, scaleY));
  }, [width, height]);

  useEffect(() => {
    if (bodyRef && open) {
      bodyRef.classList.add('no_scroll_backdrop');
    } else if (bodyRef && !open) {
      bodyRef.classList.remove('no_scroll_backdrop');
    }
  }, [open, bodyRef]);

  return (
    <Backdrop
      sx={{
        color: palette.secondary.contrastText,
        background: palette.primary.dark,
        zIndex: 99
      }}
      open={open}
    >
      <CenterColumnBox>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          width={(iconSize.width + 50) * parentSize}
          height={(iconSize.height + 50) * parentSize}
        >
          <Car
            width={iconSize.width * parentSize}
            height={iconSize.height * parentSize}
            color={palette.primary.dark}
          />
          <Box mt={.5} />
          <Box
            ref={boxRef}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              fontSize={16 * parentSize}
              fontWeight="bold"
              color={palette.secondary.contrastText}
            >
              LOADING
            </Typography>
            <TextLoop
              boxRef={boxRef}
              isLoop={isLoop}
              parentSize={parentSize}
            />
          </Box>
        </Box>
      </CenterColumnBox>
    </Backdrop>
  )
}
