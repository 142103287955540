import React, { useContext, useEffect } from 'react';
import { Button, Box, Typography } from '@mui/material';
import { AuthContext } from '../../contexts/Auth';
import { useNavigate } from 'react-router';
import { styled } from '@mui/system';
import { CenterColumnBox, palette } from '../../theme';

const CustomBox = styled(Box)({
  width: '100%',
  height: '100vh',
});

const Login: React.FC = () => {
  const navigate = useNavigate();
  const { login, user } = useContext(AuthContext);

  useEffect(() => {
    if (user) {
      return navigate('/');
    }
    return;
    // eslint-disable-next-line
  }, [user]);

  const handleLogin = async () => {
    login();
  }

  return (
    <CustomBox bgcolor={palette.background.default}>
      <Box>
        <CenterColumnBox justifyContent="center">
          <Box m={16} />
          <Typography variant="h2" color="inherit">ログイン</Typography>
          <Box m={2} />
          <Button
            variant="contained"
            style={{ color: 'white', background: palette.primary.main }}
            onClick={handleLogin}
          >
            ログイン
          </Button>
          <Box m={1} />
          <Box m={8} />
        </CenterColumnBox>
      </Box>
    </CustomBox>
  )
}

export default Login;
