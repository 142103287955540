import { Box, Divider, Typography, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import { CenterColumnBox, CustomContentSubTitle, CustomContentTitle, palette, theme } from '../../theme';
import { useTranslation } from 'react-i18next';

const CustomMainBox = styled(CenterColumnBox)({
  position: 'relative',
  justifyContent: 'center'
});

const CustomMainImage = styled(Box)({
  position: 'absolute',
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  zIndex: 0,
  backgroundImage: 'url("/images/maintenance2.jpg")',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '0 0',
  backgroundSize: 'cover',
  boxShadow: '0 0 5px rgba(255,255,255,.1)'
});

const CustomCenterColumnBox = styled(CenterColumnBox)({
  width: '48%',
  [theme.breakpoints.down('md')]: {
    width: 'auto'
  }
});

const CustomTypography = styled(Typography)({
  color: palette.primary.contrastText,
  fontSize: '20px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '16px'
  }
});

const CustomSubTypography = styled(Typography)({
  color: palette.primary.contrastText,
  fontSize: '25px',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '20px'
  }
});

const CustomContent = styled(Box)({
  background: palette.secondary.contrastText,
  display: 'flex',
  justifyContent: 'space-around',
  padding: '50px 0',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px 10px',
  }
});

export const InfoView: React.FC = () => {
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();
  return (
    <Box>
      {isMediumScreen ? (<Box mt={5} />) : (<Box mt={10} />)}
      <CustomMainBox>
        <CustomMainImage />
        {isMediumScreen ? (<Box mt={5} />) : (<Box mt={10} />)}
        <Box
          display="flex"
          justifyContent="center"
          width={1}
        >
          <Box
            bgcolor={palette.secondary.contrastText}
            borderTop={`2px solid ${palette.primary.main}`}
            p={2}
            width={.9}
            zIndex={1}
          >
            <Box padding={'8px 16px'}>
              <CustomContentTitle
                textAlign="center"
                variant="h1"
              >
                安心点検
              </CustomContentTitle>
              <Box pt={.5} />
              <CustomContentSubTitle
                color={palette.primary.main}
                variant="body2"
                textAlign="center"
              >
                {t('安心点検')}
              </CustomContentSubTitle>
            </Box>
            <CustomContent>
              <CustomCenterColumnBox>
                <CustomTypography>丁寧な車検・点検</CustomTypography>
                <Box pt={1} />
                <CustomSubTypography>経験を積んだ自動車整備士が<br />丁寧な車検・点検を行います。</CustomSubTypography>
              </CustomCenterColumnBox>
              {isMediumScreen && (<Box pt={2} />)}
              <Divider orientation={isMediumScreen ? 'horizontal' : 'vertical'} variant="middle" flexItem />
              {isMediumScreen && (<Box pt={2} />)}
              <CustomCenterColumnBox>
                <CustomTypography>アフターサービス</CustomTypography>
                <Box pt={1} />
                <CustomSubTypography>お客様のニーズに合わせて、<br />熟練スタッフが対応いたします。</CustomSubTypography>
              </CustomCenterColumnBox>
            </CustomContent>
          </Box>
        </Box>
        {isMediumScreen ? (<Box mt={5} />) : (<Box mt={10} />)}
      </CustomMainBox>
    </Box>
  )
}