export const Car = (props: any) => {
  const iconStyle = { width: 110, height: 40, ...props };
  return (
    <svg className="car" {...iconStyle} viewBox="0 0 102 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(2 1)" stroke="#FFFFFF" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <path className="__body" d="M38.5 16.5H92.51M49.293 3.375C54.927 1.792 56.017 1.805 56.017 1.805C58.63 1.36 62.855 1.468 65.437 2.042L73.818 3.905C76.408 4.481 79.982 6.511 81.798 8.436L88.146 15.168L94.391 17.045C97.489 17.553 100 20.476 100 23.552V27.758C100 28.048 97.464 31.947 94.313 31.947H38.808C36.153 31.947 34.468 29.847 35.12 27.277C35.12 27.277 38.83 7.333 49.293 3.375Z" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M85.493 38.058C89.3159 38.058 92.415 35.0099 92.415 31.25C92.415 27.49 89.3159 24.442 85.493 24.442C81.6701 24.442 78.571 27.49 78.571 31.25C78.571 35.0099 81.6701 38.058 85.493 38.058Z" fill={props.color} stroke="#FFFFFF" strokeWidth="3.2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M48.511 38.058C52.3339 38.058 55.433 35.0099 55.433 31.25C55.433 27.49 52.3339 24.442 48.511 24.442C44.6881 24.442 41.589 27.49 41.589 31.25C41.589 35.0099 44.6881 38.058 48.511 38.058Z" fill={props.color} stroke="#FFFFFF" strokeWidth="3.2" strokeLinecap="round" strokeLinejoin="round" />
        <path className="__line --top" d="M24.5 17.5H4.475" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path className="__line --middle" d="M22.5 24.5H2.4755" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path className="__line --bottom" d="M27.5 10.5H8.5" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>
  );
}