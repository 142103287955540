import React from 'react';
import { CenterColumnBox, palette, theme } from '../../theme';
import { Box, Typography, useMediaQuery } from '@mui/material';
import Hamburger from 'hamburger-react';

interface OwnProps {
  isOpen: boolean;
  onHandleHamburgerMenu: () => void;
}

export const HamburgerMenu: React.FC<OwnProps> = (props) => {
  const { isOpen, onHandleHamburgerMenu } = props;
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <CenterColumnBox
      className="hamburger"
      position="absolute"
      zIndex={1500}
      top={'auto'}
      bottom={'auto'}
      onClick={() => {
        onHandleHamburgerMenu();
        if (isOpen) {
          const el = document.getElementById('submenu');
          if (el) {
            const classList = el.classList;
            if (!classList.contains('ps-open')) return;
            el.click();
          }
        }
      }}
      style={{ cursor: isMediumScreen ? 'pointer' : 'default' }}
    >
      {!isOpen && (
        <Box
          sx={{
            "& div.hamburger-react": {
              [theme.breakpoints.down('md')]: {
                cursor: 'default!important'
              }
            }
          }}
        >
          <Hamburger
            color={palette.secondary.dark}
            direction="right"
            label="Show Menu"
            size={28}
            toggled={isOpen}
          />
        </Box>
      )}
      <Box
        position="absolute"
        bottom={-3}
      >
        {!isOpen && (<Typography variant="body1">{isOpen ? 'CLOSE' : 'MENU'}</Typography>)}
      </Box>
    </CenterColumnBox>
  )
}